export const QUERY_USER_CERTIFICATIONS_PENDING = 'QUERY_USER_CERTIFICATIONS_PENDING';
export const QUERY_USER_CERTIFICATIONS_FULFILLED = 'QUERY_USER_CERTIFICATIONS_FULFILLED';
export const QUERY_USER_CERTIFICATIONS_REJECTED = 'QUERY_USER_CERTIFICATIONS_REJECTED';

export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';

export const CLEAR_REGISTRY_FILTERS = 'CLEAR_REGISTRY_FILTERS';

export const CHANGE_REGISTRY_SORT_OPTIONS = 'CHANGE_REGISTRY_SORT_OPTIONS';

export const CREATE_SHAREABLE_LINK_FOR_USER = 'CREATE_SHAREABLE_LINK_FOR_USER';
