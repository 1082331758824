import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import ChildModal from '../../containers/ChildModal';
import ResendEmailVerificationForm from '../Forms/ResendEmailVerificationForm';

const resendVerificationEmailMessages = defineMessages({
  buttonTitle: {
    id: 'resendButton.buttonTitle',
    defaultMessage: 'Resend verification email',
  },
  unVerifiedEmailButton: {
    id: 'header.emailNotVerified',
    defaultMessage: 'Email is not verified',
  },
});

const ResendEmailVerificationButton = (props) => {
  const initialEmail = { email: props.email };

  const form = (
    <ResendEmailVerificationForm
      onSubmit={props.resend}
      initialValues={initialEmail}
    />
  );

  let unVerifiedEmailProps = {};

  if (props.warning_button) {
    unVerifiedEmailProps = {
      title: '',
      type: 'custom',
      buttonSize: 'sm',
      trigger: ['hover'],
      buttonClass: 'btn-warning',
      buttonTooltip: props.tooltip,
      buttonTooltipPosition: 'bottom',
      id: 'sendVerificationEmailBanner',
      buttonIcon: 'fa-exclamation-triangle',
      buttonText: props.intl.formatMessage(resendVerificationEmailMessages.unVerifiedEmailButton),
    };
  }

  return (
    <ChildModal
      type="text-link"
      id="resendVerificationEmail"
      title={props.intl.formatMessage(resendVerificationEmailMessages.buttonTitle)}
      buttonText={props.intl.formatMessage(resendVerificationEmailMessages.buttonTitle)}
      {...unVerifiedEmailProps}
    >
      {form}
    </ChildModal>
  );
};

ResendEmailVerificationButton.propTypes = {
  resend: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  email: PropTypes.string,
  tooltip: PropTypes.node,
  warning_button: PropTypes.bool,
};

ResendEmailVerificationButton.defaultProps = {
  email: '',
  tooltip: null,
  warning_button: false,
};

export default injectIntl(ResendEmailVerificationButton);
