import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { change, destroy } from 'redux-form';
import { push } from 'react-router-redux';


import * as types from '../constants/actionTypes';
import APPLICATION_WIZARD_FORM from '../constants/forms';
import axios from '../../../api/axios';
import { filterSentAttachments, formFilesToBase64 } from '../../../utils/forms';
import templateToastr from '../../../utils/toastr';
import { getApplication } from '../../../actions/applications';


// ------------------------------------
// Action Handlers
// ------------------------------------
export function* destroyApplicationWizard() {
  yield put(destroy(APPLICATION_WIZARD_FORM));
  yield put({ type: types.RESET_WIZARD });
}

export function* sendApplication(form) {
  let response;
  const applicationId = form.id;
  if (applicationId) {
    response = yield call(axios.put, `/applications/${applicationId}`, form);
  } else {
    response = yield call(axios.post, '/applications', form);
  }
  return response.data;
}

export function* handleSuccessfullDraftSave(application) {
  yield put({ type: types.SEND_APPLICATION_DRAFT_FULFILLED, application });
  yield put(change(APPLICATION_WIZARD_FORM, 'id', application.id));
  yield delay(3000);
  yield put({ type: types.SEND_APPLICATION_DRAFT_RESET });
}

export function* handleSuccessfullSave(application) {
  templateToastr('success', 'saved', 'application');
  yield put({ type: types.SEND_APPLICATION_FULFILLED, application });
  yield put(getApplication(application.id));
  yield put(push(`/users/${application.user.id}/applications/${application.id}`));
  yield destroyApplicationWizard();
}

function* submitApplicationSaga({ form }) {
  yield put({ type: types.SEND_APPLICATION_PENDING });

  let formData = filterSentAttachments(form, 'attachments');
  formData = yield formFilesToBase64(formData, ['name']);

  const actions = {
    fulfilled: formData.draft === true ? handleSuccessfullDraftSave : handleSuccessfullSave,
    rejected: formData.draft === true ? types.SEND_APPLICATION_DRAFT_REJECTED : types.SEND_APPLICATION_REJECTED,
  };

  try {
    const application = yield call(sendApplication, formData);
    yield call(actions.fulfilled, application);
  } catch (error) {
    yield put({ type: actions.rejected, error });
  }
}

export function* sendApplicationSaga({ form }) {
  const submitForm = {
    ...form,
    draft: false,
  };
  yield call(submitApplicationSaga, { form: submitForm });
}

export function* sendApplicationDraftSaga({ form }) {
  const draftForm = {
    ...form,
    draft: true,
  };
  yield call(submitApplicationSaga, { form: draftForm });
}

export function* changePageSaga({ page }) {
  yield put({ type: types.CHANGE_WIZARD_PAGE_FULFILLED, page });
}

export function* setWizardQualification({ qualification }) {
  yield put({ type: types.SET_WIZARD_QUALIFICATION_FULFILLED, qualification });
}

export function* setWizardQualificationLevel({ qualificationLevel }) {
  yield put({ type: types.SET_WIZARD_QUALIFICATION_LEVEL_FULFILLED, qualificationLevel });
}


// ------------------------------------
// Watchers
// ------------------------------------
export function* watchWizardSagas() {
  yield all([
    takeLatest(types.SEND_APPLICATION, sendApplicationSaga),
    takeLatest(types.SEND_APPLICATION_DRAFT, sendApplicationDraftSaga),
    takeLatest(types.CHANGE_WIZARD_PAGE, changePageSaga),
    takeLatest(types.SET_WIZARD_QUALIFICATION, setWizardQualification),
    takeLatest(types.SET_WIZARD_QUALIFICATION_LEVEL, setWizardQualificationLevel),
    takeLatest(types.DESTROY_APPLICATION_WIZARD, destroyApplicationWizard),
  ]);
}
