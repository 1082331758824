import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';


const NewsRoute = store => ({
  path: '/uutiset',
  getComponent(nextState, cb) {
    import('./containers/NewsRoute')
      .then(async (module) => {
        store.dispatch(setActivePage(menuPages.NEWS_PAGE));
        cb(null, module.default);
      });
  },
});

export default NewsRoute;
