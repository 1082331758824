import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import axios, { removeAllTokens, setAuthToken } from '../api/axios';
import * as types from '../constants/auth/actionTypes';
import axiosConfig from '../constants/api/config';
import { serverErrorToastr } from '../utils/toastr';
import { createFormErrors } from '../utils/forms';


// ------------------------------------
// Action Handlers
// ------------------------------------
export function* registerNewUser({ payload }) {
  const { userFormData, resolve, reject } = payload;
  const userCreationUrl = '/users';
  const userLoginUrl = '/auth/login';
  const tokenUrl = '/signicat/login_token';

  // We start of by making sure that we do not have an old token laying around
  yield removeAllTokens();

  let creationError;
  try {
    // Create a new user
    yield put({ type: types.REGISTER_NEW_USER_PENDING, payload: { state: 'userCreation' } });
    yield axios.post(userCreationUrl, userFormData);
  } catch (err) {
    // If the problem is not the users email then we can just stop
    // If the users email is the problem then we might just have a
    // case where the user already exists but has not authenticated yet
    if (
      !isEmpty(err.response) &&
      err.response.status !== 400 &&
      isEmpty(err.response.data.email)
    ) {
      return serverErrorToastr(err.response);
    }
    // We store the creation error as if the login fail, we want to
    // re-raise that error
    creationError = err;
  }

  try {
    // Log in the new user
    yield put({ type: types.REGISTER_NEW_USER_PENDING, payload: { state: 'userLogin' } });

    // Add registration flag, telling the backend to skip some non-sensitive validation
    userFormData.registration = true;

    const tokenResponse = yield axios.post(userLoginUrl, userFormData);
    setAuthToken(tokenResponse.data.token);
  } catch (err) {
    // If we had a creation error, return it
    if (creationError) {
      const formErrors = createFormErrors(creationError.response);
      reject(formErrors);
      return serverErrorToastr(creationError.response);
    }
    // Otherwise, the current error as a toast
    return serverErrorToastr(err.response);
  }

  try {
    // Fetch one time token for Signicat authentication
    yield put({ type: types.REGISTER_NEW_USER_PENDING, payload: { state: 'loginTokenCreation' } });
    const response = yield axios.get(tokenUrl);
    const oneTimeToken = response.data.token;

    // Redirect to Signicat authentication server
    window.location.href = `${axiosConfig.baseUrl}/signicat/login/?token=${oneTimeToken}`;
  } catch (err) {
    yield put({ type: types.REGISTER_NEW_USER_REJECTED, payload: err });
    serverErrorToastr(err.response);
  }
  return resolve();
}

// ------------------------------------
// Watchers
// ------------------------------------
export function* watchauthenticationSagas() {
  yield all([
    takeLatest(types.REGISTER_NEW_USER, registerNewUser),
  ]);
}


export default function* authenticationSagas() {
  yield all([
    fork(watchauthenticationSagas),
  ]);
}
