import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ForgotpasswordLink = (props) => {
  const defaultText = (<FormattedMessage
    id="forgotPasswordLink.resetPassword"
    defaultMessage="Reset password"
  />);
  const text = props.text;
  let openElement;

  if (props.isButton) {
    openElement = (
      <button
        type="button"
        onClick={props.show}
        className="btn btn-sm btn-register btn-rounded btn-outline"
      >
        {text || defaultText}
      </button>
    );
  } else {
    openElement = (
      <button onClick={props.show} className="link-btn">
        {text || defaultText}
      </button>
    );
  }

  return (
    <span>
      {openElement}
    </span>
  );
};

ForgotpasswordLink.propTypes = {
  show: PropTypes.func.isRequired,
  text: PropTypes.node,
  isButton: PropTypes.bool,
};

ForgotpasswordLink.defaultProps = {
  text: '',
  isButton: false,
};

export default ForgotpasswordLink;
