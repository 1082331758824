import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes as reduxFormPropTypes, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Input from '../../components/FormFields/Input';
import Form from '../Form/Form';

const ResendEmailVerificationForm = props => (
  <Form {...props}>
    <div className="form-group required">
      <label htmlFor="email" className="control-label">
        <FormattedMessage
          id="verificationForm.email"
          defaultMessage="E-mail"
        />
      </label>
      <div>
        <FormattedMessage
          id="verificationForm.emailPlaceholder"
          defaultMessage="Enter your email"
        >
          {
            message => (<Field
              name="email"
              component={Input}
              type="email"
              className="form-control"
              placeholder={message}
            />)
          }
        </FormattedMessage>
      </div>
    </div>
    <button
      type="submit"
      className="btn btn-success btn-rounded btn-block"
      disabled={props.submitting}
    >
      <i className="fa fa-check fa-fw" />
      <FormattedMessage
        id="verificationForm.send"
        defaultMessage="Send"
      />
    </button>
  </Form>
);

ResendEmailVerificationForm.propTypes = {
  ...reduxFormPropTypes,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'resendEmailVerificationForm',
})(ResendEmailVerificationForm);

