import * as types from '../constants/locale/actionTypes';

export const localeSelected = locale => (dispatch) => {
  dispatch({
    type: types.LOCALE_SELECTED,
    payload: locale,
  });
};

export default localeSelected;
