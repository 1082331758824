import { isEmpty } from 'lodash';
import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import queryStatistics from './actions/statistics';

const StatisticsRoute = store => ({
  path: 'statistics',
  authorize: {
    groups: [
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/Statistics/Statistics')
      .then(async (module) => {
        const statisticsReducer = await import('../../../../reducers/statistics');
        store.injectReducer({ key: 'statistics', reducer: statisticsReducer.default });

        const state = store.getState();
        if (isEmpty(state.statistics)) {
          store.dispatch(queryStatistics());
        }
        cb(null, module.default);
      });
  },
});

export default StatisticsRoute;
