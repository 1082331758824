export const ATTACHMENT_ADD = 'ATTACHMENT_ADD';
export const ATTACHMENT_REMOVE = 'ATTACHMENT_REMOVE';
export const ATTACHMENT_DOWNLOAD = 'ATTACHMENT_DOWNLOAD';

export const USER_ATTACHMENT_REMOVE_FULFILLED = 'USER_ATTACHMENT_REMOVE_FULFILLED';
export const USER_ATTACHMENT_REMOVE_REJECTED = 'USER_ATTACHMENT_REMOVE_REJECTED';

export const USER_ATTACHMENT_ADD_FULFILLED = 'USER_ATTACHMENT_ADD_FULFILLED';
export const USER_ATTACHMENT_ADD_REJECTED = 'USER_ATTACHMENT_ADD_REJECTED';

export const APPLICATION_ATTACHMENT_REMOVE_FULFILLED = 'APPLICATION_ATTACHMENT_REMOVE_FULFILLED';
export const APPLICATION_ATTACHMENT_REMOVE_REJECTED = 'APPLICATION_ATTACHMENT_REMOVE_REJECTED';

export const APPLICATION_ATTACHMENT_ADD_FULFILLED = 'APPLICATION_ATTACHMENT_ADD_FULFILLED';
export const APPLICATION_ATTACHMENT_ADD_REJECTED = 'APPLICATION_ATTACHMENT_ADD_REJECTED';

export const APPLICATION_COMMENT_ATTACHMENT_REMOVE_FULFILLED = 'APPLICATION_COMMENT_ATTACHMENT_REMOVE_FULFILLED';
export const APPLICATION_COMMENT_ATTACHMENT_REMOVE_REJECTED = 'APPLICATION_COMMENT_ATTACHMENT_REMOVE_REJECTED';

export const APPLICATION_COMMENT_ATTACHMENT_ADD_FULFILLED = 'APPLICATION_COMMENT_ATTACHMENT_ADD_FULFILLED';
export const APPLICATION_COMMENT_ATTACHMENT_ADD_REJECTED = 'APPLICATION_COMMENT_ATTACHMENT_ADD_REJECTED';
