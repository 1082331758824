import PropTypes from 'prop-types';

export const ApplicationFormShapeObject = {
  id: PropTypes.string,
  qualification: PropTypes.string,
  company_billing: PropTypes.string,
  personal_billing: PropTypes.string,
  education_items: PropTypes.arrayOf(PropTypes.string),
  work_items: PropTypes.arrayOf(PropTypes.string),
  training_items: PropTypes.arrayOf(PropTypes.string),
  course_items: PropTypes.arrayOf(PropTypes.string),
  fise_exam_items: PropTypes.arrayOf(PropTypes.string),
  reference_items: PropTypes.arrayOf(PropTypes.string),
  draft: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  qualification_type: PropTypes.string,
  qualification_level: PropTypes.string,
  qualification_area_of_expertise: PropTypes.string,
  application_type: PropTypes.string,
  reference_attachments: PropTypes.arrayOf(PropTypes.shape({})),
  education_attachments: PropTypes.arrayOf(PropTypes.shape({})),
  work_attachments: PropTypes.arrayOf(PropTypes.shape({})),
  application_structure: PropTypes.string,
  area_of_expertise: PropTypes.string,
  billing_reference: PropTypes.string,
  cpd_course_items: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.any,
  general_comment: PropTypes.string,
  statement_author_items: PropTypes.arrayOf(PropTypes.shape({})),
  statement_items: PropTypes.arrayOf(PropTypes.shape({})),
  latest_state: PropTypes.string,
};

export const ApplicationFormKeys = Object.keys(ApplicationFormShapeObject);

const ApplicationFormShape = PropTypes.shape(ApplicationFormShapeObject);


export default ApplicationFormShape;
