export const QUERY_USER_GROUPS = 'QUERY_USER_GROUPS';
export const QUERY_USER_GROUPS_PENDING = 'QUERY_USER_GROUPS_PENDING';
export const QUERY_USER_GROUPS_FULFILLED = 'QUERY_USER_GROUPS_FULFILLED';
export const QUERY_USER_GROUPS_REJECTED = 'QUERY_USER_GROUPS_REJECTED';

export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';
export const UPDATE_USER_GROUPS_PENDING = 'UPDATE_USER_GROUPS_PENDING';
export const UPDATE_USER_GROUPS_FULFILLED = 'UPDATE_USER_GROUPS_FULFILLED';
export const UPDATE_USER_GROUPS_REJECTED = 'UPDATE_USER_GROUPS_REJECTED';

export const UPDATE_UI_GROUP_LIST = 'UPDATE_UI_GROUP_LIST';
export const UPDATE_UI_GROUP_LIST_ADD_FULFILLED = 'UPDATE_UI_GROUP_LIST_ADD_FULFILLED';
export const UPDATE_UI_GROUP_LIST_REMOVE_FULFILLED = 'UPDATE_UI_GROUP_LIST_REMOVE_FULFILLED';
