import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';
import { fetchAllPages } from '../../actions/utils';
import {
  queryApplicationStructureOptions,
  queryCurrentActiveApplicationStructures,
  hasFetchedAllApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../actions/structures';

const ContactUsRoute = store => ({
  path: 'contact-us',
  getComponent(nextState, cb) {
    import('./containers/ContactUsView').then(async (module) => {
      const state = store.getState();
      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
      if (!state.structures.fetchedAll && !state.structures.startedFetching) {
        store.dispatch(hasStartedFetchingApplicationStructures());
        store.dispatch(fetchAllPages(
          queryCurrentActiveApplicationStructures,
          {},
          hasFetchedAllApplicationStructures,
        ));
      }
      store.dispatch(setActivePage(menuPages.CONTACT_US_PAGE));
      cb(null, module.default);
    });
  },
});


export default ContactUsRoute;
