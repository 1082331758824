import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';

const EmailVerificationSuccessRoute = store => ({
  path: 'email_verified',
  getComponent(nextState, cb) {
    import('./EmailVerificationSuccessView').then(async (module) => {
      store.dispatch(setActivePage(menuPages.EMAIL_VERIFICATION_SUCCESS_PAGE));
      cb(null, module.default);
    });
  },
});

export default EmailVerificationSuccessRoute;
