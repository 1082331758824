import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { every, some } from 'lodash';
import hoistStatics from 'hoist-non-react-statics';
import { push } from 'react-router-redux';

import AuthShape from '../structures/auth';
import UserShape from '../structures/user';

/*
 Wrapper that enables permission checked before rendering a route
 */

// Filter out props not to sent to wrapped element
const filterProps = ({
  checkPermissions, auth, loggedInUser, ...otherProps
}) => ({ ...otherProps });

// Wraps the component that needs the auth enforcement
const routerPermissionWrapper = (DecoratedComponent) => {
  const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';
  const wrapperDisplayName = 'RouterPermissionWrapper';

  const checkPermissions = (user, auth, routes) => (dispatch) => {
    // If there is no user, then the user also has no groups
    let userObj = {
      groups: [],
    };

    // If an user exists, override the user object
    if (user && user.user) {
      userObj = user.user;
    }

    // TODO: Figure out a nicer way to handle token authenticated users
    if (userObj.token_authenticated) {
      return true;
    }

    // Check if the user belongs to at least one permitted groups
    const userPassesRoles = groups => (
      !groups
      || !groups.length
      || some(groups, role => userObj.groups.indexOf(role) > -1)
    );

    // Check if the user exists and that the route has an authorize attribute
    const routeIsAuthorized = ({ authorize }) => {
      if (!authorize) {
        return true;
      }
      return userObj.id && userPassesRoles(authorize.groups);
    };

    // Check every route in the chain
    const isAuthorized = every(routes, routeIsAuthorized);

    // We start a redirect back to the frontpage asap
    if (!isAuthorized && !auth.loggingIn) {
      dispatch(push('/'));
    }

    return isAuthorized;
  };

  const mapDispatchToProps = {
    checkPermissions,
  };

  const mapStateToProps = state => ({
    auth: state.auth,
    loggedInUser: state.users[state.auth.currentUser],
  });

  class RouterPermissionWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        routes: [], // eslint-disable-line react/no-unused-state
        hasPermissions: false,
      };
    }

    /* eslint-disable-next-line camelcase */
    UNSAFE_componentWillMount() {
      if (this.props.auth.completed) {
        const hasPermissions = this.props.checkPermissions(
          this.props.loggedInUser,
          this.props.auth,
          this.props.routes,
        );
        this.setState({ hasPermissions });
      }
    }
    /* eslint-disable-next-line camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.auth.completed) {
        const hasPermissions = this.props.checkPermissions(
          nextProps.loggedInUser,
          nextProps.auth,
          nextProps.routes,
        );
        this.setState({ hasPermissions });
      }
    }

    render() {
      if (this.state.hasPermissions) {
        return <DecoratedComponent {...filterProps(this.props)} />;
      }
      // TODO: Replace with proper loading component
      // Note that a "null component" will return an empty page.
      const LoadingComponent = () => null;
      return <LoadingComponent {...filterProps(this.props)} />;
    }
  }

  // React Convention: Wrap the Display Name for Easy Debugging
  RouterPermissionWrapper.displayName = `${wrapperDisplayName}(${displayName})`;

  RouterPermissionWrapper.propTypes = {
    auth: AuthShape.isRequired,
    loggedInUser: UserShape,
    checkPermissions: PropTypes.func.isRequired,
    routes: PropTypes.arrayOf(
      PropTypes.shape,
    ).isRequired,
  };

  RouterPermissionWrapper.defaultProps = {
    loggedInUser: {},
  };

  const routerPermissionWapper = connect(
    mapStateToProps, mapDispatchToProps,
  )(RouterPermissionWrapper);

  // React Convention: Static Methods Must Be Copied Over
  return hoistStatics(routerPermissionWapper, DecoratedComponent);
};

export default routerPermissionWrapper;
