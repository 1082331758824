import * as types from '../constants/clipboard/actionTypes';

const initialValue = {};

const clipboardReducer = (state = initialValue, action) => {
  switch (action.type) {
  case types.CLIPBOARD_DATA_PENDING:
    return {
      ...state,
      [action.payload.name]: {
        data: null,
        available: false,
        fetching: true,
        errors: null,
      },
    };
  case types.CLIPBOARD_DATA_FULFILLED:
    return {
      ...state,
      [action.payload.name]: {
        data: action.payload.data,
        available: true,
        fetching: false,
        errors: null,
      },
    };
  case types.CLIPBOARD_DATA_REJECTED:
    return {
      ...state,
      [action.payload.name]: {
        data: null,
        available: true,
        fetching: false,
        errors: action.payload.errors,
      },
    };
  default:
    return state;
  }
};

export default clipboardReducer;
