export const QUERY_APPLICATION_STRUCTURES = 'QUERY_APPLICATION_STRUCTURES';
export const QUERY_APPLICATION_STRUCTURES_PENDING = 'QUERY_APPLICATION_STRUCTURES_PENDING';
export const QUERY_APPLICATION_STRUCTURES_FULFILLED = 'QUERY_APPLICATION_STRUCTURES_FULFILLED';
export const QUERY_APPLICATION_STRUCTURES_REJECTED = 'QUERY_APPLICATION_STRUCTURES_REJECTED';

export const QUERY_APPLICATION_STRUCTURES_OVERRIDE = 'QUERY_APPLICATION_STRUCTURES_OVERRIDE';
export const QUERY_APPLICATION_STRUCTURES_OVERRIDE_PENDING = 'QUERY_APPLICATION_STRUCTURES_OVERRIDE_PENDING';
export const QUERY_APPLICATION_STRUCTURES_OVERRIDE_FULFILLED = 'QUERY_APPLICATION_STRUCTURES_OVERRIDE_FULFILLED';
export const QUERY_APPLICATION_STRUCTURES_OVERRIDE_REJECTED = 'QUERY_APPLICATION_STRUCTURES_OVERRIDE_REJECTED';

export const GET_APPLICATION_STRUCTURE = 'GET_APPLICATION_STRUCTURE';
export const GET_APPLICATION_STRUCTURE_PENDING = 'GET_APPLICATION_STRUCTURE_PENDING';
export const GET_APPLICATION_STRUCTURE_FULFILLED = 'GET_APPLICATION_STRUCTURE_FULFILLED';
export const GET_APPLICATION_STRUCTURE_REJECTED = 'GET_APPLICATION_STRUCTURE_REJECTED';

export const CREATE_APPLICATION_STRUCTURE = 'CREATE_APPLICATION_STRUCTURE';
export const CREATE_APPLICATION_STRUCTURE_PENDING = 'CREATE_APPLICATION_STRUCTURE_PENDING';
export const CREATE_APPLICATION_STRUCTURE_FULFILLED = 'CREATE_APPLICATION_STRUCTURE_FULFILLED';
export const CREATE_APPLICATION_STRUCTURE_REJECTED = 'CREATE_APPLICATION_STRUCTURE_REJECTED';

export const UPDATE_APPLICATION_STRUCTURE = 'UPDATE_APPLICATION_STRUCTURE';
export const UPDATE_APPLICATION_STRUCTURE_PENDING = 'UPDATE_APPLICATION_STRUCTURE_PENDING';
export const UPDATE_APPLICATION_STRUCTURE_FULFILLED = 'UPDATE_APPLICATION_STRUCTURE_FULFILLED';
export const UPDATE_APPLICATION_STRUCTURE_REJECTED = 'UPDATE_APPLICATION_STRUCTURE_REJECTED';

export const REMOVE_APPLICATION_STRUCTURE = 'REMOVE_APPLICATION_STRUCTURE';
export const REMOVE_APPLICATION_STRUCTURE_PENDING = 'REMOVE_APPLICATION_STRUCTURE_PENDING';
export const REMOVE_APPLICATION_STRUCTURE_FULFILLED = 'REMOVE_APPLICATION_STRUCTURE_FULFILLED';
export const REMOVE_APPLICATION_STRUCTURE_REJECTED = 'REMOVE_APPLICATION_STRUCTURE_REJECTED';

export const QUERY_APPLICATION_STRUCTURE_OPTIONS = 'QUERY_APPLICATION_STRUCTURE_OPTIONS';
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_PENDING = 'QUERY_APPLICATION_STRUCTURE_OPTIONS_PENDING';
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_FULFILLED = 'QUERY_APPLICATION_STRUCTURE_OPTIONS_FULFILLED';
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_REJECTED = 'QUERY_APPLICATION_STRUCTURE_OPTIONS_REJECTED';

export const QUERY_APPLICATION_STRUCTURES_UPDATE_TIME = 'QUERY_APPLICATION_STRUCTURES_UPDATE_TIME';
export const QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_PENDING = 'QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_PENDING';
export const QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_FULFILLED = 'QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_FULFILLED';
export const QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_REJECTED = 'QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_REJECTED';

export const CLEAR_OLD_APPLICATIONS = 'CLEAR_OLD_APPLICATIONS';

export const QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME = (
  'QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME'
);
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_PENDING = (
  'QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_PENDING'
);
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_FULFILLED = (
  'QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_FULFILLED'
);
export const QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_REJECTED = (
  'QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_REJECTED'
);

export const FETCHED_ALL_APPLICATION_STRUCTURES = 'FETCHED_ALL_APPLICATION_STRUCTURES';
export const STARTED_FETCHING_APPLICATION_STRUCTURES = 'STARTED_FETCHING_APPLICATION_STRUCTURES';
export const FETCHED_ALL_STRUCTURE_OPTION = 'FETCHED_ALL_STRUCTURE_OPTION';
export const STARTED_FETCHING_STRUCTURE_OPTION = 'STARTED_FETCHING_STRUCTURE_OPTION';

export const CREATE_APPLICATION_STRUCTURE_OPTION = 'CREATE_APPLICATION_STRUCTURE_OPTION';
export const CREATE_APPLICATION_STRUCTURE_OPTION_PENDING = 'CREATE_APPLICATION_STRUCTURE_OPTION_PENDING';
export const CREATE_APPLICATION_STRUCTURE_OPTION_FULFILLED = 'CREATE_APPLICATION_STRUCTURE_OPTION_FULFILLED';
export const CREATE_APPLICATION_STRUCTURE_OPTION_REJECTED = 'CREATE_APPLICATION_STRUCTURE_OPTION_REJECTED';

export const UPDATE_APPLICATION_STRUCTURE_OPTION = 'UPDATE_APPLICATION_STRUCTURE_OPTION';
export const UPDATE_APPLICATION_STRUCTURE_OPTION_PENDING = 'UPDATE_APPLICATION_STRUCTURE_OPTION_PENDING';
export const UPDATE_APPLICATION_STRUCTURE_OPTION_FULFILLED = 'UPDATE_APPLICATION_STRUCTURE_OPTION_FULFILLED';
export const UPDATE_APPLICATION_STRUCTURE_OPTION_REJECTED = 'UPDATE_APPLICATION_STRUCTURE_OPTION_REJECTED';

export const REMOVE_APPLICATION_STRUCTURE_OPTION = 'REMOVE_APPLICATION_STRUCTURE_OPTION';
export const REMOVE_APPLICATION_STRUCTURE_OPTION_PENDING = 'REMOVE_APPLICATION_STRUCTURE_OPTION_PENDING';
export const REMOVE_APPLICATION_STRUCTURE_OPTION_FULFILLED = 'REMOVE_APPLICATION_STRUCTURE_OPTION_FULFILLED';
export const REMOVE_APPLICATION_STRUCTURE_OPTION_REJECTED = 'REMOVE_APPLICATION_STRUCTURE_OPTION_REJECTED';

export const SORT_APPLICATION_STRUCTURE_CATEGORY = 'SORT_APPLICATION_STRUCTURE_CATEGORY';
export const SORT_APPLICATION_STRUCTURE_CATEGORY_FULFILLED = 'SORT_APPLICATION_STRUCTURE_CATEGORY_FULFILLED';
