import axios from '../api/axios';
import axiosConfig from '../constants/api/config';

const CMS_API_ROOT = process.env.REACT_APP_CMS_STATIC_PAGE_API || `${axiosConfig.baseUrl}/cms`;
export const TOS_PAGE_ID = process.env.REACT_APP_TOS_PAGE_ID || 'tos-page';
export const PRIVACY_POLICY_PAGE_ID = process.env.REACT_APP_PRIVACY_POLICY_PAGE_ID || 'privacy-policy-page';
export const COE_PAGE_ID = process.env.REACT_APP_COE_PAGE_ID || 'coe-page';
export const HOME_PAGE_ID = process.env.REACT_APP_HOME_PAGE_ID || 'home-page';
export const CONTACT_US_PAGE_ID = process.env.REACT_APP_CONTACT_US_PAGE_ID || 'contact-us-page';
export const NEWS_PAGE_ID = process.env.REACT_APP_NEWS_PAGE_ID || 'news-page';
export const REGISTRY_PAGE_ID = process.env.REACT_APP_REGISTRY_PAGE_ID || 'registry-page';
export const APPLY_FOR_QUALIFICATION_PAGE_ID = (
  process.env.REACT_APP_APPLY_FOR_QUALIFICATION_PAGE_ID || 'apply-for-qualification-page'
);
export const REGISTRY_HEADER_PAGE_ID = process.env.REACT_APP_REGISTRY_HEADER_PAGE_ID || 'registry-header-page';

export const getRichTextPageData = async (pageId) => {
  const currentLanguage = localStorage.getItem('currentLanguage') || 'fi';
  const response = await axios.get(
    `${CMS_API_ROOT}/pages/?type=cms.RichTextPage&slug=${pageId}&fields=body&locale=${currentLanguage}`);
  if (response.status === 200) {
    return response.data.items[0];
  }
  return null;
};


const getPageData = async (url) => {
  const response = await axios.get(url);
  if (response.status === 200) {
    return response.data.items[0];
  }
  return null;
};


export const getHomePageData = async (pageId) => {
  const currentLanguage = localStorage.getItem('currentLanguage') || 'fi';
  return getPageData(
    `${CMS_API_ROOT}/pages/?type=cms.HomePage&slug=${pageId}&fields=sections&locale=${currentLanguage}`);
};

export const getHeroPageData = async (pageId) => {
  const currentLanguage = localStorage.getItem('currentLanguage') || 'fi';
  return getPageData(
    `${CMS_API_ROOT}/pages/?type=cms.HeroPage&slug=${pageId}&fields=sections&locale=${currentLanguage}`);
};

export const getNewPageData = async () => {
  const currentLanguage = localStorage.getItem('currentLanguage') || 'fi';
  const response = await axios.get(
    `${CMS_API_ROOT}/pages/?type=cms.NewsPage&fields=body,go_live_at&locale=${currentLanguage}&order=-go_live_at`,
  );
  if (response.status === 200) {
    return response.data.items;
  }
  return null;
};

export const getSectionData = (sections, sectionId) => {
  const section = sections.find(ele => ele.value.id === sectionId);
  if (section) {
    return section.value;
  }
  return null;
};


export default getRichTextPageData;
