export const QUERY_DASHBOARD_USERS = 'QUERY_DASHBOARD_USERS';
export const QUERY_DASHBOARD_USERS_PENDING = 'QUERY_DASHBOARD_USERS_PENDING';
export const QUERY_DASHBOARD_USERS_FULFILLED = 'QUERY_DASHBOARD_USERS_FULFILLED';
export const QUERY_DASHBOARD_USERS_REJECTED = 'QUERY_DASHBOARD_USERS_REJECTED';

export const CHANGE_DASHBOARD_USERS_SORTING = 'CHANGE_DASHBOARD_USERS_SORTING';

export const DELETE_USER_WARNING_PENDING = 'DELETE_USER_WARNING_PENDING';
export const DELETE_USER_WARNING_FULFILLED = 'DELETE_USER_WARNING_FULFILLED';

export const DELETE_USER_CONFIRM = 'DELETE_USER_CONFIRM';
export const MOVE_USER_CERTIFICATES = 'MOVE_USER_CERTIFICATES';
