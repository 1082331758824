export const APPLICATION_STATE_DRAFT = 'draft';
export const APPLICATION_STATE_CHANGE_REQUEST = 'change_request';
export const APPLICATION_STATE_CHANGE_REQUEST_CAN_CANCEL = 'change_request_can_cancel';
export const APPLICATION_STATE_PENDING = 'pending';
export const APPLICATION_STATE_PENDING_RESENT = 'pending_resent';
export const APPLICATION_STATE_APPROVED_FOR_BOARD = 'approved_for_board';
export const APPLICATION_STATE_PRELIMINARY_APPROVED_BY_BOARD = 'preliminary_approved_by_board';
export const APPLICATION_STATE_PRELIMINARY_DECLINED_BY_BOARD = 'preliminary_declined_by_board';
export const APPLICATION_STATE_PRELIMINARY_CHANGE_REQUEST = 'premilinary_change_request';
export const APPLICATION_STATE_CANCELED_BY_USER = 'canceled_by_user';
export const APPLICATION_STATE_WAITING = 'waiting';
export const APPLICATION_STATE_APPROVED = 'approved';
export const APPLICATION_STATE_DECLINED = 'declined';
export const APPLICATION_STATE_APPROVED_BUT_HIDDEN = 'approved_but_hidden';


export const ADMIN_APPLICATION_STATE_TRANSITIONS = {
  [APPLICATION_STATE_DRAFT]: [
    APPLICATION_STATE_CHANGE_REQUEST,
    APPLICATION_STATE_APPROVED_FOR_BOARD,
  ],
  [APPLICATION_STATE_CHANGE_REQUEST]: [
    APPLICATION_STATE_APPROVED_FOR_BOARD,
    APPLICATION_STATE_DECLINED,
  ],
  [APPLICATION_STATE_CHANGE_REQUEST_CAN_CANCEL]: [
    APPLICATION_STATE_APPROVED_FOR_BOARD,
    APPLICATION_STATE_DECLINED,
  ],
  [APPLICATION_STATE_PENDING]: [
    APPLICATION_STATE_APPROVED_FOR_BOARD,
    APPLICATION_STATE_CHANGE_REQUEST,
  ],
  [APPLICATION_STATE_PENDING_RESENT]: [
    APPLICATION_STATE_APPROVED_FOR_BOARD,
    APPLICATION_STATE_CHANGE_REQUEST,
  ],
  [APPLICATION_STATE_APPROVED_FOR_BOARD]: [
    APPLICATION_STATE_PRELIMINARY_APPROVED_BY_BOARD,
    APPLICATION_STATE_PRELIMINARY_DECLINED_BY_BOARD,
    APPLICATION_STATE_PRELIMINARY_CHANGE_REQUEST,
  ],
  [APPLICATION_STATE_PRELIMINARY_APPROVED_BY_BOARD]: [
    APPLICATION_STATE_CHANGE_REQUEST,
    APPLICATION_STATE_APPROVED,
    APPLICATION_STATE_DECLINED,
    APPLICATION_STATE_PRELIMINARY_DECLINED_BY_BOARD,
  ],
  [APPLICATION_STATE_PRELIMINARY_DECLINED_BY_BOARD]: [
    APPLICATION_STATE_CHANGE_REQUEST,
    APPLICATION_STATE_DECLINED,
    APPLICATION_STATE_PRELIMINARY_APPROVED_BY_BOARD,
  ],
  [APPLICATION_STATE_PRELIMINARY_CHANGE_REQUEST]: [
    APPLICATION_STATE_APPROVED,
    APPLICATION_STATE_DECLINED,
    APPLICATION_STATE_CHANGE_REQUEST,
  ],
  [APPLICATION_STATE_CANCELED_BY_USER]: [],
  [APPLICATION_STATE_WAITING]: [],
  [APPLICATION_STATE_APPROVED]: [],
  [APPLICATION_STATE_DECLINED]: [],
};

export const USER_APPLICATION_STATE_TRANSITIONS = {
  [APPLICATION_STATE_CHANGE_REQUEST]: [],
  [APPLICATION_STATE_CHANGE_REQUEST_CAN_CANCEL]: [
    APPLICATION_STATE_CANCELED_BY_USER,
  ],
};

export const DO_NOT_SEND_EMAILS_STATE = [
  APPLICATION_STATE_APPROVED_FOR_BOARD,
  APPLICATION_STATE_PRELIMINARY_APPROVED_BY_BOARD,
  APPLICATION_STATE_PRELIMINARY_DECLINED_BY_BOARD,
  APPLICATION_STATE_PRELIMINARY_CHANGE_REQUEST,
];
