import axios from '../../../../../api/axios';

import * as types from '../constants/actionTypes';
import * as applicationTypes from '../../../../../constants/applications/actionTypes';

export const fetchUserApplications = userId => (dispatch) => {
  const payload = {
    userId,
  };
  dispatch({
    type: types.QUERY_USER_APPLICATIONS_PENDING,
    payload,
  });

  axios.get(`applications?user_id=${userId}`)
    .then((response) => {
      dispatch({
        type: applicationTypes.QUERY_APPLICATIONS_FULFILLED,
        payload: response,
      });

      payload.data = response.data.map(item => item.id);
      dispatch({
        type: types.QUERY_USER_APPLICATIONS_FULFILLED,
        payload,
      });
    })
    .catch((err) => {
      payload.data = err;
      dispatch({
        type: types.QUERY_USER_APPLICATIONS_REJECTED,
        payload,
      });
    });
};

export default fetchUserApplications;
