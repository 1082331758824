import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import logoutRedirect from '../actions/logoutRedirect';
import axiosConfig from '../constants/api/config';


const axiosInstance = axios.create({
  baseURL: axiosConfig.baseUrl,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('currentLanguage') || 'fi',
  },
});

// Get the default response transformers
const transformResponse = axiosInstance.defaults.transformResponse;

axiosInstance.interceptors.response.use((response) => {
  // Modify the response to split up pagination information to
  // be included as a separate response key under `pagination`

  const newResponse = cloneDeep(response);

  transformResponse.forEach((transform) => {
    newResponse.data = transform(response.data);
  });

  if (!isEmpty(response.data) && 'count' in response.data && 'results' in response.data) {
    newResponse.pagination = {
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
    };
    newResponse.data = response.data.results;
  }
  return newResponse;
}, error => new Promise((resolve, reject) => {
  const originalRequest = error.config;
  const errorDetail = error.response.data.detail;

  // Note that the error string will always be in an English string
  const validRefreshResponses = [
    'Token has expired.',
    'Signature has expired.',
    'Authentication credentials were not provided.',
  ];

  const validRefreshResponse = validRefreshResponses.indexOf(errorDetail) > -1;
  const tokenExpired = error.response.status === 401 && validRefreshResponse;

  // eslint-disable-next-line no-underscore-dangle
  if (tokenExpired && !originalRequest._retry) {
    const requestData = {
      client_id: 'fise',
    };

    axiosInstance.post(
      '/auth/refresh',
      requestData,
      { withCredentials: true, _retry: true },
    )
      .then(({ data }) => {
        const params = [];

        setAuthToken(data.token);

        if (['post', 'put', 'patch'].indexOf(originalRequest.method) > -1) {
          params.push(originalRequest.data);
        }

        params.push({
          _retry: true,
          params: originalRequest.params,
        });

        axiosInstance[originalRequest.method](
          originalRequest.url,
          ...params,
        )
          .then((result) => {
            resolve(result);
          })
          .catch((retryError) => {
            reject(retryError);
          });
      })
      .catch(() => {
        removeAllTokens();
        // eslint-disable-next-line no-restricted-globals
        logoutRedirect();
      });

    return;
  }

  reject(error);
}));

// refreshTokenInBackground -> keeps refreshing token every 10mints in the
// background to keep users logged in
export const refreshTokenInBackground = () => {
  const tenMinutesInMilliseconds = 1000 * 60 * 10;
  setInterval(() => {
    const requestData = {
      client_id: 'fise',
    };

    axiosInstance.post(
      '/auth/refresh',
      requestData,
      { _retry: true, withCredentials: true },
    )
      .then(({ data }) => {
        setAuthToken(data.token);
      })
      .catch(error => error);
  }, tenMinutesInMilliseconds);
};

const getAuthorization = () => {
  const token = localStorage.getItem('JWT_TOKEN');
  if (token) {
    return `JWT ${localStorage.getItem('JWT_TOKEN')}`;
  }
  return null;
};

export const setAuthToken = (token) => {
  localStorage.setItem('JWT_TOKEN', token);
  axiosInstance.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      'X-Authorization': getAuthorization(),
    },
  }));
};

export const setLanguage = (langCode) => {
  axiosInstance.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      'Accept-Language': langCode,
    },
  }));
};

export const removeAuthToken = () => {
  localStorage.removeItem('JWT_TOKEN');
};

export const removeAllTokens = () => {
  removeAuthToken();
  // TODO: In a reasonable amount of time this code can be removed
  // since refresh tokens has moved to cookies
  localStorage.removeItem('JWT_REFRESH_TOKEN');
};

export default axiosInstance;
