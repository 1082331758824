import { getDegrees } from '../../../../actions/degrees';
import { setUserProfileTab } from '../../../../actions/userProfile';

export const CVRoute = store => ({
  path: 'cv',
  getComponent(nextState, cb) {
    import('./containers/UserCVRoute')
      .then((module) => {
        store.dispatch(getDegrees());
        store.dispatch(setUserProfileTab('cv'));

        cb(null, module.default);
      });
  },
});

export default CVRoute;
