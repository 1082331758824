import { isEmpty, omit } from 'lodash';

import * as types from '../constants/applications/actionTypes';
import * as commentAttachmentTypes from '../constants/attachments/actionTypes';

const initialState = {};
const applicationsReducer = (state = initialState, action) => {
  let comment;
  switch (action.type) {
  case types.UPDATE_APPLICATION_VOTE_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        board_member_application_comments: state[action.payload.data.application]
          .board_member_application_comments.map(
            item => (
              item.id !== action.payload.data.id ? item : action.payload.data
            ),
          ),
      },
    };
  case types.CREATE_APPLICATION_VOTE_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        board_member_application_comments: [action.payload.data,
          ...state[action.payload.data.application].board_member_application_comments],
      },
    };
  case types.GET_APPLICATION_FULFILLED:
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };

  case types.QUERY_APPLICATIONS_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };
  case types.UPDATE_APPLICATION_FULFILLED:
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };
  case types.REMOVE_APPLICATION_FULFILLED: {
    const id = action.payload.id;
    return {
      ...omit(state, id),
    };
  }
  case types.CREATE_APPLICATION_COMMENT_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        comments: [action.payload.data, ...state[action.payload.data.application].comments],
      },
    };

  case types.UPDATE_APPLICATION_COMMENT_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        comments: state[action.payload.data.application].comments.map(
          item => (
            item.id !== action.payload.data.id ? item : action.payload.data
          ),
        ),
      },
    };

  case types.REMOVE_APPLICATION_COMMENT_FULFILLED: {
    const id = action.payload.id;
    const application = action.payload.applicationId;

    return {
      ...state,
      [application]: {
        ...state[application],
        comments: state[application].comments.filter(
          item => item.id !== id,
        ),
      },
    };
  }

  case commentAttachmentTypes.APPLICATION_COMMENT_ATTACHMENT_ADD_FULFILLED:
    return {
      ...state,
      [action.payload.applicationId]: {
        ...state[action.payload.applicationId],
        comments: state[action.payload.applicationId].comments.map(
          item => (
            item.id !== action.payload.parentId ? item : {
              ...item,
              attachments: [...item.attachments, action.payload.data],
            }
          ),
        ),
      },
    };

  case commentAttachmentTypes.APPLICATION_COMMENT_ATTACHMENT_REMOVE_FULFILLED:
    return {
      ...state,
      [action.payload.applicationId]: {
        ...state[action.payload.applicationId],
        comments: state[action.payload.applicationId].comments.map(
          item => (
            item.id !== action.payload.parentId ? item : {
              ...item,
              attachments: [
                ...item.attachments.filter(
                  attachment => attachment.id !== action.payload.fileId,
                ),
              ],
            }
          ),
        ),
      },
    };

  case types.CREATE_APPLICATION_NOTE_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        notes: [action.payload.data, ...state[action.payload.data.application].notes],
      },
    };
  case types.UPDATE_APPLICATION_NOTE_FULFILLED:
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        notes: state[action.payload.data.application].notes.map(
          item => (
            item.id !== action.payload.data.id ? item : action.payload.data
          ),
        ),
      },
    };

  case types.REMOVE_APPLICATION_NOTE_FULFILLED: {
    const id = action.payload.id;
    const application = action.payload.applicationId;

    return {
      ...state,
      [application]: {
        ...state[application],
        notes: state[application].notes.filter(
          item => item.id !== id,
        ),
      },
    };
  }
  case types.CREATE_APPLICATION_STATE_FULFILLED:
    if (!isEmpty(action.payload.data.comment)) {
      comment = {
        ...action.payload.data.comment,
        application_history_state: action.payload.data.state,
      };
      return {
        ...state,
        [action.payload.data.application]: {
          ...state[action.payload.data.application],
          history: [action.payload.data, ...state[action.payload.data.application].history],
          comments: [comment, ...state[action.payload.data.application].comments],
          latest_state: action.payload.data.state,
        },
      };
    }
    return {
      ...state,
      [action.payload.data.application]: {
        ...state[action.payload.data.application],
        history: [action.payload.data, ...state[action.payload.data.application].history],
        latest_state: action.payload.data.state,
      },
    };
  default:
    return state;
  }
};

export default applicationsReducer;
