import React from 'react';
import PropTypes from 'prop-types';

import styles from './MobileMenuButton.less';


const MobileMenuButton = (props) => {
  let menuOpenClass = '';
  if (props.menu.mobileMenuOpened) {
    menuOpenClass += styles.mobileMenuOpen;
  }

  return (
    <button className={`${styles.mobileNavToggle} ${menuOpenClass}`} onClick={props.toggleMobileMenu}>
      <div className={styles.hamburger} />
    </button>
  );
};

MobileMenuButton.propTypes = {
  menu: PropTypes.shape({
    mobileMenuOpened: PropTypes.bool.isRequired,
  }).isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export default MobileMenuButton;
