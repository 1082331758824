import axios from '../../../../../api/axios';
import * as types from '../../../../../constants/statistics/actionTypes';

const queryStatistics = (fromDate, toDate, quickFilter) => (dispatch) => {
  dispatch({
    type: types.QUERY_STATISTICS_PENDING,
    payload: {},
  });
  return axios.get('/statistics', {
    params: { from_date: fromDate, to_date: toDate, quick_filter: quickFilter } })
    .then((response) => {
      dispatch({
        type: types.QUERY_STATISTICS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.QUERY_STATISTICS_REJECTED,
        payload: error.response,
      });
    });
};

export const queryCertStatistics = internalId => (dispatch) => {
  dispatch({
    type: types.QUERY_STATISTICS_PENDING,
    payload: {},
  });
  return axios.get('/statistics', {
    params: { internal_id: internalId } })
    .then((response) => {
      dispatch({
        type: types.QUERY_STATISTICS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.QUERY_STATISTICS_REJECTED,
        payload: error.response,
      });
    });
};
export default queryStatistics;
