import store from '../store';

const requireAuth = (nextState, replace) => {
  const state = store.getState();
  if (!state.auth.loggedIn) {
    replace({
      pathname: '/',
    });
  }
};

export default requireAuth;
