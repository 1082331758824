import React from 'react';
import { FormattedMessage } from 'react-intl';

const isIE = /* @cc_on!@ */false || !!document.documentMode;

const IEWarningMessage = () => (
  <span>
    {isIE &&
    <div className="alert alert-danger text-center" role="alert">
      <FormattedMessage
        id="iEWarningMessage.body"
        defaultMessage="
        I looks like you are using Internet Explorer. We currently do not fully support
        your browser and highly recommend the use of Chrome, Edge, Firefox or Safari instead.
        There might be parts of the service that might not as expected if you continue using
        Internet Explorer.
        "
      />
    </div>}
  </span>
);


export default IEWarningMessage;
