import { all, fork, takeEvery } from 'redux-saga/effects';

import * as types from '../constants/locale/actionTypes';
import { setLanguage } from '../api/axios';
import cmsConfig from '../constants/cms/config';

function selectLocale(action) {
  const currentLanguage = localStorage.getItem('currentLanguage');
  if (currentLanguage !== action.payload) {
    localStorage.setItem('currentLanguage', action.payload);
    setLanguage(action.payload);
    if (!window.location.href.startsWith(cmsConfig.cmsBaseUrl)) {
      window.location.reload();
    } else {
      window.location.href = cmsConfig.cmsBaseUrl;
    }
  }
}

function autoChangeLocale(action) {
  const currentLanguage = localStorage.getItem('currentLanguage');
  if (currentLanguage !== action.payload) {
    localStorage.setItem('currentLanguage', action.payload);
    setLanguage(action.payload);
    window.location.reload();
  }
}

export function* watchLocaleSaga() {
  yield all([
    takeEvery(types.LOCALE_SELECTED, selectLocale),
    takeEvery(types.LOCALE_AUTO_CHANGE, autoChangeLocale),
  ]);
}

export default function* localeSagas() {
  yield all([
    fork(watchLocaleSaga),
  ]);
}
