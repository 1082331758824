import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';

import axios from '../../../../../api/axios';
import * as types from '../../../../../constants/boards/actionTypes';

export const queryBoards = filterParams => (dispatch) => {
  const filters = isEmpty(filterParams) ? {} : filterParams;

  if (!('page' in filters)) {
    filters.page = 1;
  }

  const queryParams = { filters };

  const payload = {
    queryParams,
  };

  dispatch({
    type: types.QUERY_BOARDS_PENDING,
    payload,
  });

  return axios.get('/boards', { params: { ...queryParams.filters } })
    .then((response) => {
      const boards = {};
      for (const board of response.data) {
        boards[board.id] = board;
      }
      payload.response = {
        ...response,
        boards,
      };
      return dispatch({
        type: types.QUERY_BOARDS_FULFILLED,
        payload,
      });
    })
    .catch((error) => {
      payload.response = error;
      return dispatch({
        type: types.QUERY_BOARDS_REJECTED,
        payload,
      });
    });
};

export const getBoard = boardId => (dispatch) => {
  dispatch({
    type: types.QUERY_BOARD_PENDING,
    payload: {},
  });
  return axios.get(`/boards/${boardId}`)
    .then((response) => {
      dispatch({
        type: types.QUERY_BOARD_FULFILLED,
        payload: response.data,
      });
    })
    .catch(error => dispatch(push('404')));
};

export default queryBoards;
