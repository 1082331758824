import React from 'react';
import PropTypes from 'prop-types';

const NavDropdownToggle = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <button href="" onClick={handleClick}>
      {props.children}
    </button>
  );
};

NavDropdownToggle.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

NavDropdownToggle.defaultProps = {
  onClick: (e) => {
    e.preventDefault();
  },
};

export default NavDropdownToggle;
