import { push } from 'react-router-redux';

import axios, { removeAllTokens, setAuthToken } from '../api/axios';
import { fetchUser } from './user';
import { hideAllModals } from './modal';
import * as types from '../constants/auth/actionTypes';
import templateToastr from '../utils/toastr';

export const requestLogin = (username, password, redirect = false) => (dispatch) => {
  dispatch({
    type: types.LOGIN_PENDING,
    payload: {},
  });

  return axios.post(
    '/auth/login', {
      email: username,
      password,
    },
    { withCredentials: true },
  )
    .then(
      (response) => {
        setAuthToken(response.data.token);
        setTimeout(() => dispatch(fetchUser('me', redirect)), 100);
        dispatch(hideAllModals());
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: types.LOGIN_REJECTED,
          payload: error,
        });
        return Promise.reject(error);
      },
    );
};

export const setLoginUser = user => (dispatch) => {
  dispatch({
    type: types.LOGIN_FULFILLED,
    payload: {
      currentUser: user.id,
      userGroups: user.groups,
      emailVerified: user.email_verified,
    },
  });
};

export const logout = () => (dispatch) => {
  axios.post('/auth/logout', {}, { withCredentials: true, _retry: true })
    .then(() => {
      removeAllTokens();
      dispatch({
        type: types.LOGOUT_FULFILLED,
        payload: {},
      });

      // Do not redirect to homepage if it has failure
      return window.location.pathname.includes('failure') ? undefined : dispatch(push('/'));
    });
};

export const requestPasswordReset = data => (dispatch) => {
  dispatch({
    type: types.PASSWORD_RESET_REQUEST_PENDING,
    payload: {},
  });

  return axios.post('/auth/request_password_reset', {
    email: data.email,
  })
    .then(
      (response) => {
        dispatch({
          type: types.PASSWORD_RESET_REQUEST_FULFILLED,
          payload: response,
        });
        dispatch(hideAllModals());
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: types.PASSWORD_RESET_REQUEST_REJECTED,
          payload: error,
        });
      });
};

export const resetPassword = data => (dispatch) => {
  dispatch({
    type: types.PASSWORD_RESET_PENDING,
    payload: {},
  });

  return axios.post('/auth/reset_password/', {
    data,
  })
    .then(
      (response) => {
        templateToastr('success', 'passwordResetSuccess');
        dispatch({
          type: types.PASSWORD_RESET_FULFILLED,
          payload: response,
        });
        dispatch(push('/'));
      },
      (error) => {
        templateToastr('warning', 'passwordResetFail');
        dispatch({
          type: types.PASSWORD_RESET_REJECTED,
          payload: error,
        });
      });
};

export const registerUser = (userFormData, resolve, reject) => ({
  type: types.REGISTER_NEW_USER,
  payload: {
    userFormData,
    resolve,
    reject,
  },
});

export const requestEmailVerification = (email, userId) => (dispatch) => {
  dispatch({
    type: types.REQUEST_EMAIL_VERIFICATION_PENDING,
  });

  return axios.post(`/users/${userId}/resend_verification_email`, {
    email,
  });
};

export const getJwtFromPermissionUuid = async (permissionToken) => {
  const response = await axios.get(`/applications/verify_permission_token/${permissionToken}`);
  return response.data.token;
};
