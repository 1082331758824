import { uniqueId } from 'lodash';
import { connect } from 'react-redux';

import LoginButton from '../components/LoginButton';
import { requestLogin } from '../actions/auth';
import { hideModal, showModal } from '../actions/modal';
import { raiseFormErrors } from '../utils/forms';
import templateToastr from '../utils/toastr';

const buttonId = uniqueId();

const login = (data) => {
  const email = data.email;
  const password = data.password;
  const redirect = true;
  const { pathname, href } = window.location;
  return dispatch => dispatch(requestLogin(email, password, redirect))
    .then(() => {
      templateToastr('success', 'loggedIn');
      if (pathname === '/' && href) {
        window.location.href = href;
      }
    }, error => raiseFormErrors(error.response),
    );
};

const show = () => (dispatch) => {
  dispatch(showModal('login', buttonId));
};

const hide = () => (dispatch) => {
  dispatch(hideModal('login', buttonId));
};

const mapDispatchToProps = {
  show,
  hide,
  login,
};

const mapStateToProps = state => ({
  showModal: state.modals[`login_${buttonId}`],
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);

