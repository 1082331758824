const FailureRoute = () => ({
  path: '/failure',
  getComponent(nextState, cb) {
    import('./FailureView')
      .then(async module => cb(null, module.default));
  },
});


export default FailureRoute;
