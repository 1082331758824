const NotFoundRoute = () => ({
  path: '*',
  getComponent(nextState, cb) {
    import('./NotFoundView')
      .then(async module => cb(null, module.default));
  },
});


export default NotFoundRoute;
