import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import templateMessages from './templates';

const ServerErrorToast = (props) => {
  const title = props.intl.formatMessage(templateMessages.defaultError_title);
  let message = '';

  const error = props.errorResponse.data;
  if (!isEmpty(error) && !isEmpty(error.detail)) {
    message = error.detail;
  }

  return (
    <div>
      {title &&
      <div className="title">{title}</div>}
      {message &&
      <div className="message">{message}</div>}
    </div>
  );
};

ServerErrorToast.propTypes = {
  intl: intlShape.isRequired,
  errorResponse: PropTypes.shape({
    data: PropTypes.shape({ detail: PropTypes.string }),
  }),
};

ServerErrorToast.defaultProps = {
  errorResponse: {},
};

export default injectIntl(ServerErrorToast);
