import axios from '../api/axios';
import * as feedTypes from '../constants/userGroups/actionTypes';

export const getUserGroups = data => dispatch => dispatch({
  type: feedTypes.QUERY_USER_GROUPS,
  payload: axios.get('/usergroups', data),
});

export const updateUserGroup = (userId, data) => dispatch => dispatch({
  type: feedTypes.UPDATE_USER_GROUPS,
  payload: axios.patch(`/users/${userId}`, { groups: data.user.user.groups }),
});

export const addNewGroupToList = data => dispatch => dispatch({
  type: feedTypes.UPDATE_UI_GROUP_LIST_ADD_FULFILLED,
  payload: data,
});

export const removeGroup = data => dispatch => dispatch({
  type: feedTypes.UPDATE_UI_GROUP_LIST_REMOVE_FULFILLED,
  payload: data,
});
