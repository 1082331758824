import { connect } from 'react-redux';

import RegisterLink from '../components/RegisterLink';
import { showModal } from '../actions/modal';


const show = () => (dispatch) => {
  dispatch(showModal('registeruser'));
};

const mapDispatchToProps = {
  show,
};

const mapStateToProps = state => ({
  showModal: state.modals.registeruser,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterLink);
