import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthShape from '../../../structures/auth';
import templateToastr from '../../../utils/toastr';

import Hero from '../../../components/Hero';
import IEWarningMessage from '../../../components/IEWarningMessage';
import { getHomePageData, getSectionData, HOME_PAGE_ID } from '../../../utils/cms';
import BorderSection from './BorderSection';
import BorderTitleSection from './BorderSection/BorderTitle';

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heroSectionContent: {},
      borderSectionContent: [],
    };
  }

  componentDidMount() {
    // Fire a toast if a status message is set
    const status = this.props.location.query.status;
    const { file_url: fileUrl } = this.props.location.query;
    if (fileUrl) {
      this.props.downloadAttachmentFile({ file: fileUrl });
    }
    if (status) {
      // To make sure that everything is up and running
      // we wait for half a second before firing.
      setTimeout(() => {
        templateToastr('success', status);
      }, 500);
    }

    const asyncGetPageData = async () => {
      const jsonHomePageData = await getHomePageData(this.props.pageId);
      if (jsonHomePageData !== null && jsonHomePageData !== undefined) {
        this.setState({
          borderSectionContent: jsonHomePageData.sections.filter(section => section.type !== 'section'),
          heroSectionContent: getSectionData(jsonHomePageData.sections, this.props.sectionId),
        });
      }
    };
    asyncGetPageData();
  }

  render() {
    return (
      <div>
        <main className="main-content container-fluid">
          <div className="flex-row">
            <div className="col">
              <IEWarningMessage />
            </div>
          </div>
        </main>
        <Hero heroSectionContent={this.state.heroSectionContent} />
        <div className="container">
          {this.state.borderSectionContent.map((section) => {
            if (section.type === 'title_border_section') {
              return <BorderTitleSection section={section.value} key={section.id} />;
            } else if (section.type === 'border_section') {
              return <BorderSection section={section.value} key={section.id} />;
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}

HomeView.propTypes = {
  downloadAttachmentFile: PropTypes.func,
  auth: AuthShape.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      status: PropTypes.string,
      file_url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  pageId: PropTypes.string,
  applyForCertificationSectionId: PropTypes.string,
  certificateRegisterSectionId: PropTypes.string,
  sectionId: PropTypes.string,
};

HomeView.defaultProps = {
  downloadAttachmentFile: () => {},
  pageId: HOME_PAGE_ID,
  applyForCertificationSectionId: 'apply-for-certification',
  certificateRegisterSectionId: 'certificate-register',
  sectionId: 'homepage-banner',
};

export default HomeView;
