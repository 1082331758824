import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ChildModal from '../../containers/ChildModal';
import { getRichTextPageData, TOS_PAGE_ID } from '../../utils/cms';

const TermsOfServiceModal = (props) => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const asyncGetPageData = async () => {
      const jsonPageData = await getRichTextPageData(props.pageId);
      if (jsonPageData != null) {
        setPageData(jsonPageData);
      }
    };
    asyncGetPageData();
  }, [props.pageId]);

  return (
    <ChildModal
      replace={props.replaceModal}
      title={<FormattedMessage
        id="termOfService.modalTitle"
        defaultMessage="Terms of Service"
      />}
      id={`termsOfService_${props.id}`}
      modalSize="large"
      type="text-link"
      buttonText={<FormattedMessage
        id="termOfService.modalOpenButtonText"
        defaultMessage="Terms of Service"
      />}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: pageData.body }} />
    </ChildModal>
  );
};

TermsOfServiceModal.propTypes = {
  id: PropTypes.string.isRequired,
  replaceModal: PropTypes.bool,
  pageId: PropTypes.string,
};

TermsOfServiceModal.defaultProps = {
  replaceModal: true,
  pageId: TOS_PAGE_ID,
};


export default TermsOfServiceModal;
