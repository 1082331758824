import React from 'react';
import PropTypes from 'prop-types';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

const ChildModal = (props) => {
  let { title } = props;
  if (typeof props.title === 'object') {
    title = props.intl.formatMessage({ ...props.title.props });
  }

  const openButtonIcon = props.type === 'create' ? 'fa-plus' : 'fa-pencil';
  let openButtonText = props.buttonText;
  if (props.buttonText === null) {
    openButtonText = props.type === 'create'
      ? (<FormattedMessage id="childModal.create" defaultMessage="Create new" />)
      : (<FormattedMessage id="childModal.edit" defaultMessage="Edit" />);
  }

  const buttonSize = props.button ? 'btn-sm' : `btn-${props.buttonSize}`;

  let openButton;

  if (props.button) {
    openButton = React.cloneElement(
      props.button,
      {
        onMouseDown: (e) => {
          props.show(props.id);
        },
      },
    );
  } else if (props.type === 'create') {
    openButton = (
      <button
        className="btn btn-sm btn-create"
        onClick={() => props.show(props.id)}
        title={title}
        type="button"
      >
        <i className={`fa fa-fw ${openButtonIcon}`} /> {openButtonText}
      </button>
    );
  } else if (props.type === 'text-link') {
    openButton = (
      <button
        className="btn-text"
        onClick={() => props.show(props.id)}
        title={title}
        type="button"
      >
        {openButtonText}
      </button>
    );
  } else if (props.type === 'custom') {
    openButton = (
      <button
        className={`btn ${buttonSize} ${props.buttonClass}`}
        onClick={() => {
          props.show(props.id);
          props.extraButtonAction?.();
        }}
        title={title}
        type="button"
        disabled={props.disabled}
      >
        {props.buttonIcon ? <i className={`fa fa-fw ${props.buttonIcon}`} /> : null}
        {openButtonText}
      </button>
    );
  } else {
    openButton = (
      <button
        className="btn btn-xs btn-edit"
        onClick={() => props.show(props.id)}
        title={title}
        type="button"
      >
        <i className={`fa fa-fw ${openButtonIcon}`} /> {openButtonText}
      </button>
    );
  }

  if (props.buttonTooltip) {
    openButton = (
      <OverlayTrigger trigger={props.trigger} placement={props.buttonTooltipPosition} overlay={props.buttonTooltip}>
        {openButton}
      </OverlayTrigger>
    );
  }

  return (
    <span>
      {openButton}
      <Modal
        show={props.showModal}
        onHide={() => props.hide(props.id)}
        bsSize={props.modalSize}
        backdrop="static"
        dialogClassName={props.dialogClassName}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>
    </span>
  );
};

ChildModal.propTypes = {
  hide: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  title: PropTypes.node.isRequired,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonClass: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.node,
  buttonSize: PropTypes.string,
  modalSize: PropTypes.string,
  button: PropTypes.node,
  buttonTooltip: PropTypes.node,
  buttonTooltipPosition: PropTypes.string,
  intl: intlShape.isRequired,
  trigger: PropTypes.arrayOf(PropTypes.string),
  extraButtonAction: PropTypes.func,
  disabled: PropTypes.bool,
  dialogClassName: PropTypes.string,
};

ChildModal.defaultProps = {
  type: 'create',
  showModal: false,
  buttonClass: '',
  buttonIcon: '',
  buttonText: null,
  buttonSize: null,
  modalSize: null,
  button: undefined,
  buttonTooltip: undefined,
  buttonTooltipPosition: 'top',
  trigger: ['hover'],
  extraButtonAction: () => {},
  disabled: false,
  dialogClassName: '',
};

export default injectIntl(ChildModal);
