export const downloadBlob = (blob, filename = 'download') => {
  // Handle IE Edge
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }

  // Create object URL
  const blobURL = window.URL.createObjectURL(blob);

  // Create hidden link element
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  // Add element to DOM
  document.body.appendChild(tempLink);

  // Click link
  tempLink.click();

  // Remove element
  document.body.removeChild(tempLink);

  // Release the object URL
  window.URL.revokeObjectURL(blobURL);
};

export default downloadBlob;
