import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { queryUsers } from './actions/users';

const UsersRoute = store => ({
  path: 'users',
  authorize: {
    groups: [USER_GROUPS.PERSONNEL, USER_GROUPS.ADMINISTRATOR],
  },
  getComponent(nextState, cb) {
    import('./components/UsersListView').then(async (module) => {
      const usersListReducer = await import('./reducers/users');
      store.injectReducer({ key: 'usersList', reducer: usersListReducer.default });

      const state = store.getState();
      if (state.usersList.dataTotalSize === 0) {
        store.dispatch(queryUsers());
      }
      cb(null, module.default);
    });
  },
});

export default UsersRoute;
