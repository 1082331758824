import { defineMessages } from 'react-intl';

const toastrTemplateMessages = defineMessages({
  saved_title: {
    id: 'intlToastr.template.title.saved',
    defaultMessage: 'Saved',
  },
  saved_message: {
    id: 'intlToastr.template.message.saved',
    defaultMessage: '{model} successfully saved.',
  },
  updated_title: {
    id: 'intlToastr.template.title.updated',
    defaultMessage: 'Updated',
  },
  updated_message: {
    id: 'intlToastr.template.message.updated',
    defaultMessage: '{model} successfully updated.',
  },
  removed_title: {
    id: 'intlToastr.template.title.removed',
    defaultMessage: 'Removed',
  },
  removed_message: {
    id: 'intlToastr.template.message.removed',
    defaultMessage: '{model} successfully removed.',
  },
  loggedIn_title: {
    id: 'intlToastr.template.title.loggedIn',
    defaultMessage: 'Logged in',
  },
  loggedOut_title: {
    id: 'intlToastr.template.title.loggedOut',
    defaultMessage: 'Logged out',
  },
  registered_title: {
    id: 'intlToastr.template.title.registered',
    defaultMessage: 'You have been registered',
  },
  registeredRedirect_title: {
    id: 'intlToastr.template.title.registeredRedirect',
    defaultMessage: 'You have been registered',
  },
  registeredRedirect_message: {
    id: 'intlToastr.template.message.registeredRedirect',
    defaultMessage: 'You are now logged in.',
  },
  forgotPasswordLinkSent_title: {
    id: 'intlToastr.template.title.forgotPasswordLinkSent',
    defaultMessage: 'Password reset link has been sent',
  },
  forgotPasswordLinkSent_message: {
    id: 'intlToastr.template.message.forgotPasswordLinkSent',
    defaultMessage: 'You should receive an email with password reset link soon.',
  },
  passwordResetSuccess_title: {
    id: 'intlToastr.template.title.passwordResetSuccess',
    defaultMessage: 'Password reset done!',
  },
  passwordResetFail_title: {
    id: 'intlToastr.template.title.passwordResetFail',
    defaultMessage: 'Password resetting failed!',
  },
  defaultError_title: {
    id: 'intlToastr.template.title.default',
    defaultMessage: 'A problem occurred',
  },
  sendEmailVerificationSuccess_title: {
    id: 'intlToastr.template.title.sendEmailVerificationSuccess',
    defaultMessage: 'Verification email sent.',
  },
});

export default toastrTemplateMessages;
