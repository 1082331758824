const wagtailRootPageTemplate = process.env.REACT_APP_BACKEND_WAGTAIL_ROOT_PAGE_TEMPLATE;
const wagtailBaseUrl = process.env.REACT_APP_BACKEND_WAGTAIL_BASE_URL;
const cmsBaseUrl = process.env.REACT_APP_CMS_BASE_URL;
const getRootPageByLanguageCode =
    languageCode => wagtailRootPageTemplate.replace('{language_code}', languageCode);

const config = {
  wagtailBaseUrl,
  wagtailRootPageTemplate,
  cmsBaseUrl,
  getRootPageByLanguageCode,
};
export default config;
