import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import FooterLogo from './img/footer-logo.png';
import styles from './Footer.less';
import PrivacyPolicy from '../Modals/PrivacyPolicy';


const getCurrentYear = () => {
  const today = new Date();
  return (today.getFullYear());
};

const Footer = () => (
  <footer className={styles.siteFooter} id="site-footer">
    <div className={styles.siteFooterTop}>
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <img src={FooterLogo} alt="FISE" />
          </div>
          <div className="col-sm-8">
            <ul className={styles.linkList}>
              <li>
                <a
                  href="https://patevyyspalvelu.fi/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="footer.contactUs"
                    defaultMessage="Contact Us"
                  />
                </a>
              </li>
              <li>
                <a href="http://fise.fi">
                  <FormattedMessage
                    id="footer.aboutUs"
                    defaultMessage="About Fise"
                  />
                </a>
              </li>
              <li>
                <PrivacyPolicy id="footer" />
              </li>
            </ul>
          </div>
          <div className={`col-sm-2 ${styles.linkIcons}`}>
            <a href="https://www.linkedin.com/company/fiseoy/" target="_Blank" rel="noreferrer">
              <i className="fa fa-linkedin" />
            </a>
            <a href="https://twitter.com/fiseoy" target="_Blank" rel="noreferrer"><i className="fa fa-twitter" /></a>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.siteFooterBottom}>
      <div className="container">
        <FormattedHTMLMessage
          id="footer.copyright"
          values={{ year: getCurrentYear() }}
          defaultMessage="&copy; {year} FISE. All rights reserved."
        />
      </div>
    </div>
  </footer>
);

export default Footer;
