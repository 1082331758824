import React from 'react';
import CookieBanner from 'react-cookie-banner';
import { defineMessages, injectIntl, intlShape } from 'react-intl';


export const cookieMessages = defineMessages({
  bodyText: {
    id: 'cookieConsent.body',
    defaultMessage: 'This site uses cookies and other storage techniques to assist with navigating ' +
    'and using the site as well as provide content from third parties. You consent to us using these storage ' +
    'techniques if you continue to use this website.',
  },
  buttonText: {
    id: 'cookieConsent.button',
    defaultMessage: 'I accept',
  },
});


const CookieConsent = props => (
  <CookieBanner
    message={props.intl.formatMessage(cookieMessages.bodyText)}
    buttonMessage={props.intl.formatMessage(cookieMessages.buttonText)}
    cookie="user-has-accepted-cookies-v1"
    dismissOnScroll={false}
    disableStyle
  />
);

CookieConsent.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CookieConsent);
