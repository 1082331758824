import PropTypes from 'prop-types';

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  country_data: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  country: PropTypes.string,
  home_phone: PropTypes.string,
  mobile_phone: PropTypes.string,
  token_authenticated: PropTypes.bool,
  anonymous: PropTypes.bool,
  approve_email_sharing: PropTypes.bool,
  operational_area_data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    region: PropTypes.string,
    verbose_region: PropTypes.string,
  })),
});

export default UserShape;
