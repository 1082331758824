import { isEmpty } from 'lodash';

import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';
import { queryUserCertifications } from './actions/registry';
import { fetchAllPages } from '../../actions/utils';
import {
  queryApplicationStructureOptions,
  queryCurrentActiveApplicationStructures,
  hasFetchedAllApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../actions/structures';
import { getOperationalAreas } from '../../actions/operationalareas';
import { fetchUser } from '../../actions/user';

export const RegistryRoute = store => ({
  path: 'registry',
  getComponent(nextState, cb) {
    import('./containers/RegistryRoute').then(async (module) => {
      const registryReducer = await import('./reducers/registry');
      store.injectReducer({ key: 'registry', reducer: registryReducer.default });

      const state = store.getState();

      // Fetch all application, excluding drafts if there is no data fetched from before
      // NOTE: The [1] marks the first page for pagination
      if (isEmpty(state.registry.data[1])) {
        store.dispatch(queryUserCertifications({}));
      }

      if (!state.structures.fetchedAll && !state.structures.startedFetching) {
        store.dispatch(hasStartedFetchingApplicationStructures());
        store.dispatch(fetchAllPages(
          queryCurrentActiveApplicationStructures,
          { stripped: true },
          hasFetchedAllApplicationStructures,
        ));
      }
      store.dispatch(getOperationalAreas());
      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
      store.dispatch(setActivePage(menuPages.REGISTRY_PAGE));
      cb(null, module.default);
    });
  },
});

export const RegistryDetailRoute = store => ({
  path: 'registry/:registryId',
  getComponent(nextState, cb) {
    import('./containers/RegistryDetailRoute').then(async (module) => {
      const registryDetailReducer = await import('./reducers/registryDetail');
      store.injectReducer({ key: 'registryDetail', reducer: registryDetailReducer.default });
      const state = store.getState();

      if (isEmpty(state.users[nextState.params.registryId])) {
        store.dispatch(fetchUser(nextState.params.registryId));
      }
      store.dispatch(setActivePage(menuPages.REGISTRY_PAGE));
      cb(null, module.default);
    });
  },
});
