import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './style.less';
import DangerousDiv from '../../../../utils/DangerousDiv';


class BorderTitleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
    };
  }

  render() {
    return (
      <div className={styles.borderSection}>
        <div className={styles.headerContainer}>
          <div className={styles.leftBorder} />
          <h2>{this.state.section.title}</h2>
        </div>
        <div className={styles.textContainer}>
          <DangerousDiv innerHTML={this.state.section.text} key={this.state.section.id} className="" />
        </div>
      </div>
    );
  }
}


BorderTitleSection.propTypes = {
  section: PropTypes.shape({}),
};

BorderTitleSection.defaultProps = {
  section: {},
};


export default BorderTitleSection;
