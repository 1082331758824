export const QUERY_USERS = 'QUERY_USERS';
export const QUERY_USERS_PENDING = 'QUERY_USERS_PENDING';
export const QUERY_USERS_FULFILLED = 'QUERY_USERS_FULFILLED';
export const QUERY_USERS_REJECTED = 'QUERY_USERS_REJECTED';

export const USER_REFERENCE_CREATE = 'USER_REFERENCE_CREATE';
export const USER_REFERENCE_CREATE_FULFILLED = 'USER_REFERENCE_CREATE_FULFILLED';

export const USER_REFERENCE_UPDATE = 'USER_REFERENCE_UPDATE';
export const USER_REFERENCE_UPDATE_FULFILLED = 'USER_REFERENCE_UPDATE_FULFILLED';

export const USER_REFERENCE_DESTROY = 'USER_REFERENCE_DESTROY';
export const USER_REFERENCE_DESTROY_PENDING = 'USER_REFERENCE_DESTROY_PENDING';
export const USER_REFERENCE_DESTROY_FULFILLED = 'USER_REFERENCE_DESTROY_PENDING';
export const USER_REFERENCE_DESTROY_REJECTED = 'USER_REFERENCE_DESTROY_REJECTED';

export const USER_ATTRIBUTE_CREATE = 'USER_ATTRIBUTE_CREATE';
export const USER_ATTRIBUTE_CREATE_PENDING = 'USER_ATTRIBUTE_CREATE_PENDING';
export const USER_ATTRIBUTE_CREATE_FULFILLED = 'USER_ATTRIBUTE_CREATE_FULFILLED';
export const USER_ATTRIBUTE_CREATE_REJECTED = 'USER_ATTRIBUTE_CREATE_REJECTED';

export const USER_ATTRIBUTE_UPDATE = 'USER_ATTRIBUTE_UPDATE';
export const USER_ATTRIBUTE_UPDATE_PENDING = 'USER_ATTRIBUTE_UPDATE_PENDING';
export const USER_ATTRIBUTE_UPDATE_FULFILLED = 'USER_ATTRIBUTE_UPDATE_FULFILLED';
export const USER_ATTRIBUTE_UPDATE_REJECTED = 'USER_ATTRIBUTE_UPDATE_REJECTED';

export const USER_ATTRIBUTE_REMOVE = 'USER_ATTRIBUTE_REMOVE';
export const USER_ATTRIBUTE_REMOVE_PENDING = 'USER_ATTRIBUTE_REMOVE_PENDING';
export const USER_ATTRIBUTE_REMOVE_FULFILLED = 'USER_ATTRIBUTE_REMOVE_FULFILLED';
export const USER_ATTRIBUTE_REMOVE_REJECTED = 'USER_ATTRIBUTE_REMOVE_REJECTED';
