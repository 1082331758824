import { isEmpty } from 'lodash';

import * as types from '../constants/feeds/actionTypes';


const initialValue = {};

const feedsDataReducer = (state = initialValue, action) => {
  let model;
  let modelId;
  let responseData;
  let responsePagination;
  let modelIdObject;
  let modelIdData;

  switch (action.type) {
  case types.GET_FEED_DATA_FULFILLED:
    model = action.payload.model;
    modelId = action.payload.modelId;
    responseData = action.payload.response.data;
    responsePagination = action.payload.response.pagination;

    if (!model || !modelId || !responseData) {
      return state;
    }

    modelIdObject = state[model] ? { ...state[model][modelId] } : {};
    modelIdData = !isEmpty(modelIdObject.data) ? [...state[model][modelId].data] : [];
    return {
      ...state,
      [model]: {
        ...state[model],
        [modelId]: {
          ...modelIdObject,
          data: [
            ...modelIdData,
            ...responseData,
          ],
          ...responsePagination,
          loading: false,
        },
      },
    };
  case types.GET_FEED_DATA_PENDING:
    model = action.payload.model;
    modelId = action.payload.modelId;

    modelIdObject = state[model] ? { ...state[model][modelId], loading: true } : {};

    return {
      ...state,
      [model]: {
        ...state[model],
        [modelId]: {
          ...modelIdObject,
        },
      },
    };
  case types.GET_FEED_DATA_REJECTED:
    model = action.payload.model;
    modelId = action.payload.modelId;

    modelIdObject = state[model] ? { ...state[model][modelId] } : {};

    return {
      ...state,
      [model]: {
        ...state[model],
        [modelId]: {
          ...modelIdObject,
          loading: false,
        },
      },
    };
  default:
    return state;
  }
};

export default feedsDataReducer;
