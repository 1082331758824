const initialState = {
  tab: 'cv',
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_USER_PROFILE_TAB':
    return {
      ...state,
      tab: action.payload,
    };
  default:
    return state;
  }
};

export default userProfileReducer;
