import axios from '../api/axios';
import * as feedTypes from '../constants/userNotes/actionTypes';


export const createUserNotes = (userId, formData) => (dispatch) => {
  const payload = {
    userId,
  };

  dispatch({
    type: feedTypes.CREATE_NOTE_PENDING,
    payload,
  });

  axios.post(`/users/${userId}/notes`, formData)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: feedTypes.CREATE_NOTE_FULFILLED,
          payload,
        });
      },
      (error) => {
        payload.error = error;
        dispatch({
          type: feedTypes.CREATE_NOTE_REJECTED,
          payload,
        });
      },
    );
};

export const queryUserNotes = userId => (dispatch) => {
  const payload = {
    userId,
  };

  dispatch({
    type: feedTypes.QUERY_NOTES_PENDING,
    payload,
  });

  axios.get(`/users/${userId}/notes`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: feedTypes.QUERY_NOTES_FULFILLED,
          payload,
        });
      },
      (error) => {
        payload.error = error;
        dispatch({
          type: feedTypes.QUERY_NOTES_REJECTED,
          payload,
        });
      },
    );
};


export const removeUserNote = (userId, noteId) => (dispatch) => {
  const payload = {
    userId,
    noteId,
  };

  dispatch({
    type: feedTypes.DESTROY_NOTE_PENDING,
    payload,
  });

  return axios.delete(`/users/${userId}/notes/${noteId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: feedTypes.DESTROY_NOTE_FULFILLED,
          payload,
        });
      },
      (error) => {
        payload.error = error;
        dispatch({
          type: feedTypes.DESTROY_NOTE_REJECTED,
          payload,
        });
      },
    );
};
