import * as types from '../constants/attachments/actionTypes';


export const addAttachedFile = (applicationId, userId, model, parentId, files, formName, fieldName) => ({
  type: types.ATTACHMENT_ADD,
  payload: {
    applicationId,
    userId,
    model,
    parentId,
    files,
    formName,
    fieldName,
  },
});

export const removeAttachedFile = (applicationId, userId, model, parentId, file, formName, fieldName) => ({
  type: types.ATTACHMENT_REMOVE,
  payload: {
    applicationId,
    userId,
    model,
    parentId,
    file,
    formName,
    fieldName,
  },
});


export const downloadAttachmentFile = attachment => ({
  type: types.ATTACHMENT_DOWNLOAD,
  payload: {
    file: attachment.file,
  },
});
