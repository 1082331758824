import { connect } from 'react-redux';

import Header from '../components/Header';
import { getHeaderWarning, hideHeaderWarning } from '../actions/headerWarning';
import { hideMobileMenu, getInfoMenu } from '../actions/menu';
import { logout, registerUser, requestPasswordReset } from '../actions/auth';
import { hideModal } from '../actions/modal';
import { localeSelected } from '../actions/locale';
import { raiseFormErrors } from '../utils/forms';
import templateToastr from '../utils/toastr';

const logoutUser = () => (dispatch) => {
  dispatch(logout());
  templateToastr('success', 'loggedOut');
};

const requestPasswordResetSubmit = formData => dispatch =>
  dispatch(requestPasswordReset(formData))
    .then(
      () => templateToastr('success', 'forgotPasswordLinkSent'),
      error => raiseFormErrors(error.response),
    );


const registerNewUserSubmit = formData => dispatch => new Promise((resolve, reject) =>
  dispatch(registerUser(formData, resolve, reject)));

const hidePasswordResetModalWindow = () => (dispatch) => {
  dispatch(hideModal('passwordreset'));
};

const hideRegisterNewUserModalWindow = () => (dispatch) => {
  dispatch(hideModal('registeruser'));
};

const mapDispatchToProps = {
  closeWarningHeader: hideHeaderWarning,
  logout: logoutUser,
  hideMobileMenu,
  getInfoMenu,
  getHeaderWarning,
  localeSelected,
  resetPassword: requestPasswordResetSubmit,
  register: registerNewUserSubmit,
  hidePasswordResetModal: hidePasswordResetModalWindow,
  hideRegisterNewUserModal: hideRegisterNewUserModalWindow,
};

const mapStateToProps = state => ({
  currentUser: state.users[state.auth.currentUser],
  menu: state.menu,
  locale: state.locale.currentLanguage,
  auth: state.auth,
  showPasswordResetModal: state.modals.passwordreset,
  showRegisterNewUserModal: state.modals.registeruser,
  warningHeader: state.warningHeader,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
