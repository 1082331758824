import axios from '../api/axios';

export const getHeaderWarning = () => (dispatch) => {
  axios.get('/cms/warning-header/').then(
    (response) => {
      const prevWarningText = sessionStorage.getItem('HAS_CLOSED_HEADER_WARNING') || false;
      const newWarningText = response.data[0].text;
      if (prevWarningText !== newWarningText) {
        dispatch({
          type: 'SET_HEADER_TEXT',
          payload: newWarningText,
        });
      }
    },
    (error) => {
      // Do nothing
    },
  );
};

export const hideHeaderWarning = text => (dispatch) => {
  dispatch({
    type: 'HIDE_HEADER_WARNING',
  });
  sessionStorage.setItem('HAS_CLOSED_HEADER_WARNING', text);
};

export default getHeaderWarning;
