import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';

import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { queryBoardMeetings } from './actions/boardMeetingsTable';
import { queryBoards } from '../Boards/actions/boardTable';
import { getBoardMeeting } from '../../../../actions/boardMeetings';
import { fetchAllPages } from '../../../../actions/utils';
import {
  queryApplicationStructureOptions,
  queryApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingStructureOptions,
} from '../../../../actions/structures';
import { fetchUser } from '../../../../actions/user';

export const BoardMeetingsRoute = store => ({
  path: 'board-meetings',
  authorize: {
    groups: [
      USER_GROUPS.BOARD_MEMBER,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.SECRETARY_ASSISTANT,
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('./components/BoardMeetingListView')
      .then(async (module) => {
        const boardMeetingsTableReducer = await import('./reducers/boardMeetingsTable');
        store.injectReducer({ key: 'boardMeetingsTable', reducer: boardMeetingsTableReducer.default });

        store.dispatch(queryBoardMeetings({ stripped: 1 }));
        cb(null, module.default);
      });
  },
});


export const BoardMeetingDetailRoute = store => ({
  path: 'board-meetings/:boardMeetingId',
  authorize: {
    groups: [
      USER_GROUPS.BOARD_MEMBER,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.SECRETARY_ASSISTANT,
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('../../../../containers/CodeOfEthicsAgreement')
      .then(async (module) => {
        const userResponse = await store.dispatch(fetchUser('me'));
        if (isEmpty(userResponse.data.approved_fise_ethics_agreement)) {
          cb(null, module.default);
          return;
        }

        import('./containers/BoardMeetingDetail')
          .then(async (moduleBoardMeeting) => {
            const state = store.getState();
            const boardMeetingId = nextState.params.boardMeetingId;
            const applicationTableReducer = await import('../Applications/reducers/applicationTable');
            store.injectReducer({ key: 'applicationTable', reducer: applicationTableReducer.default });

            store.dispatch(queryBoards());
            if (boardMeetingId !== 'new') {
              try {
                const boardResponse = await store.dispatch(getBoardMeeting(boardMeetingId));
                const appStructures = boardResponse.value.data.board_data.certifications.toString();
                store.dispatch(queryApplicationStructures({
                  id__in: appStructures,
                  no_revisions: true,
                  stripped: true,
                }),
                );
                if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
                  store.dispatch(hasStartedFetchingStructureOptions());
                  store.dispatch(fetchAllPages(
                    queryApplicationStructureOptions,
                    {},
                    hasFetchedAllStructureOption,
                  ));
                }
              } catch (error) {
                store.dispatch(push('/dashboard/board-meetings'));
              }
            }
            cb(null, moduleBoardMeeting.default);
          });
      });
  },

});

export default BoardMeetingsRoute;
