const initialState = {};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'BILLING_INFORMATION_PERSONAL_ADD_FULFILLED':
    return {
      ...state,
      personal: [...state.personal, action.payload.data],
    };

  case 'BILLING_INFORMATION_PERSONAL_UPDATE_FULFILLED':
    return {
      ...state,
      personal: state.personal.map(
        item => (
          item.id !== action.payload.data.id ? item : action.payload.data
        ),
      ),
    };
  case 'BILLING_INFORMATION_PERSONAL_REMOVE_FULFILLED':
    return {
      ...state,
      personal: state.personal.filter(
        item => item.id !== action.payload.billingId,
      ),
    };

  case 'BILLING_INFORMATION_COMPANY_ADD_FULFILLED':
    return {
      ...state,
      company: [...state.company, action.payload.data],
    };

  case 'BILLING_INFORMATION_COMPANY_UPDATE_FULFILLED':
    return {
      ...state,
      company: state.company.map(
        item => (
          item.id !== action.payload.data.id ? item : action.payload.data
        ),
      ),
    };

  case 'BILLING_INFORMATION_COMPANY_REMOVE_FULFILLED':
    return {
      ...state,
      company: state.company.filter(
        item => item.id !== action.payload.billingId,
      ),
    };

  case 'BILLING_INFORMATION_ALL_FULFILLED':
    return action.payload.data;
  default:
    return state;
  }
};

export default modalReducer;
