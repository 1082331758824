import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { getDegrees } from '../../../../actions/degrees';
import { getOrganizations } from '../../../../actions/organization';
import { getUserAttributeTypes } from '../../../../actions/userAttributeTypes';
import {
  queryApplicationStructureOptions,
  hasFetchedAllStructureOption,
  hasStartedFetchingStructureOptions,
} from '../../../../actions/structures';
import { fetchAllPages } from '../../../../actions/utils';

const AdminSettingsRoute = store => ({
  path: 'admin-settings',
  authorize: {
    groups: [USER_GROUPS.ADMINISTRATOR],
  },
  getComponent(nextState, cb) {
    import('./containers/AdminSettingsRoute').then(async (module) => {
      const state = store.getState();
      store.dispatch(getDegrees());
      store.dispatch(getOrganizations());
      store.dispatch(getUserAttributeTypes());
      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
      cb(null, module.default);
    });
  },
});


export default AdminSettingsRoute;
