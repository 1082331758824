import { omit } from 'lodash';
import * as types from '../constants/organizations/actionTypes';

const initialState = {};

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SORT_ORGANIZATION_FULFILLED:
  case types.QUERY_ORGANIZATIONS_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };

  case types.CREATE_ORGANIZATION_FULFILLED:
  case types.UPDATE_ORGANIZATION_FULFILLED: {
    const obj = action.payload.data;
    return {
      ...state,
      [obj.id]: obj,
    };
  }

  case types.REMOVE_ORGANIZATION_FULFILLED: {
    const id = action.payload.id;
    return {
      ...omit(state, id),
    };
  }

  default:
    return state;
  }
};

export default organizationsReducer;
