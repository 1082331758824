import React from 'react';

import ReduxFormFieldShape from '../../structures/reduxFormField';

const Input = ({
  input,
  type,
  id,
  disabled,
  required,
  placeholder,
  className,
  meta: { touched, error },
  onKeyDown,
}) => (
  <span>
    <input
      {...input}
      type={type}
      id={id}
      placeholder={placeholder}
      required={required}
      className={className}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
    {touched && error && <span className="text-danger">{error}</span>}
  </span>
);

Input.propTypes = {
  ...ReduxFormFieldShape.isRequired,
};

export default Input;
