import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';

const EmailAlreadyVerifiedRoute = store => ({
  path: 'already_verified',
  getComponent(nextState, cb) {
    import('./EmailAlreadyVerifiedView').then(async (module) => {
      store.dispatch(setActivePage(menuPages.EMAIL_ALREADY_VERIFIED_PAGE));
      cb(null, module.default);
    });
  },
});

export default EmailAlreadyVerifiedRoute;
