import { connect } from 'react-redux';

import LoggedOutModal from '../../components/Modals/LoggedOutModal';
import { hideModal } from '../../actions/modal';

const loggedOutmodalName = 'logged_out';

const hide = modalName => (dispatch) => {
  dispatch(hideModal(modalName));
};

const mapDispatchToProps = {
  hide,
};

const mapStateToProps = (state, ownProps) => ({
  showModal: state.modals[loggedOutmodalName],
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutModal);
