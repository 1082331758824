import { isEmpty } from 'lodash';

import axios from '../../../../../api/axios';
import * as types from '../constants/applicationsTable/actionTypes';
import * as applicationTypes from '../../../../../constants/applications/actionTypes';
import { downloadBlob } from '../../../../../utils/download';
import { queryApplicationStructures } from '../../../../../actions/structures';


export const queryApplications = filtersParams => (dispatch) => {
  const filters = isEmpty(filtersParams) ? {} : filtersParams;
  let paginatedQueryForMeeting = false;
  let structureNeedsFetching = true;
  filters.simple = 1;

  if (!('page' in filters)) {
    filters.page = 1;
  }

  if ('prefetchSelectedForMeeting' in filters) {
    paginatedQueryForMeeting = true;
    delete filters.prefetchSelectedForMeeting;
  }

  if ('structureAlreadyFetched' in filters) {
    structureNeedsFetching = false;
    delete filters.structureAlreadyFetched;
  }

  const payload = {
    filters,
  };

  dispatch({
    type: types.QUERY_APPLICATIONTABLE_PENDING,
    payload,
  });

  return axios.get('/applications', { params: filters })
    // eslint-disable-next-line consistent-return
    .then((response) => {
      dispatch({
        type: applicationTypes.QUERY_APPLICATIONS_FULFILLED,
        payload: response,
      });

      payload.response = {
        ...response,
        data: response.data.map(item => item.id),
      };

      if (structureNeedsFetching) {
        const applicationStructuresSet = [...new Set(response.data.map(item => item.application_structure))];
        dispatch(queryApplicationStructures({ id__in: applicationStructuresSet.toString(), stripped: true }));
      }

      dispatch({
        type: types.QUERY_APPLICATIONTABLE_FULFILLED,
        payload,
      });

      /*
      This would allow to fetch all the pages for the queryApplications. It doesn't serve
      any other purpose than allowing this action creator to work with fetchAllPages.
       */
      if (paginatedQueryForMeeting) {
        return dispatch({
          type: '',
          value: { pagination: { ...response.pagination } },
        });
      }
    })
    .catch((err) => {
      payload.response = err;
      dispatch({
        type: types.QUERY_APPLICATIONTABLE_REJECTED,
        payload,
      });
    });
};

export const clearApplicationFilters = () => (dispatch) => {
  dispatch(queryApplications({
    state: '-draft,approved_for_board,change_request,change_request_can_cancel,pending_resent,pending',
  }));
  dispatch({
    type: types.QUERY_APPLICATIONTABLE_CLEAR_FILTERS,
  });
};

export const clearApplicationStructureFilters = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_APPLICATIONTABLE_APPLICATION_FILTERS,
  });
};

export const downloadApplicationReport = params => () => {
  const queryParams = isEmpty(params) ? {} : params;
  delete queryParams.page;

  axios({
    method: 'get',
    url: '/applications/report',
    params: queryParams,
    responseType: 'blob',
  }).then((response) => {
    // Get the filename from the content-disposition header
    const contentDisposition = response.request.getResponseHeader('Content-Disposition');
    const filename = contentDisposition.match(/filename="(.+)"/)[1];
    downloadBlob(response.data, filename);
  });
};

export const changeSorting = (sortName, sortOrder) => (dispatch) => {
  dispatch({
    type: types.CHANGE_SORTING,
    payload: {
      sortName,
      sortOrder,
    },
  });
};

export const filtersDependenciesLoading = () => (dispatch) => {
  dispatch({
    type: types.APPLICATION_FILTER_DEPENDENCIES_PENDING,
  });
};

export const filtersDependenciesLoaded = () => (dispatch) => {
  dispatch({
    type: types.APPLICATION_FILTER_DEPENDENCIES_FULFILLED,
  });
};

export const confirmDeleteApplication = id => dispatch => dispatch({
  type: types.DELETE_APPLICATION_CONFIRM,
  payload: axios.delete(`/applications/${id}`),
});

export const changeApplicationTableLoading = () => dispatch => dispatch({
  type: types.BOARD_MEETING_EMPTY_APP_QUERY_FULFILLED,
});

export default queryApplications;
