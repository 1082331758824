const initialValue = {
  mobileMenuOpened: false,
  activeMenu: 'home',
  infoMenu: null,
};

const menuReducer = (state = initialValue, action) => {
  switch (action.type) {
  case 'TOGGLE_MOBILE_MENU':
    return {
      ...state,
      mobileMenuOpened: !state.mobileMenuOpened,
    };
  case 'HIDE_MOBILE_MENU':
    return {
      ...state,
      mobileMenuOpened: false,
    };
  case 'SET_ACTIVE_PAGE':
    return {
      ...state,
      activeMenu: action.payload,
    };
  case 'SET_INFO_MENU':
    return {
      ...state,
      infoMenu: action.payload,
    };
  default:
    return state;
  }
};

export default menuReducer;
