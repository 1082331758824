import axios from '../api/axios';
import * as types from '../constants/boardMeetings/actionTypes';
import { formFilesToBase64 } from '../utils/forms';
import { STARTED } from '../constants/boards/boardMeeting';


export const getBoardMeeting = id => dispatch => dispatch({
  type: types.GET_BOARD_MEETING,
  payload: axios.get(`/board_meetings/${id}`),
});

export const getBoardMeetings = params => dispatch => (
  dispatch({
    type: types.QUERY_BOARD_MEETINGS,
    payload: axios.get('/board_meetings', { params }),
  })
);

export const createBoardMeeting = data => dispatch => (
  formFilesToBase64(data, ['name']).then(uploadableData => (
    dispatch({
      type: types.CREATE_BOARD_MEETING,
      payload: axios.post('/board_meetings', uploadableData),
    })
  ))
);

export const updateBoardMeeting = (id, data) => dispatch => dispatch({
  type: types.UPDATE_BOARD_MEETING,
  payload: axios.patch(`/board_meetings/${id}`, data),
});

export const removeBoardMeeting = id => (dispatch) => {
  const payload = {
    id,
  };

  dispatch({
    type: types.REMOVE_BOARD_MEETING_PENDING,
    payload,
  });

  return axios.delete(`/board_meetings/${id}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_BOARD_MEETING_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_BOARD_MEETING_REJECTED,
          payload: error,
        });
      },
    );
};

export const updateBoardMeetingState = (id, data) => (dispatch) => {
  const meetingId = id;
  return axios.post(`/board_meetings/${id}/states`, data)
    .then(response => dispatch({
      type: types.UPDATE_BOARD_MEETING_STATE_FULFILLED,
      payload: { ...response, meetingId },
    }));
};

export const startMeetingWithAttendees = (id, data) => dispatch => (
  dispatch(updateBoardMeetingState(id, { state: STARTED })).then(
    () => dispatch(updateBoardMeeting(id, data)),
  )
);

export const reorderApplication = (data, id) => dispatch => (
  dispatch({
    type: types.REORDER_BOARD_MEETING_APPLICATIONS,
    payload: axios.put(`/board_meetings/${id}/sort`, data),
  })
);

export const getMeetingPublishedEmailPreview = (meetingId, data) => dispatch => (
  axios.post(`/board_meetings/${meetingId}/emails`, data).then(
    response => dispatch({
      type: types.GET_MEETING_PUBLISHED_EMAIL_PREVIEW_FULFILLED,
      payload: response,
    }),
  )
);
