import createReducers from './index';

function injectReducer({ key, reducer }) {
  if (Object.hasOwnProperty.call(this, key)) {
    return;
  }

  this.asyncReducers = {
    ...this.asyncReducers,
    [key]: reducer,
  };

  this.replaceReducer(createReducers(this.asyncReducers));
}

export default injectReducer;
