import { get, isEmpty } from 'lodash';
import { destroy } from 'redux-form';

import { fetchUserApplications } from './actions/application';
import { setUserProfileTab } from '../../../../actions/userProfile';
import { getApplication } from '../../../../actions/applications';
import { getDegrees } from '../../../../actions/degrees';
import {
  getApplicationStructure,
  queryApplicationStructures,
  hasFetchedAllApplicationStructures,
  hasStartedFetchingApplicationStructures,
} from '../../../../actions/structures';
import { fetchAllPages } from '../../../../actions/utils';
import * as types from '../../../ApplicationWizard/constants/actionTypes';
import APPLICATION_WIZARD_FORM from '../../../ApplicationWizard/constants/forms';

export const UserApplicationsRoute = store => ({
  path: 'applications',
  getComponent(nextState, cb) {
    store.dispatch(destroy(APPLICATION_WIZARD_FORM));
    store.dispatch({ type: types.RESET_WIZARD });
    import('./containers/UserApplicationsRoute')
      .then(async (module) => {
        const userApplicationsReducer = await import('./reducers/userApplications');
        store.injectReducer({ key: 'userApplications', reducer: userApplicationsReducer.default });
        const state = store.getState();

        if (!state.structures.fetchedAll && !state.structures.startedFetching) {
          store.dispatch(hasStartedFetchingApplicationStructures());
          store.dispatch(fetchAllPages(
            queryApplicationStructures,
            { stripped: true },
            hasFetchedAllApplicationStructures,
          ));
        }
        store.dispatch(setUserProfileTab('applications'));
        store.dispatch(fetchUserApplications(nextState.params.id));
        cb(null, module.default);
      });
  },
});

export const UserApplicationsDetailRoute = store => ({
  path: 'applications/:applicationId',
  getComponent(nextState, cb) {
    import('./containers/UserApplicationDetailRoute')
      .then(async (module) => {
        // Get the application
        const state = store.getState();
        const applicationNotFetched = isEmpty(state.applications[nextState.params.applicationId]);
        if (!applicationNotFetched) {
          const applicationStructureId = state.applications[nextState.params.applicationId].application_structure;
          store.dispatch(getApplicationStructure(applicationStructureId));
        }
        if (applicationNotFetched) {
          store.dispatch(getApplication(nextState.params.applicationId)).then((applicationResponse) => {
            const applicationStructureId = get(applicationResponse, 'payload.data.application_structure');
            store.dispatch(getApplicationStructure(applicationStructureId));
          });
        }

        // Get degrees
        if (isEmpty(state.degrees)) {
          store.dispatch(getDegrees());
        }

        store.dispatch(setUserProfileTab('applications'));
        cb(null, module.default);
      });
  },
});
