import { setUserProfileTab } from '../../../../actions/userProfile';
import { queryUserNotes } from '../../../../actions/userNotes';
import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { getUserAttributeTypes } from '../../../../actions/userAttributeTypes';
import { getUserGroups } from '../../../../actions/userGroups';


export const AdminRoute = store => ({
  path: 'admin',
  authorize: {
    groups: [USER_GROUPS.SECRETARY, USER_GROUPS.ADMINISTRATOR, USER_GROUPS.SECRETARY_ASSISTANT],
  },
  getComponent(nextState, cb) {
    import('./containers/UserAdminRoute')
      .then(async (module) => {
        store.dispatch(setUserProfileTab('admin'));
        store.dispatch(queryUserNotes(nextState.params.id));
        store.dispatch(getUserGroups());
        store.dispatch(getUserAttributeTypes());

        cb(null, module.default);
      });
  },
});

export default AdminRoute;
