import PropTypes from 'prop-types';

import React, { Component } from 'react';
import styles from './Hero.less';
import { getSectionData, getHeroPageData } from '../../utils/cms';
import DangerousDiv from '../../utils/DangerousDiv';


class Hero extends Component {
  constructor(props) {
    super(props);
    this.heroSectionContent = props.heroSectionContent;
    this.pageId = props.pageId;
    this.sectionId = props.sectionId;
    this.state = {
      sectionData: props.heroSectionContent,
    };

    if (this.heroSectionContent === null && this.pageId) {
      this.asyncGetPageData(this.pageId, this.sectionId);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.heroSectionContent !== this.state.sectionData;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.heroSectionContent === null) {
      return { ...state };
    }
    return { ...state, sectionData: props.heroSectionContent };
  }

  async asyncGetPageData(pageId, sectionId) {
    const jsonHomePageData = await getHeroPageData(pageId);
    if (jsonHomePageData != null) {
      this.setState({ sectionData: getSectionData(jsonHomePageData.sections, sectionId) });
    }
  }

  render() {
    return (
      <div className={styles.hero}>
        <div
          className={styles.heroContent}
          style={(this.state.sectionData && this.state.sectionData.image) ?
            { backgroundImage: `url(${this.state.sectionData.image})` } : {}}
        >
          {this.state.sectionData === null ? '' : (
            <div className={`container ${styles.heroContainer}`}>
              <h1>
                { this.state.sectionData.title }
              </h1>
              <div className="lead">
                <DangerousDiv innerHTML={this.state.sectionData.text} key="" className="" />
                {this.state.sectionData &&
                this.state.sectionData.buttons && this.state.sectionData.buttons.map(button => (
                  <a
                    href={button.value.link}
                    key={button.id}
                    target={button.value.external_link ? '_blank' : ''}
                  >
                    {button.value.text}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}


Hero.propTypes = {
  pageId: PropTypes.string,
  sectionId: PropTypes.string,
  heroSectionContent: PropTypes.shape({}),
};

Hero.defaultProps = {
  heroSectionContent: null,
  pageId: null,
  sectionId: 'banner',
};


export default Hero;
