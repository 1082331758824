export const QUERY_NOTES = 'QUERY_NOTES';
export const QUERY_NOTES_PENDING = 'QUERY_NOTES_PENDING';
export const QUERY_NOTES_FULFILLED = 'QUERY_NOTES_FULFILLED';
export const QUERY_NOTES_REJECTED = 'QUERY_NOTES_REJECTED';

export const CREATE_NOTE = 'CREATE_NOTE';
export const CREATE_NOTE_PENDING = 'CREATE_NOTE_PENDING';
export const CREATE_NOTE_FULFILLED = 'CREATE_NOTE_FULFILLED';
export const CREATE_NOTE_REJECTED = 'CREATE_NOTE_REJECTED';

export const DESTROY_NOTE = 'DESTROY_NOTE';
export const DESTROY_NOTE_PENDING = 'DESTROY_NOTE_PENDING';
export const DESTROY_NOTE_FULFILLED = 'DESTROY_NOTE_FULFILLED';
export const DESTROY_NOTE_REJECTED = 'DESTROY_NOTE_REJECTED';
