import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const LoggedOutModal = props => (
  <Modal show={props.showModal} onHide={() => props.hide('logged_out')} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage
          id="loggedoutModal.title"
          defaultMessage="You've been logged out"
        />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormattedMessage
        id="loggedoutModal.content"
        defaultMessage="You have been logged out due to inactivity."
      />
    </Modal.Body>
  </Modal>
);

LoggedOutModal.propTypes = {
  hide: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
};

LoggedOutModal.defaultProps = {
  showModal: false,
};

export default LoggedOutModal;
