import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './style.less';
import YellowItem from './YellowItem';
import DangerousDiv from '../../../../utils/DangerousDiv';


class RowSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
    };
  }

  render() {
    const columns = 12 / this.state.section.content.length;
    return (
      <div className={`${styles.rowContent} row`}>
        {this.state.section.content.map(section => (
          <div key={section.id}>
            {section.type === 'yellow_item' ? <YellowItem section={section} columns={3} /> : null}
            {section.type !== 'yellow_item' ?
              <div className={`${styles.column} col-sm-${columns}`}>
                {(section.value.svg !== '' || section.value.title) &&
                  <div className={styles.iconImage}>
                    {section.value.svg !== '' ? (
                      <div className={styles.iconHolder}>
                        <DangerousDiv innerHTML={section.value.svg} key={section.id} className="" />
                      </div>
                    ) : null
                    }
                    {section.value.title && <h4>{section.value.title}</h4>}
                  </div>
                }
                <DangerousDiv innerHTML={section.value.text} key={section.id} className="" />
              </div> : null
            }
          </div>
        ))}
      </div>
    );
  }
}


RowSection.propTypes = {
  section: PropTypes.shape({}),
};

RowSection.defaultProps = {
  section: {},
};

export default RowSection;
