export const QUERY_USER_ATTRIBUTE_TYPES = 'QUERY_USER_ATTRIBUTE_TYPES';
export const QUERY_USER_ATTRIBUTE_TYPES_PENDING = 'QUERY_USER_ATTRIBUTE_TYPES_PENDING';
export const QUERY_USER_ATTRIBUTE_TYPES_FULFILLED = 'QUERY_USER_ATTRIBUTE_TYPES_FULFILLED';
export const QUERY_USER_ATTRIBUTE_TYPES_REJECTED = 'QUERY_USER_ATTRIBUTE_TYPES_REJECTED';

export const GET_USER_ATTRIBUTE_TYPE = 'GET_USER_ATTRIBUTE_TYPE';
export const GET_USER_ATTRIBUTE_TYPE_PENDING = 'GET_USER_ATTRIBUTE_TYPE_PENDING';
export const GET_USER_ATTRIBUTE_TYPE_FULFILLED = 'GET_USER_ATTRIBUTE_TYPE_FULFILLED';
export const GET_USER_ATTRIBUTE_TYPE_REJECTED = 'GET_USER_ATTRIBUTE_TYPE_REJECTED';

export const CREATE_USER_ATTRIBUTE_TYPE = 'CREATE_USER_ATTRIBUTE_TYPE';
export const CREATE_USER_ATTRIBUTE_TYPE_PENDING = 'CREATE_USER_ATTRIBUTE_TYPE_PENDING';
export const CREATE_USER_ATTRIBUTE_TYPE_FULFILLED = 'CREATE_USER_ATTRIBUTE_TYPE_FULFILLED';
export const CREATE_USER_ATTRIBUTE_TYPE_REJECTED = 'CREATE_USER_ATTRIBUTE_TYPE_REJECTED';

export const UPDATE_USER_ATTRIBUTE_TYPE = 'UPDATE_USER_ATTRIBUTE_TYPE';
export const UPDATE_USER_ATTRIBUTE_TYPE_PENDING = 'UPDATE_USER_ATTRIBUTE_TYPE_PENDING';
export const UPDATE_USER_ATTRIBUTE_TYPE_FULFILLED = 'UPDATE_USER_ATTRIBUTE_TYPE_FULFILLED';
export const UPDATE_USER_ATTRIBUTE_TYPE_REJECTED = 'UPDATE_USER_ATTRIBUTE_TYPE_REJECTED';

export const REMOVE_USER_ATTRIBUTE_TYPE = 'REMOVE_USER_ATTRIBUTE_TYPE';
export const REMOVE_USER_ATTRIBUTE_TYPE_PENDING = 'REMOVE_USER_ATTRIBUTE_TYPE_PENDING';
export const REMOVE_USER_ATTRIBUTE_TYPE_FULFILLED = 'REMOVE_USER_ATTRIBUTE_TYPE_FULFILLED';
export const REMOVE_USER_ATTRIBUTE_TYPE_REJECTED = 'REMOVE_USER_ATTRIBUTE_TYPE_REJECTED';

