import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';

import axios from '../api/axios';
import { logout, requestLogin, setLoginUser } from './auth';
import { userInGroups } from '../utils/auth';
import * as types from '../constants/user/actionTypes';
import * as USER_GROUPS from '../constants/auth/userGroups';
import { downloadBlob } from '../utils/download';

export const queryUsers = parameters => (dispatch) => {
  const params = isEmpty(parameters) ? {} : parameters;

  return dispatch({
    type: types.QUERY_USERS,
    payload: axios.get('/users', { params }),
  });
};


export const fetchUser = (id, redirect = false) => (dispatch) => {
  let currentUser = false;
  if (id === 'me') {
    currentUser = true;
  }

  dispatch({
    type: 'USER_PENDING',
    payload: {
      id,
    },
  });

  return axios.get(`/users/${id}`)
    .then(
      // eslint-disable-next-line consistent-return
      (response) => {
        dispatch({
          type: 'USER_FULFILLED',
          payload: response,
        });
        if (currentUser) {
          if (!(response.data.strongly_authenticated || response.data.token_authenticated)) {
            dispatch(logout());
            return false;
          }
          dispatch(setLoginUser(response.data));
          if (redirect) {
            if (userInGroups(response.data, USER_GROUPS.SECRETARY, USER_GROUPS.ADMINISTRATOR, USER_GROUPS.PERSONNEL,
              USER_GROUPS.SECRETARY_ASSISTANT)) {
              dispatch(push('/dashboard'));
            } else {
              dispatch(push(`/users/${response.data.id}/cv`));
            }
          }
        }
        return response;
      },
      (error) => {
        dispatch({
          type: 'USER_REJECTED',
          payload: error,
        });
        dispatch(logout());
      },
    );
};

export const updateUser = user => (dispatch) => {
  dispatch({
    type: 'USER_PENDING',
    payload: {
      id: user.id,
    },
  });

  return axios.patch(`/users/${user.id}`, user)
    .then(
      (response) => {
        dispatch({
          type: 'USER_FULFILLED',
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        dispatch({
          type: 'USER_REJECTED',
          payload: error,
        });
        return Promise.reject(error);
      },
    );
};

export const signUpUser = data => dispatch => axios.post('/users', data)
  .then(
    (response) => {
      dispatch({
        type: 'USER_FULFILLED',
        payload: response,
      });
      dispatch(requestLogin(data.email, data.password));
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: 'USER_REJECTED',
        payload: error,
      });
      return Promise.reject(error);
    },
  );

export const createUser = data => dispatch => dispatch({
  type: 'USER',
  payload: axios.post('/users', data),
});


export const createWorkHistory = (userId, data) => dispatch => dispatch({
  type: 'USER_WORKHISTORY',
  payload: axios.post(`/users/${userId}/workhistory`, data)
    .finally(
      () => {
        dispatch(fetchUser(userId));
      },
    ),
});

export const updateWorkHistory = (userId, workHistoryId, data) => dispatch => dispatch({
  type: 'USER_WORKHISTORY_UPDATE',
  payload: axios.put(`/users/${userId}/workhistory/${workHistoryId}`, data)
    .finally(
      () => {
        dispatch(fetchUser(userId));
      },
    ),
});

export const removeWorkHistory = (userId, workHistoryId) => {
  const payload = {
    userId,
    workHistoryId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_WORKHISTORY_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/workhistory/${workHistoryId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_WORKHISTORY_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_WORKHISTORY_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createEducation = (userId, data) => dispatch => dispatch({
  type: 'USER_EDUCATIONS',
  payload: axios.post(`/users/${userId}/educations`, data),
});

export const updateEducation = (userId, educationId, data) => dispatch => dispatch({
  type: 'USER_EDUCATIONS_UPDATE',
  payload: axios.put(`/users/${userId}/educations/${educationId}`, data),
});

export const removeEducation = (userId, educationId) => {
  const payload = {
    userId,
    educationId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_EDUCATIONS_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/educations/${educationId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_EDUCATIONS_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_EDUCATIONS_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

// TODO: SIMPLIFY THIS FLOW
export const createCourse = (userId, data) => dispatch => dispatch({
  type: 'USER_COURSES',
  payload: axios.post(`/users/${userId}/courses`, data),
});

export const updateCourse = (userId, courseId, data) => dispatch => dispatch({
  type: 'USER_COURSES_UPDATE',
  payload: axios.put(`/users/${userId}/courses/${courseId}`, data),
});

export const removeCourse = (userId, courseId) => {
  const payload = {
    userId,
    courseId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_COURSES_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/courses/${courseId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_COURSES_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_COURSES_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createTraining = (userId, data) => dispatch => dispatch({
  type: 'USER_TRAININGS',
  payload: axios.post(`/users/${userId}/trainings`, data),
});

export const updateTraining = (userId, trainingId, data) => dispatch => dispatch({
  type: 'USER_TRAININGS_UPDATE',
  payload: axios.put(`/users/${userId}/trainings/${trainingId}`, data),
});

export const removeTraining = (userId, trainingId) => {
  const payload = {
    userId,
    trainingId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_TRAININGS_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/trainings/${trainingId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_TRAININGS_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_TRAININGS_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createCPDCourse = (userId, data) => dispatch => dispatch({
  type: 'USER_CPDCOURSES',
  payload: axios.post(`/users/${userId}/cpdcourses`, data),
});

export const updateCPDCourse = (userId, courseId, data) => dispatch => dispatch({
  type: 'USER_CPDCOURSES_UPDATE',
  payload: axios.put(`/users/${userId}/cpdcourses/${courseId}`, data),
});

export const removeCPDCourse = (userId, courseId) => {
  const payload = {
    userId,
    courseId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_CPDCOURSES_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/cpdcourses/${courseId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_CPDCOURSES_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_CPDCOURSES_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createExam = (userId, data) => dispatch => dispatch({
  type: 'USER_EXAMS',
  payload: axios.post(`/users/${userId}/exams`, data),
});

export const updateExam = (userId, examId, data) => dispatch => dispatch({
  type: 'USER_EXAMS_UPDATE',
  payload: axios.put(`/users/${userId}/exams/${examId}`, data),
});

export const removeExam = (userId, examId) => {
  const payload = {
    userId,
    examId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_EXAMS_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/exams/${examId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_EXAMS_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_EXAMS_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createReference = (userId, data) => dispatch => dispatch({
  type: types.USER_REFERENCE_CREATE,
  payload: axios.post(`/users/${userId}/references`, data),
});

export const updateReference = (userId, referenceId, data) => dispatch => dispatch({
  type: types.USER_REFERENCE_UPDATE,
  payload: axios.put(`/users/${userId}/references/${referenceId}`, data),
});

export const removeReference = (userId, referenceId) => {
  const payload = {
    userId,
    referenceId,
  };

  return (dispatch) => {
    dispatch({
      type: types.USER_REFERENCE_DESTROY_PENDING,
      payload,
    });

    return axios.delete(`/users/${userId}/references/${referenceId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: types.USER_REFERENCE_DESTROY_FULFILLED,
            payload,
          });
        },
        (error) => {
          dispatch({
            type: types.USER_REFERENCE_DESTROY_REJECTED,
            payload: error,
          });
        },
      );
  };
};

export const getAllBillingInformation = userId => (dispatch) => {
  dispatch({
    type: 'BILLING_INFORMATION_ALL',
    payload: axios.get(`/users/${userId}/billing`),
  });
};

export const createCompanyBillingInformation = (userId, data) => dispatch => dispatch({
  type: 'BILLING_INFORMATION_COMPANY_ADD',
  payload: axios.post(`/users/${userId}/billing/company`, data),
});

export const updateCompanyBillingInformation = (userId, billingId, data) => dispatch => dispatch({
  type: 'BILLING_INFORMATION_COMPANY_UPDATE',
  payload: axios.put(`/users/${userId}/billing/company/${billingId}`, data),
});

export const removeCompanyBillingInformation = (userId, billingId) => {
  const payload = {
    userId,
    billingId,
  };

  return (dispatch) => {
    dispatch({
      type: 'BILLING_INFORMATION_COMPANY_PENDING',
      payload,
    });
    return axios.delete(`/users/${userId}/billing/company/${billingId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'BILLING_INFORMATION_COMPANY_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'BILLING_INFORMATION_COMPANY_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createPersonalBillingInformation = (userId, data) => dispatch => dispatch({
  type: 'BILLING_INFORMATION_PERSONAL_ADD',
  payload: axios.post(`/users/${userId}/billing/personal`, data),
});

export const updatePersonalBillingInformation = (userId, billingId, data) => dispatch => dispatch({
  type: 'BILLING_INFORMATION_PERSONAL_UPDATE',
  payload: axios.put(`/users/${userId}/billing/personal/${billingId}`, data),
});

export const removePersonalBillingInformation = (userId, billingId) => {
  const payload = {
    userId,
    billingId,
  };

  return (dispatch) => {
    dispatch({
      type: 'BILLING_INFORMATION_PERSONAL_PENDING',
      payload,
    });
    return axios.delete(`/users/${userId}/billing/personal/${billingId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'BILLING_INFORMATION_PERSONAL_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'BILLING_INFORMATION_PERSONAL_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createStatement = (userId, data) => dispatch => dispatch({
  type: 'USER_STATEMENTS',
  payload: axios.post(`/users/${userId}/statements`, data),
});

export const updateStatement = (userId, statementId, data) => dispatch => dispatch({
  type: 'USER_STATEMENTS_UPDATE',
  payload: axios.put(`/users/${userId}/statements/${statementId}`, data),
});

export const removeStatement = (userId, statementId) => {
  const payload = {
    userId,
    statementId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_STATEMENTS_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/statements/${statementId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_STATEMENTS_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_STATEMENTS_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};
export const createStatementAuthor = (userId, data) => dispatch => dispatch({
  type: 'USER_STATEMENTSAUTHOR',
  payload: axios.post(`/users/${userId}/statementauthors`, data),
});

export const updateStatementAuthor = (userId, statementAuthorId, data) => dispatch => dispatch({
  type: 'USER_STATEMENTSAUTHOR_UPDATE',
  payload: axios.put(`/users/${userId}/statementauthors/${statementAuthorId}`, data),
});

export const removeStatementAuthor = (userId, statementAuthorId) => {
  const payload = {
    userId,
    statementAuthorId,
  };

  return (dispatch) => {
    dispatch({
      type: 'USER_STATEMENTSAUTHOR_REMOVE_PENDING',
      payload,
    });

    return axios.delete(`/users/${userId}/statementauthors/${statementAuthorId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: 'USER_STATEMENTSAUTHOR_REMOVE_FULFILLED',
            payload,
          });
        },
        (error) => {
          dispatch({
            type: 'USER_STATEMENTSAUTHOR_REMOVE_REJECTED',
            payload: error,
          });
        },
      );
  };
};

export const createAttribute = (userId, data) => dispatch => dispatch({
  type: types.USER_ATTRIBUTE_CREATE,
  payload: axios.post(`/users/${userId}/attributes`, data),
});

export const updateAttribute = (userId, attributesId, data) => dispatch => dispatch({
  type: types.USER_ATTRIBUTE_UPDATE,
  payload: axios.patch(`/users/${userId}/attributes/${attributesId}`, data),
});

export const removeAttribute = (userId, attributeId) => {
  const payload = {
    userId,
    attributeId,
  };

  return (dispatch) => {
    dispatch({
      type: types.USER_ATTRIBUTE_REMOVE_PENDING,
      payload,
    });

    return axios.delete(`/users/${userId}/attributes/${attributeId}`)
      .then(
        (response) => {
          payload.response = response;
          dispatch({
            type: types.USER_ATTRIBUTE_REMOVE_FULFILLED,
            payload,
          });
        },
        (error) => {
          dispatch({
            type: types.USER_ATTRIBUTE_REMOVE_REJECTED,
            payload: error,
          });
        },
      );
  };
};


export const downloadCV = userId => (dispatch) => {
  axios({
    method: 'get',
    url: `/users/${userId}/cvexport`,
    responseType: 'blob',
  }).then((response) => {
    // Get the filename from the content-disposition header
    const contentDisposition = response.request.getResponseHeader('Content-Disposition');
    const filename = contentDisposition.match(/filename="(.+)"/)[1];
    downloadBlob(response.data, filename);
    dispatch({
      type: 'CV_DOWNLOAD_FULFILLED',
      payload: filename,
    });
  });
};
