import { isEmpty } from 'lodash';

import * as types from '../constants/actionTypes';
import axios from '../../../../../api/axios';
import { QUERY_BOARD_MEETINGS_FULFILLED } from '../../../../../constants/boardMeetings/actionTypes';

export const queryBoardMeetings = filterParams => (dispatch) => {
  const filters = isEmpty(filterParams) ? {} : filterParams;

  if (!('page' in filters)) {
    filters.page = 1;
  }

  const queryParams = { filters };

  const payload = {
    queryParams,
  };

  dispatch({
    type: types.QUERY_BOARD_MEETINGS_TABLE_PENDING,
    payload,
  });

  return axios.get('/board_meetings', { params: { ...queryParams.filters } })
    .then((response) => {
      dispatch({
        type: QUERY_BOARD_MEETINGS_FULFILLED,
        payload: response,
      });

      payload.response = {
        ...response,
        data: response.data.map(item => item.id),
      };

      dispatch({
        type: types.QUERY_BOARD_MEETINGS_TABLE_FULFILLED,
        payload,
      });
    })
    .catch((error) => {
      payload.response = error;
      dispatch({
        type: types.QUERY_BOARD_MEETINGS_TABLE_REJECTED,
        payload,
      });
    });
};


export default queryBoardMeetings;
