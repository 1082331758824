import { filter, omit, remove } from 'lodash';
import * as types from '../constants/degrees/actionTypes';

const initialValue = {};

const degreesReducer = (state = initialValue, action) => {
  let otherDegree;

  switch (action.type) {
  case types.SORT_DEGREES_FULFILLED:
  case types.QUERY_DEGREES_FULFILLED:
    otherDegree = filter(
      action.payload.data,
      { short_name: 'MUU' },
    );

    if (otherDegree.length === 1) {
      otherDegree = otherDegree[0];
      remove(
        action.payload.data,
        { short_name: 'MUU' },
      );
      action.payload.data.push(otherDegree);
    }
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };
  case types.GET_DEGREE_FULFILLED:
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };
  case types.REMOVE_DEGREE_FULFILLED: {
    const id = action.payload.id;
    return {
      ...omit(state, id),
    };
  }
  case types.CREATE_DEGREE_FULFILLED:
  case types.UPDATE_DEGREE_FULFILLED: {
    const obj = action.payload.data;
    return {
      ...state,
      [obj.id]: obj,
    };
  }

  default:
    return state;
  }
};

export default degreesReducer;
