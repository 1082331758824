import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';

const CMSRoute = store => ({
  path: 'cms/*',
  getComponent(nextState, cb) {
    import('./CMSView').then(async (module) => {
      store.dispatch(setActivePage(menuPages.CMS_PAGE));
      cb(null, module.default);
    });
  },
});


export default CMSRoute;
