import PropTypes from 'prop-types';

const AuthShape = PropTypes.shape({
  completed: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.bool.string,
  userLevel: PropTypes.bool.string,
});


export default AuthShape;
