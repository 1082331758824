export const QUERY_BOARD_MEETINGS = 'FETCH_BOARD_MEETINGS';
export const QUERY_BOARD_MEETINGS_PENDING = 'FETCH_BOARD_MEETINGS_PENDING';
export const QUERY_BOARD_MEETINGS_FULFILLED = 'FETCH_BOARD_MEETINGS_FULFILLED';
export const QUERY_BOARD_MEETINGS_REJECTED = 'FETCH_BOARD_MEETINGS_REJECTED';

export const GET_BOARD_MEETING = 'GET_BOARD_MEETING';
export const GET_BOARD_MEETING_PENDING = 'GET_BOARD_MEETING_PENDING';
export const GET_BOARD_MEETING_FULFILLED = 'GET_BOARD_MEETING_FULFILLED';
export const GET_BOARD_MEETING_REJECTED = 'GET_BOARD_MEETING_REJECTED';

export const CREATE_BOARD_MEETING = 'CREATE_BOARD_MEETING';
export const CREATE_BOARD_MEETING_PENDING = 'CREATE_BOARD_MEETING_PENDING';
export const CREATE_BOARD_MEETING_FULFILLED = 'CREATE_BOARD_MEETING_FULFILLED';
export const CREATE_BOARD_MEETING_REJECTED = 'CREATE_BOARD_MEETING_REJECTED';

export const UPDATE_BOARD_MEETING = 'UPDATE_BOARD_MEETING';
export const UPDATE_BOARD_MEETING_PENDING = 'UPDATE_BOARD_MEETING_PENDING';
export const UPDATE_BOARD_MEETING_FULFILLED = 'UPDATE_BOARD_MEETING_FULFILLED';
export const UPDATE_BOARD_MEETING_REJECTED = 'UPDATE_BOARD_MEETING_REJECTED';

export const REMOVE_BOARD_MEETING = 'REMOVE_BOARD_MEETING';
export const REMOVE_BOARD_MEETING_PENDING = 'REMOVE_BOARD_MEETING_PENDING';
export const REMOVE_BOARD_MEETING_FULFILLED = 'REMOVE_BOARD_MEETING_FULFILLED';
export const REMOVE_BOARD_MEETING_REJECTED = 'REMOVE_BOARD_MEETING_REJECTED';

export const UPDATE_BOARD_MEETING_STATE = 'UPDATE_BOARD_MEETING_STATE';
export const UPDATE_BOARD_MEETING_STATE_FULFILLED = 'UPDATE_BOARD_MEETING_STATE_FULFILLED';

export const REORDER_BOARD_MEETING_APPLICATIONS = 'REORDER_BOARD_MEETING_APPLICATIONS';
export const REORDER_BOARD_MEETING_APPLICATIONS_FULFILLED = 'REORDER_BOARD_MEETING_APPLICATIONS_FULFILLED';

export const GET_MEETING_PUBLISHED_EMAIL_PREVIEW_FULFILLED = 'GET_MEETING_PUBLISHED_EMAIL_PREVIEW_FULFILLED';
