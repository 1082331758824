import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer } from 'react-router-redux';

import applicationsReducer from './applications';
import authReducer from './auth';
import billingReducer from './billing';
import boardMeetingsReducer from './boardMeetings';
import clipboardReducer from './clipboard';
import degreesReducer from './degrees';
import feedsReducer from './feeds';
import feedsDataReducer from './feedsData';
import localeReducer from './locale';
import menuReducer from './menu';
import modalReducer from './modal';
import operationalAreaReducer from './operationalareas';
import structuresReducer from './structures';
import structuresOptionsReducer from './structureoptions';
import userNotesReducer from './userNotes';
import userProfileReducer from './userProfile';
import userReducer from './user';
import userAttributeTypeReducer from './userAttributeTypes';
import userGroupsReducer from './userGroups';
import organizationsReducer from './organizations';
import boardTableReducer from './boardsTable';
import contactUsReducer from './contactUs';
import warningHeaderReducer from './warningHeader';

const createReducers = asyncReducers => combineReducers({
  applications: applicationsReducer,
  warningHeader: warningHeaderReducer,
  auth: authReducer,
  billing: billingReducer,
  boardMeetings: boardMeetingsReducer,
  clipboard: clipboardReducer,
  contactUs: contactUsReducer,
  degrees: degreesReducer,
  organizations: organizationsReducer,
  feeds: feedsReducer,
  feedsData: feedsDataReducer,
  form: formReducer,
  locale: localeReducer,
  menu: menuReducer,
  modals: modalReducer,
  routing: routerReducer,
  toastr: toastrReducer,
  structures: structuresReducer,
  structureOptions: structuresOptionsReducer,
  userNotes: userNotesReducer,
  userGroups: userGroupsReducer,
  userProfile: userProfileReducer,
  users: userReducer,
  userAttributeTypes: userAttributeTypeReducer,
  operationalAreas: operationalAreaReducer,
  boards: boardTableReducer,
  ...asyncReducers,
});

export default createReducers;
