const modalReducer = (state = {}, action) => {
  let modalName;

  switch (action.type) {
  case 'SHOW_MODAL':
    modalName = action.payload.name;
    if (!action.payload.replace) {
      return {
        ...state,
        [modalName]: action.payload.show,
      };
    }
    return {
      [modalName]: action.payload.show,
    };
  case 'HIDE_MODAL':
    modalName = action.payload.name;
    if (action.payload.all) {
      return {};
    }
    return {
      ...state,
      [modalName]: action.payload.show,
    };
  default:
    return state;
  }
};

export default modalReducer;
