import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import RegisterLink from '../../containers/RegisterLink';
import LoginForm from '../../components/Forms/LoginForm';
import ForgotPasswordLink from '../../containers/ForgotPasswordLink';

import styles from './LoginButton.less';

const LoginButton = (props) => {
  const buttonClasses = cx(
    styles.loginButton,
    {
      'btn btn-sm btn-primary btn-rounded btn-outline': !props.link,
      'link-btn': props.link,
    },
  );

  return (
    <span className={styles.loginButtonWrapper}>
      <button
        type="button"
        className={buttonClasses}
        onClick={props.show}
      >
        <FormattedMessage
          id="loginButton.buttonTitle"
          defaultMessage="Log in"
        />
      </button>
      <Modal show={props.showModal} onHide={props.hide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="loginButton.modalTitle"
              defaultMessage="Log in"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm onSubmit={props.login} />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <small>
              <RegisterLink
                text={<FormattedMessage
                  id="loginButton.register"
                  defaultMessage="Don't have an account? Register here!"
                />}
              />
              <br />
              <ForgotPasswordLink
                text={<FormattedMessage
                  id="forgotPasswordButton.linkText"
                  defaultMessage="Forgot your password? Request reset here!"
                />}
              />
            </small>
          </div>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

LoginButton.propTypes = {
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  login: PropTypes.func.isRequired,
  link: PropTypes.bool,
};

LoginButton.defaultProps = {
  showModal: false,
  link: false,
};


export default LoginButton;
