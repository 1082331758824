export const QUERY_APPLICATIONTABLE = 'QUERY_APPLICATIONTABLE';
export const QUERY_APPLICATIONTABLE_PENDING = 'QUERY_APPLICATIONTABLE_PENDING';
export const QUERY_APPLICATIONTABLE_FULFILLED = 'QUERY_APPLICATIONTABLE_FULFILLED';
export const QUERY_APPLICATIONTABLE_REJECTED = 'QUERY_APPLICATIONTABLE_REJECTED';

export const QUERY_APPLICATIONTABLE_CLEAR_FILTERS = 'CLEAR_APPLICATIONTABLE_FILTERS';
export const CLEAR_APPLICATIONTABLE_APPLICATION_FILTERS = 'CLEAR_APPLICATIONTABLE_APPLICATION_FILTERS';

export const CHANGE_SORTING = 'CHANGE_SORTING';

export const APPLICATION_FILTER_DEPENDENCIES_PENDING = 'APPLICATION_FILTER_DEPENDENCIES_PENDING';
export const APPLICATION_FILTER_DEPENDENCIES_FULFILLED = 'APPLICATION_FILTER_DEPENDENCIES_FULFILLED';

export const DELETE_APPLICATION_CONFIRM = 'DELETE_APPLICATION_CONFIRM';

export const BOARD_MEETING_EMPTY_APP_QUERY_FULFILLED = 'BOARD_MEETING_EMPTY_APP_QUERY_FULFILLED';
