import UserAdminRoute from './routes/Admin';
import { UserApplicationsDetailRoute, UserApplicationsRoute } from './routes/Applications';
import UserBillingRoute from './routes/Billing';
import UserCVRoute from './routes/CV';
import { fetchUser } from '../../actions/user';
import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';
import * as USER_GROUPS from '../../constants/auth/userGroups';
import {
  queryApplicationStructures,
  queryApplicationStructureOptions,
  hasFetchedAllApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../actions/structures';
import { getOperationalAreas } from '../../actions/operationalareas';
import { fetchAllPages } from '../../actions/utils';

export const UsersRoute = () => ({
  path: 'users',
  authorize: {
    groups: [
      USER_GROUPS.APPLICANT,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.SECRETARY_ASSISTANT,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/UsersContainer')
      .then(module => cb(null, module.default));
  },
});

export const UsersDetailRoute = store => ({
  path: 'users/:id',
  authorize: {
    groups: [
      USER_GROUPS.APPLICANT,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.SECRETARY_ASSISTANT,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/UsersDetailContainer').then((module) => {
      const state = store.getState();

      store.dispatch(fetchUser(nextState.params.id));
      if (!state.structures.fetchedAll && !state.structures.startedFetching) {
        store.dispatch(hasStartedFetchingApplicationStructures());
        store.dispatch(fetchAllPages(
          queryApplicationStructures,
          { stripped: true },
          hasFetchedAllApplicationStructures,
        ));
      }
      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
      store.dispatch(getOperationalAreas());
      store.dispatch(setActivePage(menuPages.PROFILE_PAGE));

      cb(null, module.default);
    });
  },
  childRoutes: [
    UserCVRoute(store),
    UserApplicationsDetailRoute(store),
    UserApplicationsRoute(store),
    UserBillingRoute(store),
    UserAdminRoute(store),
  ],
});
