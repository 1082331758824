import * as types from '../constants/userNotes/actionTypes';

const initialState = {};

const userNotesReducer = (state = initialState, action) => {
  let userId;
  let noteId;

  switch (action.type) {
  case types.QUERY_NOTES_FULFILLED:
    userId = action.payload.userId;
    return {
      ...state,
      [userId]: action.payload.response.data,
    };
  case types.CREATE_NOTE_FULFILLED:
    userId = action.payload.userId;
    return {
      ...state,
      [userId]: [
        action.payload.response.data,
        ...state[userId],
      ],
    };
  case types.DESTROY_NOTE_FULFILLED:
    userId = action.payload.userId;
    noteId = action.payload.noteId;
    return {
      ...state,
      [userId]: [
        ...state[userId].filter(
          item => item.id !== noteId,
        ),
      ],
    };
  default:
    return state;
  }
};

export default userNotesReducer;
