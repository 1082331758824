/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Checkbox.less';
import Required from './Required';


const Checkbox = (props) => {
  const classes = cx(
    styles.Checkbox,
    {
      [styles.disabled]: props.disabled === true,
      [styles.inline]: props.inline === true,
      [styles.visibilityToggle]: props.visibilityToggle === true,
      [styles.defaultStyle]: props.visibilityToggle !== true,
    },
    props.className,
  );

  return (
    <div className={classes}>
      {props.children}
      {props.required &&
      <Required />}
      {props.labelFor &&
      <label htmlFor={props.labelFor}> {props.label} </label>
      }
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  visibilityToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  labelFor: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  inline: false,
  disabled: false,
  visibilityToggle: false,
  required: false,
  labelFor: null,
  className: '',
  label: '',
};


export default Checkbox;
