import { connect } from 'react-redux';

import ForgotPasswordLink from '../components/ForgotPasswordLink';
import { showModal } from '../actions/modal';

const show = () => (dispatch) => {
  dispatch(showModal('passwordreset'));
};

const mapDispatchToProps = {
  show,
};

const mapStateToProps = state => ({
  showModal: state.modals.passwordreset,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordLink);
