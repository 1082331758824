const LOCALSTORAGE_STATE = 'state';

export const loadState = () => {
  try {
    const serializerdState = sessionStorage.getItem(LOCALSTORAGE_STATE);

    if (serializerdState === null) {
      return undefined;
    }

    return JSON.parse(serializerdState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const deserializerdState = JSON.stringify(state);
    sessionStorage.setItem(LOCALSTORAGE_STATE, deserializerdState);
  } catch (err) {
    return false;
  }

  return true;
};
