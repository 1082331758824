import { connect } from 'react-redux';

import MobileMenuButton from '../components/MobileMenu/MobileMenuButton';
import { toggleMobileMenu } from '../actions/menu';


const mapDispatchToProps = {
  toggleMobileMenu,
};

const mapStateToProps = state => ({
  menu: state.menu,
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuButton);
