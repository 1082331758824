import * as types from '../constants/boards/actionTypes';

const initialState = {
  dataTotalSize: 0,
  page: 1,
  boards: {},
  loading: true,
  error: false,
};

const boardsTableReducer = (state = initialState, action) => {
  let response;
  let pagination;
  let queryParams;

  switch (action.type) {
  case types.QUERY_BOARDS_FULFILLED:
    response = action.payload.response;
    pagination = response.pagination;
    queryParams = action.payload.queryParams;

    return {
      ...state,
      boards: { ...response.boards },
      dataTotalSize: pagination.count,
      loading: false,
      error: false,
      page: queryParams.filters.page,
    };
  case types.QUERY_BOARDS_PENDING:
    return {
      ...state,
      loading: true,
      error: false,
    };
  case types.QUERY_BOARDS_REJECTED:
    return {
      ...state,
      loading: false,
      error: true,
    };
  default:
    return state;
  }
};

export default boardsTableReducer;
