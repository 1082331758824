import { isEmpty } from 'lodash';

import axios from '../api/axios';
import * as types from '../constants/degrees/actionTypes';


export const getOperationalAreas = () => (dispatch, getStore) => {
  const operationalAreas = getStore().operationalAreas;
  if (isEmpty(operationalAreas)) {
    return dispatch({
      type: types.QUERY_OPERATIONAL_AREAS,
      payload: axios.get('/operationalareas'),
    });
  }
  return null;
};


export default getOperationalAreas;
