import PropTypes from 'prop-types';

const ReduxFormFieldShape = PropTypes.shape({
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string,
    error: PropTypes.shape([]),
    touched: PropTypes.bool,
  }).isRequired,
});

export default ReduxFormFieldShape;
