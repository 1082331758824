import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { throttle } from 'lodash';
import createRavenMiddleware from 'raven-for-redux';

import createReducers from '../reducers';
import injectReducer from '../reducers/utils';
import sagaMiddleware, { injectSaga, runInitialSagas } from '../sagas';
import { loadState, saveState } from './localStorage';
import Raven from '../utils/raven';

const middlewareArray = [
  sagaMiddleware,
  createRavenMiddleware(Raven),
  promise(),
  thunk,
  routerMiddleware(browserHistory),
];

const logger = createLogger({
  collapsed: true,
});

if (process.env.NODE_ENV === 'development') {
  middlewareArray.push(logger);
}

const middleware = applyMiddleware(
  ...middlewareArray,
);

const composeEnhancers = composeWithDevTools({});

const preloadedState = loadState();
const store = {
  ...createStore(
    createReducers(),
    preloadedState,
    composeEnhancers(
      middleware,
    ),
  ),
  asyncReducers: {},
  asyncSagas: {},
  injectReducer,
  injectSaga,
};

runInitialSagas(store);

// Backup state to local storage
store.subscribe(throttle(() => {
  saveState({
    auth: store.getState().auth,
  });
}, 2000));

export default store;
