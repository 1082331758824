export const QUERY_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const QUERY_ORGANIZATIONS_FULFILLED = 'FETCH_ORGANIZATIONS_FULFILLED';

export const REMOVE_ORGANIZATION_PENDING = 'REMOVE_ORGANIZATION_PENDING';
export const REMOVE_ORGANIZATION_FULFILLED = 'REMOVE_ORGANIZATION_FULFILLED';
export const REMOVE_ORGANIZATION_REJECTED = 'REMOVE_ORGANIZATION_REJECTED';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_FULFILLED = 'UPDATE_ORGANIZATION_FULFILLED';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_FULFILLED = 'CREATE_ORGANIZATION_FULFILLED';

export const SORT_ORGANIZATION = 'SORT_ORGANIZATION';
export const SORT_ORGANIZATION_FULFILLED = 'SORT_ORGANIZATION_FULFILLED';
