import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import fiLocaleData from 'react-intl/locale-data/fi';
import svLocaleData from 'react-intl/locale-data/sv';

import messagesFi from './fi.json';
import messagesSv from './sv.json';

addLocaleData(enLocaleData);
addLocaleData(fiLocaleData);
addLocaleData(svLocaleData);

// https://www.w3schools.com/tags/ref_language_codes.asp
const messages = {
  fi: messagesFi,
  sv: messagesSv,
};

export default messages;
