import { isEmpty, get } from 'lodash';

import { getApplication } from '../../../../actions/applications';
import { getDegrees } from '../../../../actions/degrees';
import { filtersDependenciesLoaded, filtersDependenciesLoading, queryApplications } from './actions/applicationTable';
import {
  getApplicationStructure,
  queryApplicationStructureOptions,
  queryApplicationStructures,
  hasFetchedAllStructureOption,
  hasFetchedAllApplicationStructures,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../../../actions/structures';
import { fetchAllPages } from '../../../../actions/utils';
import { getUserAttributeTypes } from '../../../../actions/userAttributeTypes';
import { fetchUser } from '../../../../actions/user';
import { getBoardMeeting } from '../../../../actions/boardMeetings';
import getQueryStringValue from '../../../../utils/urls';

export const ApplicationsRoute = store => ({
  path: 'applications',
  getComponent(nextState, cb) {
    import('./containers/ApplicationsRoute')
      .then(async (module) => {
        const applicationTableReducer = await import('./reducers/applicationTable');
        store.injectReducer({ key: 'applicationTable', reducer: applicationTableReducer.default });

        const state = store.getState();

        // Fetch all application, excluding drafts if there is no data fetched from before
        // NOTE: The [1] marks the first page for pagination
        if (isEmpty(state.applicationTable.data[1])) {
          store.dispatch(queryApplications({
            state: '-draft,approved_for_board,change_request,change_request_can_cancel,pending_resent,pending',
            exclude_board: true,
          }));
        }

        store.dispatch(filtersDependenciesLoading());

        const filters = { no_revisions: true, stripped: true };
        let structurePromise;
        if (!state.structures.fetchedAll && !state.structures.startedFetching) {
          store.dispatch(hasStartedFetchingApplicationStructures());
          structurePromise = store.dispatch(fetchAllPages(
            queryApplicationStructures,
            filters,
            hasFetchedAllApplicationStructures,
          ));
        } else {
          structurePromise = Promise.resolve();
        }
        let structureOptionsPromise;
        if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
          store.dispatch(hasStartedFetchingStructureOptions());
          structureOptionsPromise = store.dispatch(fetchAllPages(
            queryApplicationStructureOptions,
            {},
            hasFetchedAllStructureOption,
          ));
        } else {
          structureOptionsPromise = Promise.resolve();
        }

        Promise.all([structurePromise, structureOptionsPromise]).then(() => {
          store.dispatch(filtersDependenciesLoaded());
        });
        cb(null, module.default);
      });
  },
});

export const ApplicationsDetailRoute = store => ({
  path: 'applications/:applicationId',
  getComponent(nextState, cb) {
    import('./containers/ApplicationDetailRoute')
      .then((module) => {
        const state = store.getState();
        const meetingId = getQueryStringValue('meeting');
        store.dispatch(getApplication(nextState.params.applicationId)).then((applicationResponse) => {
          const userId = get(applicationResponse, 'payload.data.user.id');
          const applicationStructureId = get(applicationResponse, 'payload.data.application_structure');

          if (!state.users[userId]) {
            /* Fetch the application owner data too since this is needed
              while updating the user attributes as these comes from users.user and
              not application.user
            */
            store.dispatch(fetchUser(userId));
          }
          if (meetingId && !state.boardMeetings.boardMeetings[meetingId]) {
            store.dispatch(getBoardMeeting(meetingId));
          }

          store.dispatch(getApplicationStructure(applicationStructureId));
        });

        if (isEmpty(state.degrees)) {
          store.dispatch(getDegrees());
        }

        if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
          store.dispatch(hasStartedFetchingStructureOptions());
          store.dispatch(fetchAllPages(
            queryApplicationStructureOptions,
            {},
            hasFetchedAllStructureOption,
          ));
        }

        if (isEmpty(state.userAttributeTypes)) {
          store.dispatch(fetchAllPages(getUserAttributeTypes));
        }

        cb(null, module.default);
      });
  },
});
