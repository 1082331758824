import * as types from '../constants/locale/actionTypes';

const initialState = {
  currentLanguage: localStorage.getItem('currentLanguage') || 'fi',
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.LOCALE_SELECTED:
    return {
      currentLanguage: action.payload,
    };
  default:
    return state;
  }
};

export default localeReducer;
