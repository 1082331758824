import { isEmpty } from 'lodash';

import axios from '../api/axios';
import * as types from '../constants/structure/actionTypes';

export const queryCurrentActiveApplicationStructures = parameters => (dispatch) => {
  const params = isEmpty(parameters) ? {} : parameters;
  params.no_revisions = true;

  return dispatch(queryApplicationStructures(params));
};


export const queryApplicationStructures = (parameters, override = false) => (dispatch) => {
  const params = isEmpty(parameters) ? {} : parameters;

  if (override) {
    return dispatch({
      type: types.QUERY_APPLICATION_STRUCTURES_OVERRIDE,
      payload: axios.get('/applicationstructures', { params }),
    });
  }
  return dispatch({
    type: types.QUERY_APPLICATION_STRUCTURES,
    payload: axios.get('/applicationstructures', { params }),
  });
};

export const hasFetchedAllApplicationStructures = () => dispatch => dispatch({
  type: types.FETCHED_ALL_APPLICATION_STRUCTURES,
  payload: {},
});

export const hasStartedFetchingApplicationStructures = () => dispatch => dispatch({
  type: types.STARTED_FETCHING_APPLICATION_STRUCTURES,
  payload: {},
});

export const queryApplicationStructureUpdateTime = () => dispatch => dispatch({
  type: types.QUERY_APPLICATION_STRUCTURES_UPDATE_TIME,
  payload: axios.get('/applicationstructures/last_update'),
});

export const getApplicationStructure = id => dispatch => dispatch({
  type: types.GET_APPLICATION_STRUCTURE,
  payload: axios.get(`/applicationstructures/${id}`),
});

export const createApplicationStructure = data => dispatch => dispatch({
  type: types.CREATE_APPLICATION_STRUCTURE,
  payload: axios.post('/applicationstructures', data),
});

export const updateApplicationStructure = (id, data) => dispatch => dispatch({
  type: types.UPDATE_APPLICATION_STRUCTURE,
  payload: axios.patch(`/applicationstructures/${id}`, data),
});

export const removeApplicationStructure = id => (dispatch) => {
  const payload = {
    id,
  };

  dispatch({
    type: types.REMOVE_APPLICATION_STRUCTURE_PENDING,
    payload,
  });

  return axios.delete(`/applicationstructures/${id}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_APPLICATION_STRUCTURE_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_APPLICATION_STRUCTURE_REJECTED,
          payload: error,
        });
      },
    );
};

export const hasFetchedAllStructureOption = () => dispatch => dispatch({
  type: types.FETCHED_ALL_STRUCTURE_OPTION,
  payload: {},
});

export const hasStartedFetchingStructureOptions = () => dispatch => dispatch({
  type: types.STARTED_FETCHING_STRUCTURE_OPTION,
  payload: {},
});

export const queryStructureOptionsUpdateTime = () => dispatch => dispatch({
  type: types.QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME,
  payload: axios.get('/applicationstructureoptions/last_update'),
});

export const createApplicationStructureOption = data => dispatch => dispatch({
  type: types.CREATE_APPLICATION_STRUCTURE_OPTION,
  payload: axios.post('/applicationstructureoptions', data),
});

export const updateApplicationStructureOption = (id, data) => dispatch => dispatch({
  type: types.UPDATE_APPLICATION_STRUCTURE_OPTION,
  payload: axios.patch(`/applicationstructureoptions/${id}`, data),
});

export const sortCertificationCategories = data => dispatch => dispatch({
  type: types.SORT_APPLICATION_STRUCTURE_CATEGORY,
  payload: axios.put('/applicationstructureoptions/sort', data),
});

export const removeApplicationStructureOption = (id, type) => (dispatch) => {
  const payload = {
    id,
    type,
  };

  dispatch({
    type: types.REMOVE_APPLICATION_STRUCTURE_OPTION_PENDING,
    payload,
  });

  return axios.delete(`/applicationstructureoptions/${id}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_APPLICATION_STRUCTURE_OPTION_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_APPLICATION_STRUCTURE_OPTION_REJECTED,
          payload: error,
        });
      },
    );
};

export const queryApplicationStructureOptions = parameters => (dispatch) => {
  const params = isEmpty(parameters) ? {} : parameters;

  return dispatch({
    type: types.QUERY_APPLICATION_STRUCTURE_OPTIONS,
    payload: axios.get('/applicationstructureoptions', { params }),
  });
};
