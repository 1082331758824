import { change, initialize } from 'redux-form';
import { get, isEmpty } from 'lodash';
import { push } from 'react-router-redux';
import { all, fork, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import * as types from '../constants/applications/actionTypes';
import APPLICATION_WIZARD_FORM from '../routes/ApplicationWizard/constants/forms';
import { ApplicationFormKeys } from '../structures/applicationForm';
import {
  changePage,
  setApplicationStructure,
  setBillingSelection,
  setImmutableApplication,
} from '../routes/ApplicationWizard/actions/wizard';
import { filterFields } from '../utils/forms';
import store from '../store';
import { getApplicationStructure } from '../actions/structures';
import * as states from '../constants/applications/applicationStates';

// ------------------------------------
// Store selectors
// ------------------------------------

const getApplicationStructures = state => (
  state.structures
);


// ------------------------------------
// Action Handlers
// ------------------------------------
const applicationToApplicationForm = application => ({
  ...application,
  company: !isEmpty(application.company) ? application.company.id : '',
  company_billing: undefined, // We set billing later on
  personal_billing: undefined,
});

export function* openApplicationDraft({ payload }) {
  const application = payload.application;
  const structureId = payload.structureId ? payload.structureId : application.application_structure;
  yield putResolve(getApplicationStructure(structureId));
  const structures = yield select(getApplicationStructures);
  const structure = get(structures, structureId);

  // Make sure that we have the application wizard reducer loaded
  // If we do not do this, the reducer will not be available until
  // we do the actual page transition.
  // Note also that the import of the reducer does not mean that we need
  // to load the entire ApplicationWizard js bundle breaking code splitting.
  const wizardReducer = yield import('../routes/ApplicationWizard/reducers/wizard');
  store.injectReducer({ key: 'applicationWizard', reducer: wizardReducer.default });
  let filteredApplication = filterFields(application, ApplicationFormKeys);
  filteredApplication = applicationToApplicationForm(filteredApplication);

  // Override the application structure to use optinally sent in structure id.
  // This is done so that an application can move from one structure to another
  // while still keeping the data. This is particulary useful when changing the
  // revision of an application.
  filteredApplication.application_structure = structureId;

  yield put(initialize(APPLICATION_WIZARD_FORM, filteredApplication));
  yield put(change(APPLICATION_WIZARD_FORM, 'qualification_category', structure.certification_category));

  /* No matter what the state of application is, it is set as draft when it is saved. (This should not be
  confused with the application history which actually holds the application state. So application.draft
  can be True but that doesn't mean it's actully draft. To know its actual state use application.current_state
  in backend) This prevents rendering immutable application items (i.e. Items that were sent previously).
  Therefore, force to set immutable application items on applications where change is requested
  */
  const latestState = application.latest_state;
  const changesRequested = (
    latestState === states.APPLICATION_STATE_CHANGE_REQUEST ||
    latestState === states.APPLICATION_STATE_CHANGE_REQUEST_CAN_CANCEL
  );
  if (!application.draft || changesRequested) {
    yield put(setImmutableApplication(application));
  }

  // We need to handle billing a bit differently since the back end does not want null values.
  // If we set any of the billing values at the time of initializing the form then they will not
  // be removed from the form when the value is set to empty string. If they are not initialized
  // and then set to empty string or undefined, they will be removed from the form. This is a
  // quirk of redux-form and kind of a hack to get around there not being any resetField function.
  if (!isEmpty(application)) {
    if (!isEmpty(application.personal_billing)) {
      yield put(setBillingSelection('personal'));
      const originalBillingItem = application.personal_billing_data.original;
      const personalBilling = originalBillingItem || application.personal_billing;
      yield put(change(APPLICATION_WIZARD_FORM, 'personal_billing', personalBilling));
    } else if (!isEmpty(application.company_billing)) {
      yield put(setBillingSelection('company'));
      const originalBillingItem = application.company_billing_data.original;
      const companyBilling = originalBillingItem || application.company_billing;
      yield put(change(APPLICATION_WIZARD_FORM, 'company_billing', companyBilling));
    }
  }

  yield put(setApplicationStructure(structure));

  yield put(changePage(1));
  yield put(push('/applicationWizard'));
}

// ------------------------------------
// Watchers
// ------------------------------------
export function* watchApplicationsSagas() {
  yield all([
    takeLatest(types.OPEN_APPLICATION_DRAFT, openApplicationDraft),
  ]);
}


export default function* applicationSagas() {
  yield all([
    fork(watchApplicationsSagas),
  ]);
}
