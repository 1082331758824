export const CHANGE_WIZARD_PAGE = 'CHANGE_WIZARD_PAGE';
export const CHANGE_WIZARD_PAGE_FULFILLED = 'CHANGE_WIZARD_PAGE_FULFILLED';

export const SEND_APPLICATION = 'SAVE_APPLICATION';
export const SEND_APPLICATION_PENDING = 'SEND_APPLICATION_PENDING';
export const SEND_APPLICATION_FULFILLED = 'SAVE_APPLICATION_FULFILLED';
export const SEND_APPLICATION_REJECTED = 'SAVE_APPLICATION_REJECTED';

export const SEND_APPLICATION_DRAFT = 'SAVE_APPLICATION_DRAFT';
export const SEND_APPLICATION_DRAFT_FULFILLED = 'SAVE_APPLICATION_DRAFT_FULFILLED';
export const SEND_APPLICATION_DRAFT_REJECTED = 'SAVE_APPLICATION_DRAFT_REJECTED';
export const SEND_APPLICATION_DRAFT_RESET = 'SAVE_APPLICATION_DRAFT_RESET';

export const SET_WIZARD_QUALIFICATION = 'SET_WIZARD_QUALIFICATION';
export const SET_WIZARD_QUALIFICATION_LEVEL = 'SET_WIZARD_QUALIFICATION_LEVEL';
export const SET_WIZARD_QUALIFICATION_FULFILLED = 'SET_WIZARD_QUALIFICATION_FULFILLED';
export const SET_WIZARD_QUALIFICATION_LEVEL_FULFILLED = 'SET_WIZARD_QUALIFICATION_LEVEL_FULFILLED';

export const SET_BILLING_SELECTION = 'SET_BILLING_SELECTION';
export const SET_APPLICATION_STRUCTURE = 'SET_APPLICATION_STRUCTURE';

export const RESET_WIZARD = 'RESET_WIZARD';
export const OPEN_AS_ADMIN = 'OPEN_AS_ADMIN';

export const SET_IMMUTABLE_APPLICATION = 'SET_IMMUTABLE_APPLICATION';

export const DESTROY_APPLICATION_WIZARD = 'DESTROY_APPLICATION_WIZARD';
