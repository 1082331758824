export const QUERY_BOARDS = 'QUERY_BOARDS';
export const QUERY_BOARDS_PENDING = 'QUERY_BOARDS_PENDING';
export const QUERY_BOARDS_FULFILLED = 'QUERY_BOARDS_FULFILLED';
export const QUERY_BOARDS_REJECTED = 'QUERY_BOARDS_REJECTED';

export const GET_BOARD = 'GET_BOARD';
export const GET_BOARD_PENDING = 'GET_BOARD_PENDING';
export const GET_BOARD_FULFILLED = 'GET_BOARD_FULFILLED';
export const GET_BOARD_REJECTED = 'GET_BOARD_REJECTED';

export const CREATE_BOARD = 'CREATE_BOARD';
export const CREATE_BOARD_PENDING = 'CREATE_BOARD_PENDING';
export const CREATE_BOARD_FULFILLED = 'CREATE_BOARD_FULFILLED';
export const CREATE_BOARD_REJECTED = 'CREATE_BOARD_REJECTED';

export const UPDATE_BOARD = 'UPDATE_BOARD';
export const UPDATE_BOARD_PENDING = 'UPDATE_BOARD_PENDING';
export const UPDATE_BOARD_FULFILLED = 'UPDATE_BOARD_FULFILLED';
export const UPDATE_BOARD_REJECTED = 'UPDATE_BOARD_REJECTED';

export const REMOVE_BOARD = 'REMOVE_BOARD';
export const REMOVE_BOARD_PENDING = 'REMOVE_BOARD_PENDING';
export const REMOVE_BOARD_FULFILLED = 'REMOVE_BOARD_FULFILLED';
export const REMOVE_BOARD_REJECTED = 'REMOVE_BOARD_REJECTED';

export const QUERY_BOARD_PENDING = 'QUERY_BOARD_PENDING';
export const QUERY_BOARD_FULFILLED = 'QUERY_BOARD_FULFILLED';
