import createSagaMiddleware from 'redux-saga';

import attachmentSagas from './attachments';
import localeSagas from './locale';
import authenticationSagas from './auth';
import applicationSagas from './application';
import applicationWizardSaga from '../routes/ApplicationWizard/sagas';

const sagaMiddleware = createSagaMiddleware();

export function injectSaga({ key, saga }) {
  if (Object.hasOwnProperty.call(this.asyncSagas, key)) {
    return;
  }

  this.asyncSagas = {
    ...this.asyncSagas,
    [key]: saga,
  };

  sagaMiddleware.run(saga);
}

export const runInitialSagas = (store) => {
  const initialSagas = {
    attachments: attachmentSagas,
    locale: localeSagas,
    auth: authenticationSagas,
    application: applicationSagas,
    wizardSagas: applicationWizardSaga,
  };

  Object.keys(initialSagas).forEach((key) => {
    store.injectSaga({ key, saga: initialSagas[key] });
  });
};

export default sagaMiddleware;
