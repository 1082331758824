import React from 'react';
import PropTypes from 'prop-types';


const DangerousDiv = props => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: props.innerHTML }} key={props.key} className={props.className} />
);

DangerousDiv.propTypes = {
  innerHTML: PropTypes.string,
  key: PropTypes.string,
  className: PropTypes.string,
};

DangerousDiv.defaultProps = {
  innerHTML: '',
  key: '',
  className: '',
};

export default DangerousDiv;
