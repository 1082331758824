import React from 'react';
import { toastr } from 'react-redux-toastr';

import IntlToast from '../components/Toastr/IntlToast';
import ServerErrorToast from '../components/Toastr/ServerErrorToast';


const templateToastr = (method, template, model, options) => {
  // We need to have a key here to supress the react errors that otherwise occurs.
  const key = `${method}_${model}`;
  const messageElement = React.createElement(
    IntlToast,
    { template, model, key },
  );

  const fn = toastr[method];
  if (typeof fn === 'function') {
    fn([messageElement], options);
  }
};

export const serverErrorToastr = (errorResponse, options) => {
  // We need to have a key here to supress the react errors that otherwise occurs.
  const key = 'errorToast';
  const messageElement = React.createElement(
    ServerErrorToast,
    { errorResponse, key },
  );

  toastr.error([messageElement], options);
};

export default templateToastr;
