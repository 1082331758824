import { keyBy, omit } from 'lodash';

import * as types from '../constants/boardMeetings/actionTypes';

const initialState = {
  page: 1,
  boardMeetings: {},
  loading: true,
  error: false,
};

const boardMeetingsReducer = (state = initialState, action) => {
  let response;
  switch (action.type) {
  case types.GET_BOARD_MEETING_FULFILLED:
  case types.UPDATE_BOARD_MEETING_FULFILLED:
    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        [action.payload.data.id]: action.payload.data,
      },
    };
  case types.REMOVE_BOARD_MEETING_FULFILLED: {
    const id = action.payload.id;
    return {
      ...state,
      boardMeetings: {
        ...omit(state.boardMeetings, id),
      },
    };
  }
  case types.CREATE_BOARD_MEETING_FULFILLED:
    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        [action.payload.data.id]: { ...action.payload.data },
      },
    };
  case types.QUERY_BOARD_MEETINGS_PENDING:
    return {
      ...state,
      loading: true,
      error: false,
    };
  case types.QUERY_BOARD_MEETINGS_FULFILLED:
    response = action.payload;

    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        ...keyBy(response.data, 'id'),
      },
      loading: false,
      error: false,
    };
  case types.QUERY_BOARD_MEETINGS_REJECTED:
    return {
      ...state,
      loading: false,
      error: true,
    };
  case types.UPDATE_BOARD_MEETING_STATE_FULFILLED:
    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        [action.payload.meetingId]: {
          ...state.boardMeetings[action.payload.meetingId],
          state_data: action.payload.data,
          state: action.payload.data.id,
        },
      },
    };
  case types.REORDER_BOARD_MEETING_APPLICATIONS_FULFILLED:
    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        [action.payload.data.id]: {
          ...state.boardMeetings[action.payload.data.id],
          applications: action.payload.data.applications,
          applications_data: action.payload.data.applications_data,
        },
      },
    };
  case types.GET_MEETING_PUBLISHED_EMAIL_PREVIEW_FULFILLED:
    return {
      ...state,
      boardMeetings: {
        ...state.boardMeetings,
        [action.payload.data.id]: {
          ...state.boardMeetings[action.payload.data.id],
          emailPreview: action.payload.data.email_preview,
        },
      },
    };
  default:
    return state;
  }
};

export default boardMeetingsReducer;

