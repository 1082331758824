const initialValue = {
  contactFiseFormSent: false,
  contactSecretaryFormSent: false,
  contactFiseFormErrors: {},
  contactSecretaryFormErrors: {},
};

const contactUsReducer = (state = initialValue, action) => {
  switch (action.type) {
  case 'SET_FISE_CONTACT_FORM_SENT':
    return {
      ...state,
      contactFiseFormSent: true,
    };
  case 'SET_SECRETARY_CONTACT_FORM_SENT':
    return {
      ...state,
      contactSecretaryFormSent: true,
    };
  case 'SET_FISE_CONTACT_FORM_ERRORS':
    return {
      ...state,
      contactFiseFormErrors: { ...state.contactFiseFormErrors, ...action.payload },
    };
  case 'SET_SECRETARY_CONTACT_FORM_ERRORS':
    return {
      ...state,
      contactSecretaryFormErrors: { ...state.contactSecretaryFormErrors, ...action.payload },
    };
  default:
    return state;
  }
};

export default contactUsReducer;
