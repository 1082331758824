import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './style.less';
import DangerousDiv from '../../../../utils/DangerousDiv';


class YellowItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
      columns: props.columns,
    };
  }

  render() {
    return (
      <div className={`${styles.column} ${styles.yellowIcon} col-sm-${this.state.columns}`}>
        <div className={styles.hex}>
          <div className={styles.hexIconHolder}>
            <img
              className={styles.filterWhite}
              src={`data:image/svg+xml;utf8,${this.state.section.value.svg}`}
              alt="icon"
            />
          </div>
          <div className={styles.hexRight} />
          <div className={styles.hexLeftTop} />
          <div className={styles.hexLeftBottom} />
        </div>
        <h5>{this.state.section.value.title}</h5>
        <DangerousDiv innerHTML={this.state.section.value.text} key={this.state.section.id} className="" />
      </div>
    );
  }
}


YellowItem.propTypes = {
  section: PropTypes.shape({}),
  columns: PropTypes.number,
};

YellowItem.defaultProps = {
  section: {},
  columns: 1,
};

export default YellowItem;
