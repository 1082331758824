// We only need to import the modules necessary for initial render
import ApplicationWizard from './ApplicationWizard';
import CoreLayout from '../layouts/CoreLayout';
import Dashboard from './Dashboard';
import HomeRoute from './Home';
import NotFoundRoute from './NotFound';
import FailureRoute from './Failure';
import { RegistryDetailRoute, RegistryRoute } from './Registry';
import { UsersDetailRoute, UsersRoute } from './Users';
import ApplyForQualificationRoute from './ApplyForQualification';
import NewsRoute from './News';
import ResetPasswordRoute from './ResetPassword';
import EmailVerificationSuccessRoute from './EmailVerification';
import EmailAlreadyVerifiedRoute from './EmailAlreadyVerified';
import CMSRoute from './CMS';
import { setActivePage } from '../actions/menu';
import * as menuPages from '../constants/menu/pages';
import routerPermissionWrapper from '../auth/routerPermissionWrapper';
import ContactUsRoute from './Contact';
import {
  queryApplicationStructures,
  queryApplicationStructureOptions,
  queryApplicationStructureUpdateTime,
  queryStructureOptionsUpdateTime,
  hasFetchedAllApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../actions/structures';
import { fetchAllPages } from '../actions/utils';
import { CLEAR_OLD_APPLICATIONS } from '../constants/structure/actionTypes';

/*  Note: Instead of using JSX, we recommend using react-router
 PlainRoute objects to build route definitions.   */

const createRoutes = store => ({
  path: '/',
  getComponent(nextState, cb) {
    store.dispatch(setActivePage(menuPages.HOME_PAGE));
    const state = store.getState();
    store.dispatch({
      type: CLEAR_OLD_APPLICATIONS,
    });

    store.dispatch(queryApplicationStructureUpdateTime()).then((response) => {
      if (!state.structures.fetchedAll && !state.structures.startedFetching) {
        store.dispatch(hasStartedFetchingApplicationStructures());
        store.dispatch(fetchAllPages(
          queryApplicationStructures,
          { stripped: true },
          hasFetchedAllApplicationStructures,
        ));
      }
    });

    store.dispatch(queryStructureOptionsUpdateTime()).then((response) => {
      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
    });
    cb(null, routerPermissionWrapper(CoreLayout));
  },
  indexRoute: HomeRoute,
  childRoutes: [
    ApplyForQualificationRoute(store),
    ContactUsRoute(store),
    NewsRoute(store),
    UsersRoute(store),
    UsersDetailRoute(store),
    ApplicationWizard(store),
    Dashboard(store),
    RegistryRoute(store),
    RegistryDetailRoute(store),
    ResetPasswordRoute(store),
    EmailVerificationSuccessRoute(store),
    EmailAlreadyVerifiedRoute(store),
    FailureRoute(store),
    CMSRoute(store),
    NotFoundRoute(store),
  ],
});

export default createRoutes;
