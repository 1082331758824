import { defineMessages } from 'react-intl';

const modelMessages = defineMessages({
  work_items: {
    id: 'models.name.work_items',
    defaultMessage: 'Work item',
  },
  education_items: {
    id: 'models.name.education_items',
    defaultMessage: 'Education',
  },
  course_items: {
    id: 'models.name.course_items',
    defaultMessage: 'Course',
  },
  fise_exam_items: {
    id: 'models.name.fise_exam_items',
    defaultMessage: 'Exam',
  },
  training_items: {
    id: 'models.name.training_items',
    defaultMessage: 'Continuous Professional Development',
  },
  cpd_course_items: {
    id: 'models.name.cpd_course_items',
    defaultMessage: 'CPD Course',
  },
  reference_items: {
    id: 'models.name.reference_items',
    defaultMessage: 'Reference',
  },
  company_billing_information: {
    id: 'models.name.company_billing_information',
    defaultMessage: 'Company billing',
  },
  personal_billing_information: {
    id: 'models.name.personal_billing_information',
    defaultMessage: 'Personal billing',
  },
  users: {
    id: 'models.name.users',
    defaultMessage: 'User',
  },
  user_note: {
    id: 'models.name.user_note',
    defaultMessage: 'Note',
  },
  application_state: {
    id: 'models.name.application_state',
    defaultMessage: 'State',
  },
  application: {
    id: 'models.name.application',
    defaultMessage: 'Application',
  },
  application_structure: {
    id: 'models.name.application_structure',
    defaultMessage: 'Application structure',
  },
  application_structure_option: {
    id: 'models.name.application_structure_option',
    defaultMessage: 'Option',
  },
  application_note: {
    id: 'models.name.application_note',
    defaultMessage: 'Note',
  },
  degree: {
    id: 'models.name.degree',
    defaultMessage: 'Degree',
  },
  statement_items: {
    id: 'models.name.statement',
    defaultMessage: 'Statement',
  },
  statement_author_items: {
    id: 'models.name.statementAuthor',
    defaultMessage: 'Statement author',
  },
  user_attribute_type: {
    id: 'models.name.userAttributeType',
    defaultMessage: 'Attribute type',
  },
  user_attribute: {
    id: 'models.name.userAttribute',
    defaultMessage: 'Attribute',
  },
  operational_area: {
    id: 'models.name.operationalArea',
    defaultMessage: 'Operational area',
  },
  comment: {
    id: 'models.name.comment',
    defaultMessage: 'Comment',
  },
  member: {
    id: 'models.name.boardMember',
    defaultMessage: 'Member',
  },
  board: {
    id: 'models.name.boardName',
    defaultMessage: 'Board',
  },
  board_meeting: {
    id: 'models.name.boardMeeting',
    defaultMessage: 'Board meeting',
  },
  attendees: {
    id: 'models.name.attendees',
    defaultMessage: 'Attendees',
  },
  // Not really a model type that comes from the backend
  // but can be used as a deafult value if no model is given.
  item: {
    id: 'models.name.item',
    defaultMessage: 'Item',
  },
});

const modelMessagesPlural = defineMessages({
  work_items: {
    id: 'models.name.work_items_plural',
    defaultMessage: 'Work items',
  },
  education_items: {
    id: 'models.name.education_items_plural',
    defaultMessage: 'Educations',
  },
  course_items: {
    id: 'models.name.course_items_plural',
    defaultMessage: 'Courses',
  },
  fise_exam_items: {
    id: 'models.name.fise_exam_items_plural',
    defaultMessage: 'Exams',
  },
  training_items: {
    id: 'models.name.training_items_plural',
    defaultMessage: 'Continuous Professional Development',
  },
  cpd_course_items: {
    id: 'models.name.cpd_course_items_plural',
    defaultMessage: 'CPD Courses',
  },
  reference_items: {
    id: 'models.name.reference_items_plural',
    defaultMessage: 'References',
  },
  company_billing_information: {
    id: 'models.name.company_billing_information_plural',
    defaultMessage: 'Company billings',
  },
  personal_billing_information: {
    id: 'models.name.personal_billing_information_plural',
    defaultMessage: 'Personal billings',
  },
  users: {
    id: 'models.name.users_plural',
    defaultMessage: 'Users',
  },
  user_note: {
    id: 'models.name.user_note_plural',
    defaultMessage: 'Notes',
  },
  application_state: {
    id: 'models.name.application_state_plural',
    defaultMessage: 'States',
  },
  application: {
    id: 'models.name.application_plural',
    defaultMessage: 'Applications',
  },
  application_structure: {
    id: 'models.name.application_structure_plural',
    defaultMessage: 'Application structures',
  },
  application_structure_option: {
    id: 'models.name.application_structure_option_plural',
    defaultMessage: 'Options',
  },
  application_note: {
    id: 'models.name.application_note_plural',
    defaultMessage: 'Notes',
  },
  degree: {
    id: 'models.name.degree_plural',
    defaultMessage: 'Degrees',
  },
  statement_items: {
    id: 'models.name.statement_plural',
    defaultMessage: 'Statements',
  },
  statement_author_items: {
    id: 'models.name.statementAuthor_plural',
    defaultMessage: 'Statement authors',
  },
  user_attribute_type: {
    id: 'models.name.userAttributeType_plural',
    defaultMessage: 'Attribute types',
  },
  user_attribute: {
    id: 'models.name.userAttribute_plural',
    defaultMessage: 'Attributes',
  },
  operational_area: {
    id: 'models.name.operationalArea_plural',
    defaultMessage: 'Operational areas',
  },
  comment: {
    id: 'models.name.comment_plural',
    defaultMessage: 'Comments',
  },
  // Not really a model type that comes from the backend
  // but can be used as a deafult value if no model is given.
  item: {
    id: 'models.name.item_plural',
    defaultMessage: 'Items',
  },
});

export const modelMessageMapping = {
  work_items: modelMessages.work_items,
  education_items: modelMessages.education_items,
  course_items: modelMessages.course_items,
  fise_exam_items: modelMessages.fise_exam_items,
  training_items: modelMessages.training_items,
  cpd_course_items: modelMessages.cpd_course_items,
  reference_items: modelMessages.reference_items,
  company_billing_information: modelMessages.company_billing_information,
  personal_billing_information: modelMessages.personal_billing_information,
  users: modelMessages.users,
  user_note: modelMessages.user_note,
  application_state: modelMessages.application_state,
  application: modelMessages.application,
  application_structure: modelMessages.application_structure,
  application_structure_option: modelMessages.application_structure_option,
  application_note: modelMessages.application_note,
  degree: modelMessages.degree,
  statement_items: modelMessages.statement_items,
  statement_author_items: modelMessages.statement_author_items,
  user_attribute_type: modelMessages.user_attribute_type,
  user_attribute: modelMessages.user_attribute,
  operational_area: modelMessages.operational_area,
  comment: modelMessages.comment,
  member: modelMessages.member,
  board: modelMessages.board,
  board_meeting: modelMessages.board_meeting,
  attendees: modelMessages.attendees,
};

export const modelMessageMappingPlural = {
  work_items: modelMessagesPlural.work_items,
  education_items: modelMessagesPlural.education_items,
  course_items: modelMessagesPlural.course_items,
  fise_exam_items: modelMessagesPlural.fise_exam_items,
  training_items: modelMessagesPlural.training_items,
  cpd_course_items: modelMessagesPlural.cpd_course_items,
  reference_items: modelMessagesPlural.reference_items,
  company_billing_information: modelMessagesPlural.company_billing_information,
  personal_billing_information: modelMessagesPlural.personal_billing_information,
  users: modelMessagesPlural.users,
  user_note: modelMessagesPlural.user_note,
  application_state: modelMessagesPlural.application_state,
  application: modelMessagesPlural.application,
  application_structure: modelMessagesPlural.application_structure,
  application_structure_option: modelMessagesPlural.application_structure_option,
  application_note: modelMessagesPlural.application_note,
  degree: modelMessagesPlural.degree,
  statement_items: modelMessagesPlural.statement_items,
  statement_author_items: modelMessagesPlural.statement_author_items,
  user_attribute_type: modelMessagesPlural.user_attribute_type,
  user_attribute: modelMessagesPlural.user_attribute,
  operational_area: modelMessagesPlural.operational_area,
  comment: modelMessagesPlural.comment,
};

export default modelMessages;
