import { destroy } from 'redux-form';
import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';
import * as types from '../ApplicationWizard/constants/actionTypes';
import APPLICATION_WIZARD_FORM from '../ApplicationWizard/constants/forms';


const ApplyForQualificationRoute = store => ({
  path: '/hae-patevyytta',
  getComponent(nextState, cb) {
    store.dispatch(destroy(APPLICATION_WIZARD_FORM));
    store.dispatch({ type: types.RESET_WIZARD });

    import('./containers/ApplyForQualificationRoute')
      .then(async (module) => {
        store.dispatch(setActivePage(menuPages.APPLY_FOR_QUALIFICATION));
        cb(null, module.default);
      });
  },
});

export default ApplyForQualificationRoute;
