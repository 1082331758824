import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const renderErrors = (errors) => {
  if (errors.length > 1) {
    return (
      <ul>
        {errors.map((error, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>{JSON.stringify(error)}</li>
        ))}
      </ul>
    );
  }
  return errors;
};

const Form = props => (
  <form onSubmit={props.handleSubmit} className={props.className}>
    {!isEmpty(props.error) &&
    <div className="alert alert-danger" role="alert">
      {renderErrors(props.error)}
    </div>}
    {props.children}
  </form>
);

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
};

Form.defaultProps = {
  error: [],
  className: '',
};

export default Form;
