import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { Dropdown, MenuItem, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import cmsConfig from '../../constants/cms/config';
import CommonModal from '../../components/Modals/CommonModal';
import LoginButton from '../../containers/LoginButton';
import LogoWhite from './img/logo-white.png';
import MobileMenuButton from '../../containers/MobileMenu';
import ResendEmailVerificationButton from '../../containers/ResendEmailVerificationButton';
import NavDropdownToggle from '../../containers/NavDropdownToggle';
import RegisterLink from '../../containers/RegisterLink';
import RegistrationForm from '../../components/Forms/RegistrationForm';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import UserShape from '../../structures/user';
import AuthShape from '../../structures/auth';
import InfoMenu from '../../structures/infoMenu';
import { userInGroups } from '../../utils/auth';
import DangerousDiv from '../../utils/DangerousDiv';

import * as menuPages from '../../constants/menu/pages';
import * as USER_GROUPS from '../../constants/auth/userGroups';

import styles from './Header.less';

const Header = (props) => {
  const user = !isEmpty(props.currentUser) && props.currentUser.user;
  const tokenUser = user && props.currentUser.user.token_authenticated;

  if (props.menu.infoMenu === null) {
    props.getInfoMenu();
  }
  if (props.warningHeader.warningHeaderText === null) {
    props.getHeaderWarning();
  }

  let unVerifiedListStyle = '';
  let unVerifiedChildListStyle = '';

  if (props.auth.loggedIn && !props.auth.emailVerified) {
    unVerifiedListStyle = styles.pageLinkListFlex;
    unVerifiedChildListStyle = styles.unverifiedFirstList;
  }

  const unverifiedEmailtooltip = (
    <Tooltip id="applyForCertificationTooltip">
      <FormattedMessage
        id="header.emailVerification.tooltip"
        defaultMessage="
          Your email is not verified. Please check your email and click the verification link.
          You can also click this button to send the email.
        "
      />
    </Tooltip>
  );

  const handleDropdownClose = () => {
    props.hideMobileMenu();
  };

  const firstListChildStyle = cx(
    props.menu.activeMenu === menuPages.HOME_PAGE ? 'current-page' : '',
    unVerifiedChildListStyle,
  );

  const pageLinkList = cx(
    styles.pageLinkList,
    unVerifiedListStyle,
  );

  const infoPagesMenuItems = props.menu.infoMenu === null ? [] : props.menu.infoMenu.map(menuItem => (
    <MenuItem
      key={menuItem.url}
      href={`${cmsConfig.cmsBaseUrl}${menuItem.url}`}
      className={window.location.href.endsWith(menuItem.url) ? 'active' : 'dropdown-item'}
    >
      {menuItem.name}
    </MenuItem>
  ));

  const infoPagesDropDown = (
    <li className={props.menu.activeMenu === menuPages.CMS_PAGE ? 'current-page' : ''}>
      <Dropdown id="dropdown-info" className="header-dropdown">
        <NavDropdownToggle bsRole="toggle">
          <i className="fa fa-info link-icon" />
          <FormattedMessage
            id="header.informationPages"
            defaultMessage="Certifications"
          />
        </NavDropdownToggle>
        <Dropdown.Menu>
          {infoPagesMenuItems}
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );

  const homeLink = (
    <Link to="/" onClick={() => handleDropdownClose()}>
      <i className="fa fa-home link-icon" />
      <FormattedMessage
        id="header.home"
        defaultMessage="Home"
      />
    </Link>
  );

  const applyForApplicationLink = (
    <li className={props.menu.activeMenu === menuPages.APPLY_FOR_QUALIFICATION ? 'current-page' : ''}>
      <Link to="/hae-patevyytta" onClick={() => handleDropdownClose()}>
        <i className="fa fa-certificate link-icon" />
        <FormattedMessage
          id="header.apply_for_qualification"
          defaultMessage="Apply for certification"
        />
      </Link>
    </li>
  );

  const browseCertificationsLink = (
    <li className={props.menu.activeMenu === menuPages.REGISTRY_PAGE ? 'current-page' : ''}>
      <Link to="/registry" onClick={() => handleDropdownClose()}>
        <i className="fa fa-file-text-o link-icon" />
        <FormattedMessage
          id="header.browseCertificates"
          defaultMessage="Certification registry"
        />
      </Link>
    </li>
  );

  const dashboardLink = (
    <li className={props.menu.activeMenu === menuPages.DASHBOARD_PAGE ? 'current-page' : ''}>
      <Link to="/dashboard" onClick={() => handleDropdownClose()}>
        <i className="fa fa-th-large link-icon" />
        <FormattedMessage
          id="header.dashboard"
          defaultMessage="Dashboard"
        />
      </Link>
    </li>
  );

  const ContactUsLink = (
    <li className={props.menu.activeMenu === menuPages.CONTACT_US_PAGE ? 'current-page' : ''}>
      <Link to="/contact-us" onClick={() => handleDropdownClose()}>
        <i className="fa fa-envelope-o link-icon" />
        <FormattedMessage
          id="header.contactUs"
          defaultMessage="Contact us"
        />
      </Link>
    </li>
  );

  const newsLink = (
    <li className={props.menu.activeMenu === menuPages.NEWS_PAGE ? 'current-page' : ''}>
      <Link to="/uutiset" onClick={() => handleDropdownClose()}>
        <i className="fa fa-newspaper-o link-icon" />
        <FormattedMessage
          id="header.news"
          defaultMessage="News"
        />
      </Link>
    </li>
  );

  const defaultNavPageLinks = (
    <ul className={pageLinkList}>
      <li className={firstListChildStyle}>
        {homeLink}
      </li>
      {infoPagesDropDown}
      {applyForApplicationLink}
      {browseCertificationsLink}
      {newsLink}
      {!props.auth.emailVerified && props.auth.loggedIn &&
      <li className={styles.unverifiedEmailButton}>
        <ResendEmailVerificationButton warning_button tooltip={unverifiedEmailtooltip} />
      </li>
      }
      {ContactUsLink}
    </ul>
  );

  const dashboardNavPageLinks = (
    <ul className={styles.pageLinkList}>
      <li className={props.menu.activeMenu === menuPages.HOME_PAGE ? 'current-page' : ''}>
        {homeLink}
      </li>
      {infoPagesDropDown}
      {applyForApplicationLink}
      {dashboardLink}
      {browseCertificationsLink}
      {newsLink}
      {ContactUsLink}
    </ul>
  );

  const memberDashboardNavPageLinks = (
    <ul className={styles.pageLinkList}>
      <li className={props.menu.activeMenu === menuPages.HOME_PAGE ? 'current-page' : ''}>
        {homeLink}
      </li>
      {applyForApplicationLink}
      {dashboardLink}
      {browseCertificationsLink}
      {newsLink}
      {ContactUsLink}
    </ul>
  );


  const languageDropdown = (
    <Dropdown id="dropdown-language" className="language">
      <NavDropdownToggle bsRole="toggle">
        <i className="fa fa-globe fa-fw" />
        <span className="text-uppercase">{props.locale}</span>
        <i className="fa fa-angle-down fa-fw" />
      </NavDropdownToggle>
      <Dropdown.Menu onSelect={props.localeSelected}>
        <MenuItem eventKey="en">English</MenuItem>
        <MenuItem eventKey="fi">Suomi</MenuItem>
        <MenuItem eventKey="sv">Svenska</MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  );

  let authControls;
  let navPageLinks = defaultNavPageLinks;

  if (tokenUser) {
    navPageLinks = null;
  }

  const accountControlModals = (
    <div className="accountControlModals">
      <CommonModal
        show={props.showRegisterNewUserModal}
        hide={props.hideRegisterNewUserModal}
        title={<FormattedMessage
          id="header.registerNewUsermodalTitle"
          defaultMessage="Register"
        />}
        content={<RegistrationForm onSubmit={props.register} />}
      />
      <CommonModal
        show={props.showPasswordResetModal}
        hide={props.hidePasswordResetModal}
        title={<FormattedMessage
          id="header.requestPasswordResetModalTitle"
          defaultMessage="Reset password"
        />}
        content={<ForgotPasswordForm onSubmit={props.resetPassword} />}
      />
    </div>
  );

  if (user) {
    const secretaryLink = '/dashboard/';
    const userLink = `/users/${user.id}/cv`;
    const userName = `${user.first_name} ${user.last_name}`;

    if (userInGroups(props.currentUser, [USER_GROUPS.SECRETARY, USER_GROUPS.ADMINISTRATOR, USER_GROUPS.PERSONNEL,
      USER_GROUPS.SECRETARY_ASSISTANT])) {
      navPageLinks = dashboardNavPageLinks;
    } else if (userInGroups(props.currentUser, [USER_GROUPS.BOARD_MEMBER])) {
      navPageLinks = memberDashboardNavPageLinks;
    }

    authControls = (
      <div className={styles.userNav}>
        <Dropdown id="dropdown-user">
          <NavDropdownToggle bsRole="toggle">
            <i className="fa fa-user-o fa-lg fa-fw" />
            <span>{userName}</span>
            <i className="fa fa-angle-down fa-fw" />
          </NavDropdownToggle>
          <Dropdown.Menu>
            {!tokenUser &&
            <li>
              <Link to={userLink}>
                <FormattedMessage
                  id="header.myDetails"
                  defaultMessage="My Details"
                />
              </Link>
            </li>}
            {userInGroups(props.currentUser, [
              USER_GROUPS.SECRETARY, USER_GROUPS.ADMINISTRATOR, USER_GROUPS.SECRETARY_ASSISTANT,
            ]) &&
            <li>
              <Link to={secretaryLink}>
                <FormattedMessage
                  id="header.secretaryView"
                  defaultMessage="Secretary view"
                />
              </Link>
            </li>}
            {!tokenUser &&
            <MenuItem divider />}
            <li>
              <button
                type="button"
                className="log-out-link"
                onClick={props.logout}
              >
                <FormattedMessage
                  id="header.logOut"
                  defaultMessage="Log out"
                />
              </button>
            </li>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    authControls = (
      <div className={styles.userNav}>
        <RegisterLink isButton />
        <LoginButton />
      </div>
    );
  }

  let menuOpenClass = '';
  if (props.menu.mobileMenuOpened) {
    menuOpenClass += styles.mobileMenuOpen;
  }

  return (
    <header id="main-header">
      <div className={styles.topNav}>
        <div className={styles.leftActions}>
          <MobileMenuButton />
          {languageDropdown}
        </div>
        <div className={styles.headerLogo}>
          <Link to="/">
            <img src={LogoWhite} alt="FISE" />
          </Link>
        </div>
        <div className={styles.rightActions}>
          {authControls}
        </div>
      </div>
      {!props.warningHeader.closed && props.warningHeader.warningHeaderText &&
        <div className={styles.warningHeader}>
          <div />
          <strong><DangerousDiv innerHTML={props.warningHeader.warningHeaderText} key="" className="" /></strong>
          <button
            type="button"
            className="close"
            onClick={() => props.closeWarningHeader(props.warningHeader.warningHeaderText)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      }
      <div className={styles.bottomNav}>
        <nav className={styles.bottomNavPageLinks}>
          { navPageLinks }
        </nav>
      </div>
      <div className={`${styles.mobileNav} ${menuOpenClass}`}>
        <div className={styles.mobileNavActions}>
          {languageDropdown}
        </div>
        {navPageLinks}
      </div>
      {accountControlModals}
    </header>
  );
};

Header.defaultProps = {
  currentUser: {},
  showRegisterNewUserModal: false,
  showPasswordResetModal: false,
};

Header.propTypes = {
  currentUser: UserShape,
  logout: PropTypes.func.isRequired,
  menu: PropTypes.shape({
    mobileMenuOpened: PropTypes.bool.isRequired,
    activeMenu: PropTypes.string.isRequired,
    infoMenu: InfoMenu.null,
  }).isRequired,
  warningHeader: PropTypes.shape({
    closed: PropTypes.bool.isRequired,
    warningHeaderText: PropTypes.string,
  }).isRequired,
  hideMobileMenu: PropTypes.func.isRequired,
  getInfoMenu: PropTypes.func.isRequired,
  getHeaderWarning: PropTypes.func.isRequired,
  localeSelected: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  auth: AuthShape.isRequired,
  register: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  hidePasswordResetModal: PropTypes.func.isRequired,
  hideRegisterNewUserModal: PropTypes.func.isRequired,
  showRegisterNewUserModal: PropTypes.bool,
  showPasswordResetModal: PropTypes.bool,
  closeWarningHeader: PropTypes.func.isRequired,
};

export default Header;
