import { showModal } from './modal';
import store from '../store';
import { logout } from './auth';

const logoutRedirect = () => {
  store.dispatch(logout());
  store.dispatch(showModal('logged_out'));
};

export default logoutRedirect;
