import axios from '../api/axios';
import * as types from '../constants/applications/actionTypes';
import * as clipboardTypes from '../constants/clipboard/actionTypes';
import UI_DOMAIN from '../constants/domain';
import { formFilesToBase64 } from '../utils/forms';
import { downloadBlob } from '../utils/download';


export const getApplication = applicationId => (dispatch) => {
  dispatch({
    type: types.GET_APPLICATION_PENDING,
    payload: applicationId,
  });

  return axios.get(`/applications/${applicationId}`)
    .then(
      response => dispatch({
        type: types.GET_APPLICATION_FULFILLED,
        payload: response,
      }),
      error => dispatch({
        type: types.GET_APPLICATION_REJECTED,
        payload: error,
      }),
    );
};

export const updateApplication = (id, data) => dispatch => dispatch({
  type: types.UPDATE_APPLICATION,
  payload: axios.patch(`/applications/${id}`, data),
});

export const removeApplication = (user, id) => (dispatch) => {
  const payload = {
    id,
    userId: user.id,
  };

  dispatch({
    type: types.REMOVE_APPLICATION_PENDING,
    payload,
  });

  return axios.delete(`/applications/${id}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_APPLICATION_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_APPLICATION_REJECTED,
          payload: error,
        });
      },
    );
};

export const createApplicationComment = (data, applicationId) => dispatch => (
  formFilesToBase64(data, ['name']).then(uploadableData => (
    dispatch({
      type: types.CREATE_APPLICATION_COMMENT,
      payload: axios.post(`/applications/${applicationId}/comments`, uploadableData),
    })
  ))
);

export const updateApplicationComment = (data, applicationId, commentId) => dispatch => dispatch({
  type: types.UPDATE_APPLICATION_COMMENT,
  payload: axios.patch(`/applications/${applicationId}/comments/${commentId}`, data),
});

export const removeApplicationComment = (applicationId, commentId) => (dispatch) => {
  const payload = {
    id: commentId,
    applicationId,
  };

  dispatch({
    type: types.REMOVE_APPLICATION_COMMENT_PENDING,
    payload,
  });

  return axios.delete(`/applications/${applicationId}/comments/${commentId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_APPLICATION_COMMENT_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_APPLICATION_COMMENT_REJECTED,
          payload: error,
        });
      },
    );
};


export const createApplicationNote = (data, applicationId) => dispatch => dispatch({
  type: types.CREATE_APPLICATION_NOTE,
  payload: axios.post(`/applications/${applicationId}/notes`, data),
});

export const updateApplicationNote = (data, applicationId, noteId) => dispatch => dispatch({
  type: types.UPDATE_APPLICATION_NOTE,
  payload: axios.patch(`/applications/${applicationId}/notes/${noteId}`, data),
});

export const removeApplicationNote = (applicationId, noteId) => (dispatch) => {
  const payload = {
    id: noteId,
    applicationId,
  };

  dispatch({
    type: types.REMOVE_APPLICATION_NOTE_PENDING,
    payload,
  });

  return axios.delete(`/applications/${applicationId}/notes/${noteId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_APPLICATION_NOTE_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_APPLICATION_NOTE_REJECTED,
          payload: error,
        });
      },
    );
};

export const submitApplicationStateChange = (data, applicationId) => dispatch => formFilesToBase64(data, ['name'])
  .then(uploadableData => dispatch({
    type: types.CREATE_APPLICATION_STATE,
    payload: axios.post(`/applications/${applicationId}/states`, uploadableData),
  }));


export const getApplicationLink = (applicationId, clipboardId) => (dispatch) => {
  const name = clipboardId || `application_link_${applicationId}`;

  dispatch({
    type: clipboardTypes.CLIPBOARD_DATA_PENDING,
    payload: {
      name,
    },
  });

  return axios.get(`/applications/${applicationId}/token`)
    .then(
      (response) => {
        const permToken = response.data.token;
        // eslint-disable-next-line max-len
        const link = `${window.location.protocol}//${UI_DOMAIN}/dashboard/applications/${applicationId}?perm_token=${permToken}`;
        dispatch({
          type: clipboardTypes.CLIPBOARD_DATA_FULFILLED,
          payload: {
            data: link,
            name,
          },
        });
      },
      (error) => {
        dispatch({
          type: clipboardTypes.CLIPBOARD_DATA_REJECTED,
          payload: {
            errors: error.data,
            name,
          },
        });
      },
    );
};

export const openApplicationDraft = (application, structureId) => ({
  type: types.OPEN_APPLICATION_DRAFT,
  payload: {
    application,
    structureId,
  },
});


export const downloadCertificate = certificateId => (dispatch) => {
  axios({
    method: 'get',
    url: `/applications/${certificateId}/certificate_export`,
    responseType: 'blob',
  }).then((response) => {
    // Get the filename from the content-disposition header
    const contentDisposition = response.request.getResponseHeader('Content-Disposition');
    const filename = contentDisposition.match(/filename="(.+)"/)[1];
    downloadBlob(response.data, filename);
    dispatch({
      type: 'CERTIFICATE_DOWNLOAD_FULFILLED',
      payload: filename,
    });
  });
};

export const makeCopyOfApplication = id => dispatch => dispatch({
  type: types.MAKE_COPY_OF_APPLICATION,
  payload: axios.post(`/applications/${id}/copy_to_draft`).then((response) => {
    dispatch(openApplicationDraft(response.data, response.data.application_structure));
  }),
});
