import { get, isEmpty, cloneDeep } from 'lodash';

export const fetchAllPages = (actionFunction, parameters, completeFunction = null) => (dispatch) => {
  const params = isEmpty(parameters) ? {} : parameters;

  return dispatch(actionFunction(params)).then((response) => {
    const hasNext = !isEmpty(get(response, 'value.pagination.next'));
    if (!hasNext) {
      if (completeFunction) {
        dispatch(completeFunction());
      }
      return response;
    }

    const newParams = cloneDeep(params);
    const currentPage = get(params, 'page', 1);
    newParams.page = currentPage + 1;
    return dispatch(fetchAllPages(actionFunction, newParams, completeFunction));
  });
};

export default fetchAllPages;
