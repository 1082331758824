import { cloneDeep, intersection, isEmpty } from 'lodash';
import { SubmissionError } from 'redux-form';

export const filterFields = (data, accepted) => {
  const result = {};
  Object.keys(data).forEach((key) => {
    if (accepted.indexOf(key) > -1) {
      result[key] = data[key];
    }
  });

  return result;
};


export const sanitizeObjForFormData = formData =>
/*
   * Set all string null values to undefined as react-jsonschema-form
   * does not like "null" values.
   *
   * Note that we can _not_ set the to "" (empty string) as this would
   * cause problems when submitting the form as it would be seen as a
   * proper value and not null by the backend.
   */

  Object.keys(formData).reduce((newObj, key) => {
    if (formData[key] === null) {
      return {
        ...newObj,
        [key]: undefined,
      };
    }
    return {
      ...newObj,
      [key]: formData[key],
    };
  }, {});

export const filterFormData = (formObject, extraFields = []) => {
  /*
   * Remove any items from the formObject that does not exist in the
   * form schema. This is so that we do not post more data than we
   * actually have in the form. If there are fields that should be
   * posted, but that are not in the schema, then those can be
   * added to the extraFields array parameter.
   */

  if (!formObject || !formObject.schema) {
    return {};
  }
  let formFields = Object.keys(formObject.schema.properties);
  formFields = formFields.concat(extraFields);
  return filterFields(formObject.formData, formFields);
};

export const fileToBase64 = (file, includeMetaFields) => {
  const VALID_META_FIELD = [
    'name',
    'size',
  ];

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      let result = event.target.result;
      if (!isEmpty(includeMetaFields)) {
        const filterOnFields = intersection(includeMetaFields, VALID_META_FIELD);
        result = {
          file: {
            data: result,
            meta: {},
          },
        };
        filterOnFields.forEach((field) => {
          result.file.meta[field] = file[field];
        });
      }
      resolve(result);
    };

    reader.onerror = () => reject(this);
    reader.readAsDataURL(file);
  });
};

/**
 * Take form (redux-form) data and change File objects to Base64
 *
 * The files in the form data can either be an array of files or
 * a single file. The same structure (single object or array)
 * that is passed will be returned.
 *
 * As creating base64 values from File/Blob objects requires
 * us to use a callback from the FileReader, we can't just
 * ask for the base64 value and get it instantly returned,
 * but we need to pass a callback and wait for the value.
 * This function abstracts away the callback and uses Promises
 * to be able to handle several files at a time.
 *
 * How it works:
 * 1. The function works by first checking for object values
 * that include either a single File or an array of Files.
 *
 * 2. A Promise is added for each field to an array.
 * The Promises will resolve into an object with two key/value pairs,
 * the first one (`key`) will be the field name, the second one
 * `data` will be either a Base64 value or an array of Base64 values,
 * depending on the input.
 *
 * 3. The return value of the function is then a promise that wraps
 * the promise array and resolves them. It will then return the
 * form data object with the field containing Files changed to the
 * Base64 values of said files.
 *
 *
 * @param formData
 * @param includeMetaFields
 * @returns {Promise}
 */
export const formFilesToBase64 = (formData, includeMetaFields) => {
  // Copy of the form data
  const promisedFormData = { ...formData };

  let promise;
  const fieldPromises = [];
  // Iterate over the form fields, looking for files
  Object.keys(formData).forEach((key) => {
    // Handle single files
    if (formData[key] instanceof File) {
      promise = fileToBase64(formData[key], includeMetaFields);
      const singleValuePromise = new Promise((resolve, reject) => {
        promise
          .then((promisedFieldData) => {
            resolve({ key, data: promisedFieldData });
          })
          .catch((error) => {
            reject(error);
          });
      });
      fieldPromises.push(singleValuePromise);
      return;
    }

    // Handle array of files
    const isNonEmptyArray = Array.isArray(formData[key]) && !isEmpty(formData[key]);
    if (isNonEmptyArray && formData[key][0] instanceof File) {
      const innerArrayValuePromises = [];
      formData[key].forEach((fieldArrayFile) => {
        promise = fileToBase64(fieldArrayFile, includeMetaFields);
        innerArrayValuePromises.push(promise);

        const arrayValuePromise = new Promise((resolve, reject) => {
          Promise.all(innerArrayValuePromises)
            .then((promisedFieldData) => {
              resolve({ key, data: promisedFieldData });
            })
            .catch((error) => {
              reject(error);
            });
        });
        fieldPromises.push(arrayValuePromise);
      });
    }
  });

  return new Promise((resolve, reject) => {
    Promise.all(fieldPromises)
      .then((innerResults) => {
        innerResults.forEach((fieldResult) => {
          promisedFormData[fieldResult.key] = fieldResult.data;
        });
        resolve(promisedFormData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const filterSentAttachments = (formData, attachmentKey) => {
  if (!formData || !attachmentKey || isEmpty(formData[attachmentKey])) {
    return formData;
  }
  const attachments = [...formData[attachmentKey].filter(
    attachment => attachment instanceof File,
  )];

  return {
    ...formData,
    attachments,
  };
};

export const prepareErrors = (errors, globalFieldErrors = false) => {
  /**
   * Change non fields errors to be redux-form friendly
   *
   * As Redux-Form uses _error as its object key for form wide errors
   * and this can't be changed in any configuration, we change the
   * response from the server from non_field_errors to just _error.
   *
   * Note: We need to silence the linter here as the parameter must
   * start with an underscore.
   */
  const preparedErrors = cloneDeep(errors);
  const hasNonFieldErrors = 'non_field_errors' in preparedErrors;

  const errorFields = Object.keys(preparedErrors);
  // eslint-disable-next-line no-underscore-dangle
  preparedErrors._error = [];

  if (globalFieldErrors && !hasNonFieldErrors && !isEmpty(errorFields)) {
    errorFields.forEach((errorField) => {
      // eslint-disable-next-line no-underscore-dangle
      preparedErrors._error.push([`${errorField}: ${preparedErrors[errorField]}`]);
    });
  }

  if (hasNonFieldErrors) {
    // eslint-disable-next-line no-underscore-dangle
    preparedErrors._error = preparedErrors._error.concat(preparedErrors.non_field_errors);
    delete preparedErrors.non_field_errors;
  }

  return preparedErrors;
};

export const createFormErrors = (response, globalFieldErrors = false) => {
  let errors = {};
  if (response && response.status >= 400 && response.status < 500) {
    errors = prepareErrors(response.data, globalFieldErrors);
  }
  return new SubmissionError(errors);
};

export const raiseFormErrors = (response, globalFieldErrors = false) => {
  throw createFormErrors(response, globalFieldErrors);
};
