import { some, includes } from 'lodash';
import { push } from 'react-router-redux';

import getQueryStringValue from './urls';
import { fetchUser } from '../actions/user';
import { setAuthToken } from '../api/axios';
import { getJwtFromPermissionUuid } from '../actions/auth';
import * as authTypes from '../constants/auth/actionTypes';


const checkJWTToken = async (store) => {
  /*
  The old system used to use token as queryString and had actual JWT as value. This
  is changed to use perm_token as queryString and has uuid of PermissionToken which
  gets exchanged for JWT.
  TODO: Remove the old token system once no longer needed.
  */
  const qsToken = getQueryStringValue('perm_token');
  const oldToken = getQueryStringValue('token');
  const locationIncludesResetPassword = includes(window.location.href, 'reset_password');

  if (oldToken && !locationIncludesResetPassword) {
    localStorage.setItem('JWT_TOKEN', oldToken);
  } else if (qsToken && !locationIncludesResetPassword) {
    try {
      const jwtFromPermissionToken = await getJwtFromPermissionUuid(qsToken);
      localStorage.setItem('JWT_TOKEN', jwtFromPermissionToken);
    } catch (error) {
      store.dispatch(push('/404'));
    }
  }

  const token = localStorage.getItem('JWT_TOKEN');

  if (token) {
    setAuthToken(token);
    store.dispatch({
      type: 'LOGIN_PENDING',
      payload: {},
    });
    store.dispatch(fetchUser('me'));
  } else {
    store.dispatch({
      type: authTypes.LOGIN_FULFILLED_NOT_LOGGED_IN,
      payload: {},
    });
  }
};

export const userInGroups = (user, groups) => {
  let checkGroups = groups;
  if (!Array.isArray(groups)) {
    checkGroups = [checkGroups];
  }

  const checkUser = user.user ? user.user : user;
  const userGroups = checkUser && checkUser.groups ? checkUser.groups : [];
  return some(checkGroups, group => userGroups.indexOf(group) > -1);
};

export default checkJWTToken;
