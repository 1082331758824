import { isEmpty } from 'lodash';
import { queryApplicationStructures } from '../../../../../actions/structures';
import * as types from '../constants/applicationsTable/actionTypes';


const getApplicationStructurePage = filtersParams => (dispatch) => {
  const filters = isEmpty(filtersParams) ? {} : filtersParams;

  // Only fetch published versions of an application
  filters.no_revisions = true;

  if (!('page' in filters)) {
    filters.page = 1;
  }

  const payload = {
    filters,
  };

  dispatch({
    type: types.QUERY_APPLICATIONSTRUCTURETABLE_PENDING,
  });

  dispatch(queryApplicationStructures(filters, true)).then((actionResponse) => {
    const response = actionResponse.value;
    payload.response = {
      ...response,
      data: response.data.map(item => item.id),
    };
    dispatch({
      type: types.QUERY_APPLICATIONSTRUCTURETABLE_FULFILLED,
      payload,
    });
  })
    .catch((err) => {
      payload.response = err;
      dispatch({
        type: types.QUERY_APPLICATIONSTRUCTURETABLE_REJECTED,
        payload,
      });
    });
};

export default getApplicationStructurePage;
