import * as attachmentTypes from '../constants/attachments/actionTypes';
import * as userTypes from '../constants/user/actionTypes';

const userReducer = (state = {}, action) => {
  const createNestedUserProperty = (userId, type, data) => ({
    ...state,
    [userId]: {
      ...state[userId],
      user: {
        ...state[userId].user,
        [type]: [
          ...state[userId].user[type].slice(0),
          data,
        ],
      },
    },
  });

  const updateNestedUserProperty = (userId, type, data) => ({
    ...state,
    [userId]: {
      ...state[userId],
      user: {
        ...state[userId].user,
        [type]: [
          ...state[userId].user[type].map(
            item => (
              item.id !== data.id ? item : data
            ),
          ),
        ],
      },
    },
  });

  const removeNestedUserProperty = (userId, type, objectId) => ({
    ...state,
    [userId]: {
      ...state[userId],
      user: {
        ...state[userId].user,
        [type]: [
          ...state[userId].user[type].filter(
            item => item.id !== objectId,
          ),
        ],
      },
    },
  });

  const addNestedUserModelAttachment = (userId, type, parentId, file) => ({
    ...state,
    [userId]: {
      ...state[userId],
      user: {
        ...state[userId].user,
        [type]: [
          ...state[userId].user[type].map(
            item => (
              item.id !== parentId ? item : {
                ...item,
                attachments: [...item.attachments, file],
              }
            ),
          ),
        ],
      },
    },
  });

  const removeNestedUserModelAttachment = (userId, type, parentId, fileId) => ({
    ...state,
    [userId]: {
      ...state[userId],
      user: {
        ...state[userId].user,
        [type]: [
          ...state[userId].user[type].map(
            item => (
              item.id !== parentId ? item : {
                ...item,
                attachments: [
                  ...item.attachments.filter(
                    attachment => attachment.id !== fileId,
                  ),
                ],
              }
            ),
          ),
        ],
      },
    },
  });

  let userId;
  let workHistoryId;
  let educationId;
  let courseId;
  let trainingId;
  let examId;
  let referenceId;
  let statementId;
  let statementAuthorId;
  let type;
  let parentId;
  let fileId;
  let file;
  let attributeId;
  switch (action.type) {
  case 'USER_PENDING':
    userId = action.payload.id;
    if (userId === 'me') {
      return state;
    }

    return {
      ...state,
      [userId]: {
        ...state[userId],
        isFetching: true,
      },
    };
  case 'USER_FULFILLED':
    userId = action.payload.data.id;
    return {
      ...state,
      [userId]: {
        ...state[userId],
        user: action.payload.data,
        isFetching: false,
      },
    };
  case userTypes.QUERY_USERS_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: {
          user: obj,
          isFetching: false,
        },
      }), {}),
    };
  case 'USER_WORKHISTORY_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'work_items', action.payload.data);

  case 'USER_WORKHISTORY_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'work_items', action.payload.data);

  case 'USER_WORKHISTORY_REMOVE_FULFILLED':
    userId = action.payload.userId;
    workHistoryId = action.payload.workHistoryId;
    return removeNestedUserProperty(userId, 'work_items', workHistoryId);

  case 'USER_EDUCATIONS_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'education_items', action.payload.data);

  case 'USER_EDUCATIONS_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'education_items', action.payload.data);

  case 'USER_EDUCATIONS_REMOVE_FULFILLED':
    userId = action.payload.userId;
    educationId = action.payload.educationId;
    return removeNestedUserProperty(userId, 'education_items', educationId);

  case 'USER_COURSES_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'course_items', action.payload.data);

  case 'USER_COURSES_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'course_items', action.payload.data);

  case 'USER_COURSES_REMOVE_FULFILLED':
    userId = action.payload.userId;
    courseId = action.payload.courseId;
    return removeNestedUserProperty(userId, 'course_items', courseId);

  case 'USER_TRAININGS_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'training_items', action.payload.data);

  case 'USER_TRAININGS_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'training_items', action.payload.data);

  case 'USER_TRAININGS_REMOVE_FULFILLED':
    userId = action.payload.userId;
    trainingId = action.payload.trainingId;
    return removeNestedUserProperty(userId, 'training_items', trainingId);

  case 'USER_STATEMENTS_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'statement_items', action.payload.data);

  case 'USER_STATEMENTS_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'statement_items', action.payload.data);

  case 'USER_STATEMENTS_REMOVE_FULFILLED':
    userId = action.payload.userId;
    statementId = action.payload.trainingId;
    return removeNestedUserProperty(userId, 'statement_items', statementId);

  case 'USER_STATEMENTSAUTHOR_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'statement_author_items', action.payload.data);

  case 'USER_STATEMENTSAUTHOR_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'statement_author_items', action.payload.data);

  case 'USER_STATEMENTSAUTHOR_REMOVE_FULFILLED':
    userId = action.payload.userId;
    statementAuthorId = action.payload.trainingId;
    return removeNestedUserProperty(userId, 'statement_author_items', statementAuthorId);

  case 'USER_CPDCOURSES_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'cpd_course_items', action.payload.data);

  case 'USER_CPDCOURSES_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'cpd_course_items', action.payload.data);

  case 'USER_CPDCOURSES_REMOVE_FULFILLED':
    userId = action.payload.userId;
    courseId = action.payload.courseId;
    return removeNestedUserProperty(userId, 'cpd_course_items', courseId);

  case 'USER_EXAMS_FULFILLED':
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'fise_exam_items', action.payload.data);

  case 'USER_EXAMS_UPDATE_FULFILLED':
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'fise_exam_items', action.payload.data);

  case 'USER_EXAMS_REMOVE_FULFILLED':
    userId = action.payload.userId;
    examId = action.payload.examId;
    return removeNestedUserProperty(userId, 'fise_exam_items', examId);

  case userTypes.USER_REFERENCE_CREATE_FULFILLED:
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'reference_items', action.payload.data);

  case userTypes.USER_REFERENCE_UPDATE_FULFILLED:
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'reference_items', action.payload.data);

  case userTypes.USER_REFERENCE_DESTROY_FULFILLED:
    userId = action.payload.userId;
    referenceId = action.payload.referenceId;
    return removeNestedUserProperty(userId, 'reference_items', referenceId);

  case attachmentTypes.USER_ATTACHMENT_ADD_FULFILLED:
    userId = action.payload.data.owner;
    type = action.payload.model;
    parentId = action.payload.data.parent_id;
    file = action.payload.data;

    return addNestedUserModelAttachment(userId, type, parentId, file);

  case attachmentTypes.USER_ATTACHMENT_REMOVE_FULFILLED:
    userId = action.payload.userId;
    type = action.payload.model;
    parentId = action.payload.parentId;
    fileId = action.payload.fileId;
    return removeNestedUserModelAttachment(userId, type, parentId, fileId);

  case userTypes.USER_ATTRIBUTE_CREATE_FULFILLED:
    userId = action.payload.data.user;
    return createNestedUserProperty(userId, 'attribute_items', action.payload.data);

  case userTypes.USER_ATTRIBUTE_UPDATE_FULFILLED:
    userId = action.payload.data.user;
    return updateNestedUserProperty(userId, 'attribute_items', action.payload.data);

  case userTypes.USER_ATTRIBUTE_REMOVE_FULFILLED:
    userId = action.payload.userId;
    attributeId = action.payload.attributeId;
    return removeNestedUserProperty(userId, 'attribute_items', attributeId);

  default:
    return state;
  }
};

export default userReducer;
