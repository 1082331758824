import * as types from '../constants/actionTypes';

export const sendApplication = form => ({
  type: types.SEND_APPLICATION,
  form,
});

export const sendApplicationDraft = form => ({
  type: types.SEND_APPLICATION_DRAFT,
  form,
});

export const changePage = page => ({
  type: types.CHANGE_WIZARD_PAGE,
  page,
});

export const setWizardQualification = qualification => ({
  type: types.SET_WIZARD_QUALIFICATION,
  qualification,
});

export const setWizardQualificationLevel = qualificationLevel => ({
  type: types.SET_WIZARD_QUALIFICATION_LEVEL,
  qualificationLevel,
});

export const setBillingSelection = choice => ({
  type: types.SET_BILLING_SELECTION,
  payload: choice,
});

export const setApplicationStructure = structure => ({
  type: types.SET_APPLICATION_STRUCTURE,
  payload: structure,
});

export const setImmutableApplication = application => ({
  type: types.SET_IMMUTABLE_APPLICATION,
  payload: application,
});

export const destroyApplicationWizard = () => ({
  type: types.DESTROY_APPLICATION_WIZARD,
  payload: null,
});
