/* eslint-disable react/no-children-prop, react/jsx-filename-extension */
/*
 * Normally all linting should be enable but the index.js is the has a
 * special function compared to most other files. We disable .jsx checks as
 * the root file of the project should be index.js to keep webpack happy and
 * we enable children properties as this adds code spitting advantages when
 * it comes to routes
 */
import { browserHistory, Router } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { IntlProvider } from 'react-intl';

import React from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import LoggedOutModal from './containers/Modals/LoggedOutModal';

import store from './store';
import checkJWTToken from './utils/auth';
import './styling/third-party/react-redux-toastr.min.css';
import '../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './styling/styles.global.less';

import createRoutes from './routes';
import messages from './i18n';
import CookieConsent from './components/CookieConsent/CookieConcent';
import { refreshTokenInBackground } from '../src/api/axios';

const routes = createRoutes(store);

const history = syncHistoryWithStore(browserHistory, store);

// Before proceeding to render anything first check the JWT token
checkJWTToken(store).then(() => renderApplication());

const renderApplication = () => {
  const currentLanguage = localStorage.getItem('currentLanguage') || 'fi';
  // fire refreshing the token in the background
  refreshTokenInBackground();
  const rootElement = document.getElementById('root');
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
        <div>
          <Router history={history} children={routes} />
          <ReduxToastr
            timeOut={3500}
            newestOnTop
            preventDuplicates
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
          <LoggedOutModal id="logged_out" />
          <CookieConsent />
        </div>
      </IntlProvider>
    </Provider>,
    rootElement,
  );
  /* eslint-enable react/no-children-prop, react/jsx-filename-extension */
};
