export const HOME_PAGE = 'home';
export const APPLY_FOR_QUALIFICATION = 'apply_for_qualification';
export const APPLICATION_WIZARD_PAGE = 'applicationwizard';
export const BROWSE_CERTIFICATES_PAGE = 'browsecertificates';
export const ABOUT_PAGE = 'about';
export const PROFILE_PAGE = 'profile';
export const DASHBOARD_PAGE = 'dashboard';
export const REGISTRY_PAGE = 'registry';
export const CMS_PAGE = 'cms';
export const EMAIL_VERIFICATION_SUCCESS_PAGE = 'email_verified';
export const EMAIL_ALREADY_VERIFIED_PAGE = 'already_verified';
export const CONTACT_US_PAGE = 'contact_us';
export const NEWS_PAGE = 'news';
