import { connect } from 'react-redux';

import ResendEmailVerificationButton from '../components/ResendEmailVerificationButton';
import { requestEmailVerification } from '../actions/auth';
import { hideModal } from '../actions/modal';
import templateToastr from '../utils/toastr';

const resend = data => (dispatch, getState) => {
  const email = data.email;
  const userId = getState().auth.currentUser;
  dispatch(hideModal('childModal_resendVerificationEmail'));

  return dispatch(requestEmailVerification(email, userId))
    .then(
      () => {
        templateToastr('success', 'sendEmailVerificationSuccess');
      },
      error => templateToastr('warning', 'defaultError'),
    );
};

const mapDispatchToProps = {
  resend,
};

const mapStateToProps = (state) => {
  let email = '';
  if (state.auth.currentUser && state.users[state.auth.currentUser]) {
    email = state.users[state.auth.currentUser].user.email;
  }
  return {
    email,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmailVerificationButton);

