import React from 'react';
import { FormattedMessage } from 'react-intl';
import axiosConfig from '../../constants/api/config';

const authenticate = () => {
  window.location.href = `${axiosConfig.baseUrl}/auth/request_password_reset`;
};

const ForgotPasswordForm = () => (
  <button onClick={authenticate} className="btn btn-success btn-rounded btn-block">
    <i className="fa fa-check fa-fw" />
    <FormattedMessage
      id="forgotPasswordForm.requestPasswordLink"
      defaultMessage="Proceed to e-identification"
    />
  </button>
);

export default ForgotPasswordForm;
