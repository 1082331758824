import React, { useState } from 'react';
import { Field, propTypes as reduxFormPropTypes, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Input from '../FormFields/Input';
import Form from '../Form/Form';
import Checkbox from '../FormFields/Checkbox';
import TermsOfServiceModal from '../Modals/TermsOfServiceModal';
import PrivacyPolicy from '../Modals/PrivacyPolicy';

const RegistrationForm = (props) => {
  const [acceptedToc, setAcceptedToc] = useState(false);
  const [acceptedPp, setAcceptedPp] = useState(false);

  return (
    <Form {...props} >
      <div style={{ marginBottom: '2rem' }}>
        <FormattedMessage
          id="registrationForm.extraText"
          defaultMessage="Create a new user account"
        />
      </div>
      <div className="form-group required">
        <label htmlFor="email" className="control-label">
          <FormattedMessage
            id="registrationForm.email"
            defaultMessage="EMAIL (USERNAME)"
          />
        </label>
        <div>
          <Field
            name="email"
            component={Input}
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group required">
        <label htmlFor="password" className="control-label">
          <FormattedMessage
            id="registrationForm.password"
            defaultMessage="Password"
          />
        </label>
        <div>
          <Field
            name="password"
            component={Input}
            type="password"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <Checkbox required={true}>
          <Field
            id="toc_agree"
            name="toc_agree"
            component="input"
            type="checkbox"
            onChange={(event, newValue) => setAcceptedToc(newValue)}
          />
          <label htmlFor="toc_agree">
            <FormattedMessage
              id="registrationForm.acceptTermsAndConditions"
              defaultMessage="I accept the"
            />
          </label>
          &nbsp;
          <TermsOfServiceModal
            id="registrationForm"
            replaceModal={false}
          />
        </Checkbox>
      </div>
      <div className="form-group">
        <Checkbox required={true}>
          <Field
            id="pp_agree"
            name="pp_agree"
            component="input"
            type="checkbox"
            onChange={(event, newValue) => setAcceptedPp(newValue)}
          />
          <label htmlFor="pp_agree">
            <FormattedMessage
              id="registrationForm.acceptPrivacyPolicy"
              defaultMessage="I accept the"
            />
          </label>
          &nbsp;
          <PrivacyPolicy
            id="registrationForm"
            replaceModal={false}
            pageId="registration-privacy-policy-page"
          />
        </Checkbox>
      </div>
      <hr />
      <p className="help-block">
        <FormattedMessage
          id="registrationForm.eid.helptext"
          defaultMessage="To complete your registration you will be redirected to authenticate with
          either your bank credentials or with a mobile certificate (mobile ID)."
        />
      </p>
      <button
        type="submit"
        className="btn btn-success btn-rounded btn-block"
        disabled={props.submitting || !acceptedToc || !acceptedPp}
      >
        <i className="fa fa-check fa-fw" />
        <FormattedMessage
          id="registrationForm.logIn"
          defaultMessage="Proceed to identification"
        />
      </button>
    </Form>
  );
};


RegistrationForm.propTypes = {
  ...reduxFormPropTypes,
};


export default reduxForm({
  form: 'loginForm',
})(RegistrationForm);
