import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../../components/Footer';
import Header from '../../containers/Header';

const CoreLayout = ({ children }) => (
  <div>
    <Header />
    <div className="content-wrapper">
      {children}
    </div>
    <Footer />
  </div>
);

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CoreLayout;
