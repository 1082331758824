import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes as reduxFormPropTypes, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Input from '../../components/FormFields/Input';
import Form from '../Form/Form';

const LoginForm = props => (
  <Form {...props}>
    <div className="form-group required">
      <label htmlFor="email" className="control-label">
        <FormattedMessage
          id="loginForm.email"
          defaultMessage="E-mail"
        />
      </label>
      <div>
        <FormattedMessage
          id="loginForm.emailPlaceholder"
          defaultMessage="Enter your email"
        >
          {
            message => (<Field
              name="email"
              component={Input}
              type="text"
              className="form-control"
              placeholder={message}
            />)
          }
        </FormattedMessage>
      </div>
    </div>
    <div className="form-group required">
      <label htmlFor="password" className="control-label">
        <FormattedMessage
          id="loginForm.password"
          defaultMessage="Password"
        />
      </label>
      <div>
        <FormattedMessage
          id="loginForm.passwordPlaceholder"
          defaultMessage="Enter your password"
        >
          { message => (<Field
            name="password"
            component={Input}
            type="password"
            className="form-control"
            placeholder={message}
          />)}
        </FormattedMessage>
      </div>
    </div>
    <button
      type="submit"
      className="btn btn-success btn-rounded btn-block"
      disabled={props.submitting}
    >
      <i className="fa fa-check fa-fw" />
      <FormattedMessage
        id="loginForm.logIn"
        defaultMessage="Log in"
      />
    </button>
  </Form>
);


LoginForm.propTypes = {
  ...reduxFormPropTypes,
  handleSubmit: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'loginForm',
})(LoginForm);
