import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';
import getApplicationStructurePage from './actions/applicationTable';
import {
  getApplicationStructure,
  queryApplicationStructureOptions,
  hasFetchedAllStructureOption,
  hasStartedFetchingStructureOptions,
} from '../../../../actions/structures';
import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { queryUsers } from '../../../../actions/user';
import { fetchAllPages } from '../../../../actions/utils';

export const ApplicationCreatorRoute = store => ({
  path: 'application-creator',
  authorize: {
    groups: [USER_GROUPS.ADMINISTRATOR],
  },
  getComponent(nextState, cb) {
    import('./containers/ApplicationCreatorRoute')
      .then(async (module) => {
        const applicationStructureTableReducer = await import('./reducers/applicationStructureTable');
        store.injectReducer({ key: 'applicationStructureTable', reducer: applicationStructureTableReducer.default });

        store.dispatch(getApplicationStructurePage());

        cb(null, module.default);
      });
  },
});

export const ApplicationCreatorDetailRoute = store => ({
  path: 'application-creator/:structureId',
  authorize: {
    groups: [USER_GROUPS.ADMINISTRATOR],
  },
  getComponent(nextState, cb) {
    import('./containers/ApplicationCreatorDetailRoute')
      .then(async (module) => {
        const structureId = nextState.params.structureId;
        const state = store.getState();
        if (structureId !== 'new' && isEmpty(state.structures[structureId])) {
          store.dispatch(getApplicationStructure(structureId))
            .catch(() => store.dispatch(push('/dashboard/application-creator')));
        }

        if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
          store.dispatch(hasStartedFetchingStructureOptions());
          store.dispatch(fetchAllPages(
            queryApplicationStructureOptions,
            {},
            hasFetchedAllStructureOption,
          ));
        }

        store.dispatch(queryUsers({ group: 'secretary' }));
        store.dispatch(queryUsers({ group: 'secretary_assistant' }));

        cb(null, module.default);
      });
  },
});
