export const showModal = (modalName, id, replace = true) => (dispatch) => {
  const modalId = id !== undefined ? `_${id}` : '';
  dispatch({
    type: 'SHOW_MODAL',
    payload: {
      name: `${modalName}${modalId}`,
      show: true,
      replace,
    },
  });
};

export const hideModal = (modalName, id) => (dispatch) => {
  const modalId = id !== undefined ? `_${id}` : '';
  dispatch({
    type: 'HIDE_MODAL',
    payload: {
      name: `${modalName}${modalId}`,
      show: false,
    },
  });
};


export const hideAllModals = () => (dispatch) => {
  dispatch({
    type: 'HIDE_MODAL',
    payload: {
      all: true,
    },
  });
};
