import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const CommonModal = props => (
  <Modal show={props.show} onHide={props.hide} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>
        {props.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {props.content}
    </Modal.Body>
  </Modal>
);

CommonModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

CommonModal.defaultProps = {
  show: false,
};

export default CommonModal;
