import axios from '../api/axios';


export const toggleMobileMenu = () => (dispatch) => {
  dispatch({
    type: 'TOGGLE_MOBILE_MENU',
    payload: {},
  });
};

export const hideMobileMenu = () => (dispatch) => {
  dispatch({
    type: 'HIDE_MOBILE_MENU',
    payload: {},
  });
};

export const setActivePage = page => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_PAGE',
    payload: page,
  });
};

export const getInfoMenu = () => (dispatch) => {
  axios.get('/cms/pages-menu/').then(
    (response) => {
      dispatch({
        type: 'SET_INFO_MENU',
        payload: response.data,
      });
    },
    (error) => {
      dispatch({
        type: 'SET_INFO_MENU',
        payload: [],
      });
    },
  );
};
