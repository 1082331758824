import { omit } from 'lodash';
import * as types from '../constants/structure/actionTypes';

const initialState = {
  fetchedAll: false,
  startedFetching: false,
  updateTime: null,
};
const structuresReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_APPLICATION_STRUCTURE_FULFILLED:
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };
  case types.CLEAR_OLD_APPLICATIONS:
    if (state.updateTime === null) {
      return {
        fetchedAll: false,
        startedFetching: false,
        updateTime: null,
      };
    }
    return state;
  case types.QUERY_APPLICATION_STRUCTURES_UPDATE_TIME_FULFILLED:
    if (state.updateTime === action.payload.data.time) {
      return state;
    } else if (state.startedFetching) {
      // Something else then the root page has started loading
      // meaning we can't override the loaded data
      return {
        ...state,
        updateTime: null,
      };
    }
    return {
      fetchedAll: false,
      startedFetching: false,
      updateTime: action.payload.data.time,
    };
  case types.QUERY_APPLICATION_STRUCTURES_OVERRIDE_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };
  case types.QUERY_APPLICATION_STRUCTURES_FULFILLED:
    return {
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
      ...state,
    };
  case types.FETCHED_ALL_APPLICATION_STRUCTURES:
    return {
      ...state,
      fetchedAll: true,
    };
  case types.STARTED_FETCHING_APPLICATION_STRUCTURES:
    return {
      ...state,
      startedFetching: true,
    };
  case types.REMOVE_APPLICATION_STRUCTURE_FULFILLED: {
    const id = action.payload.id;
    return {
      ...omit(state, id),
    };
  }
  case types.CREATE_APPLICATION_STRUCTURE_FULFILLED:
  case types.UPDATE_APPLICATION_STRUCTURE_FULFILLED: {
    const obj = action.payload.data;
    return {
      ...state,
      [obj.id]: obj,
    };
  }
  default:
    return state;
  }
};

export default structuresReducer;
