import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './style.less';
import RowSection from './RowSection';
import ImageRowSection from './ImageRow';
import DangerousDiv from '../../../../utils/DangerousDiv';


class BorderSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
    };
  }

  render() {
    return (
      <div className={styles.borderSection}>
        {this.state.section.title && <h3>{this.state.section.title}</h3>}
        {this.state.section.content.map((section) => {
          if (section.type === 'row') {
            return <RowSection section={section.value} key={section.id} />;
          } else if (section.type === 'text') {
            return <DangerousDiv innerHTML={section.value} key={section.id} className={styles.textRow} />;
          } else if (section.type === 'image') {
            return (
              <div key={section.id}>
                <img src={section.value.image} alt="Diagram" />
              </div>
            );
          } else if (section.type === 'image_row') {
            return <ImageRowSection section={section.value} key={section.id} />;
          }
          return null;
        })}
      </div>
    );
  }
}


BorderSection.propTypes = {
  section: PropTypes.shape({}),
};

BorderSection.defaultProps = {
  section: {},
};


export default BorderSection;
