import { isEmpty } from 'lodash';

import axios from '../api/axios';
import * as types from '../constants/userAttributeTypes/actionTypes';


export const getUserAttributeTypes = () => (dispatch, getStore) => {
  const attributeTypes = getStore().userAttributeTypes;
  if (isEmpty(attributeTypes)) {
    return dispatch({
      type: types.QUERY_USER_ATTRIBUTE_TYPES,
      payload: axios.get('/userattributetypes'),
    });
  }
  return null;
};

export const getUserAttributeType = typeId => dispatch => (
  dispatch({
    type: types.GET_USER_ATTRIBUTE_TYPE,
    payload: axios.get(`/userattributetypes/${typeId}`),
  })
);

export const removeUserAttributeType = typeId => (dispatch) => {
  const payload = {
    id: typeId,
  };

  dispatch({
    type: types.REMOVE_USER_ATTRIBUTE_TYPE_PENDING,
    payload,
  });

  return axios.delete(`/userattributetypes/${typeId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_USER_ATTRIBUTE_TYPE_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_USER_ATTRIBUTE_TYPE_REJECTED,
          payload: error,
        });
      },
    );
};

export const updateUserAttributeType = (typeId, data) => dispatch => (
  dispatch({
    type: types.UPDATE_USER_ATTRIBUTE_TYPE,
    payload: axios.patch(`/userattributetypes/${typeId}`, data),
  })
);

export const createUserAttributeType = data => dispatch => (
  dispatch({
    type: types.CREATE_USER_ATTRIBUTE_TYPE,
    payload: axios.post('/userattributetypes', data),
  })
);
