import { connect } from 'react-redux';

import ChildModal from '../components/ChildModal';
import { hideModal, showModal } from '../actions/modal';

const modalName = 'childModal';

const show = (id, replace = true) => (dispatch) => {
  dispatch(showModal(modalName, id, replace));
};

const hide = id => (dispatch) => {
  dispatch(hideModal(modalName, id));
};

const mapDispatchToProps = {
  show,
  hide,
};

const mapStateToProps = (state, ownProps) => ({
  showModal: state.modals[`${modalName}_${ownProps.id}`],
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  show: item => dispatchProps.show(item, ownProps.replace),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChildModal);

