export const QUERY_DEGREES = 'FETCH_DEGREES';
export const QUERY_DEGREES_PENDING = 'FETCH_DEGREES_PENDING';
export const QUERY_DEGREES_FULFILLED = 'FETCH_DEGREES_FULFILLED';
export const QUERY_DEGREES_REJECTED = 'FETCH_DEGREES_REJECTED';

export const GET_DEGREE = 'GET_DEGREE';
export const GET_DEGREE_PENDING = 'GET_DEGREE_PENDING';
export const GET_DEGREE_FULFILLED = 'GET_DEGREE_FULFILLED';
export const GET_DEGREE_REJECTED = 'GET_DEGREE_REJECTED';

export const CREATE_DEGREE = 'CREATE_DEGREE';
export const CREATE_DEGREE_PENDING = 'CREATE_DEGREE_PENDING';
export const CREATE_DEGREE_FULFILLED = 'CREATE_DEGREE_FULFILLED';
export const CREATE_DEGREE_REJECTED = 'CREATE_DEGREE_REJECTED';

export const UPDATE_DEGREE = 'UPDATE_DEGREE';
export const UPDATE_DEGREE_PENDING = 'UPDATE_DEGREE_PENDING';
export const UPDATE_DEGREE_FULFILLED = 'UPDATE_DEGREE_FULFILLED';
export const UPDATE_DEGREE_REJECTED = 'UPDATE_DEGREE_REJECTED';

export const REMOVE_DEGREE = 'REMOVE_DEGREE';
export const REMOVE_DEGREE_PENDING = 'REMOVE_DEGREE_PENDING';
export const REMOVE_DEGREE_FULFILLED = 'REMOVE_DEGREE_FULFILLED';
export const REMOVE_DEGREE_REJECTED = 'REMOVE_DEGREE_REJECTED';

export const QUERY_OPERATIONAL_AREAS = 'QUERY_OPERATIONAL_AREAS';
export const QUERY_OPERATIONAL_AREAS_PENDING = 'QUERY_OPERATIONAL_AREAS_PENDING';
export const QUERY_OPERATIONAL_AREAS_REJECTED = 'QUERY_OPERATIONAL_AREAS_REJECTED';
export const QUERY_OPERATIONAL_AREAS_FULFILLED = 'QUERY_OPERATIONAL_AREAS_FULFILLED';

export const SORT_DEGREES = 'SORT_DEGREES';
export const SORT_DEGREES_FULFILLED = 'SORT_DEGREES_FULFILLED';
