import * as types from '../constants/userGroups/actionTypes';

const initialState = [];

const userGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.QUERY_USER_GROUPS_FULFILLED:
    return [...action.payload.data];
  case types.UPDATE_USER_GROUPS_FULFILLED:
    return [...state];
  case types.UPDATE_UI_GROUP_LIST_ADD_FULFILLED:
    return [...state];
  case types.UPDATE_UI_GROUP_LIST_REMOVE_FULFILLED:
    return [...state];
  default:
    return state;
  }
};

export default userGroupsReducer;
