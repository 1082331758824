import { omit } from 'lodash';
import * as types from '../constants/structure/actionTypes';

const initialState = { fetchedAll: false, startedFetching: false, updateTime: null };
const structuresOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.SORT_APPLICATION_STRUCTURE_CATEGORY_FULFILLED:
  case types.QUERY_APPLICATION_STRUCTURE_OPTIONS_FULFILLED: {
    /**
     * Create a object with two levels, the first one corresponds
     * to the option type and the second
     * levels key is the id of the option object and its value is the
     * object itself.
     *
     * Example:
     * {
     *   "certification_type": {
     *     "60fdafa5-1ac1-4421-83ae-83e1c9790191": {...}
     *   }
     * }
     */
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.type]: {
          ...state[obj.type],
          ...accumulator[obj.type],
          [obj.id]: obj,
        },
      }), {}),
    };
  }
  case types.CLEAR_OLD_APPLICATIONS:
    if (state.updateTime === null) {
      return {
        fetchedAll: false,
        startedFetching: false,
        updateTime: null,
        testing: true,
      };
    }
    return state;
  case types.QUERY_APPLICATION_STRUCTURE_OPTIONS_UPDATE_TIME_FULFILLED:
    if (state.updateTime === action.payload.data.time) {
      return state;
    } else if (state.startedFetching) {
      // Something else then the root page has started loading
      // meaning we can override the loaded data
      return {
        ...state,
        updateTime: null,
      };
    }
    return {
      ...state,
      fetchedAll: false,
      startedFetching: false,
      updateTime: action.payload.data.time,
    };
  case types.FETCHED_ALL_STRUCTURE_OPTION:
    return {
      ...state,
      fetchedAll: true,
    };
  case types.STARTED_FETCHING_STRUCTURE_OPTION:
    return {
      ...state,
      startedFetching: true,
    };
  case types.REMOVE_APPLICATION_STRUCTURE_OPTION_FULFILLED: {
    const type = action.payload.type;
    const id = action.payload.id;
    return {
      ...state,
      [type]: omit(state[type], id),
    };
  }
  case types.CREATE_APPLICATION_STRUCTURE_OPTION_FULFILLED:
  case types.UPDATE_APPLICATION_STRUCTURE_OPTION_FULFILLED: {
    const obj = action.payload.data;
    return {
      ...state,
      [obj.type]: {
        ...state[obj.type],
        [obj.id]: obj,
      },
    };
  }
  default:
    return state;
  }
};

export default structuresOptionsReducer;
