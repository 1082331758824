import { connect } from 'react-redux';

import HomeView from '../components/HomeView';
import { downloadAttachmentFile } from '../../../actions/attachments';

const mapDispatchToProps = {
  downloadAttachmentFile,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
