import { getAllBillingInformation } from '../../../../actions/user';
import { setUserProfileTab } from '../../../../actions/userProfile';


export const BillingRoute = store => ({
  path: 'billing',
  getComponent(nextState, cb) {
    import('./containers/UserBillingRoute')
      .then(async (module) => {
        store.dispatch(setUserProfileTab('billing'));
        store.dispatch(getAllBillingInformation(nextState.params.id));
        cb(null, module.default);
      });
  },
});

export default BillingRoute;
