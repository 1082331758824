import { isEmpty } from 'lodash';

import axios from '../api/axios';
import * as types from '../constants/organizations/actionTypes';


export const getOrganizations = () => (dispatch, getStore) => {
  const organizations = getStore().organizations;
  if (isEmpty(organizations)) {
    return dispatch({
      type: types.QUERY_ORGANIZATIONS,
      payload: axios.get('/member_organizations'),
    });
  }
  return null;
};


export const removeOrganization = organizationId => (dispatch) => {
  const payload = {
    id: organizationId,
  };

  dispatch({
    type: types.REMOVE_ORGANIZATION_PENDING,
    payload,
  });

  return axios.delete(`/member_organizations/${organizationId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_ORGANIZATION_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_ORGANIZATION_REJECTED,
          payload: error,
        });
      },
    );
};

export const updateOrganization = (organizationId, data) => dispatch => (
  dispatch({
    type: types.UPDATE_ORGANIZATION,
    payload: axios.patch(`/member_organizations/${organizationId}`, data),
  })
);

export const createOrganization = data => dispatch => (
  dispatch({
    type: types.CREATE_ORGANIZATION,
    payload: axios.post('/member_organizations', data),
  })
);

export const sortOrganization = data => dispatch => (
  dispatch({
    type: types.SORT_ORGANIZATION,
    payload: axios.put('/member_organizations/sort', data),
  })
);
