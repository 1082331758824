import axios from '../api/axios';
import * as types from '../constants/statistics/actionTypes';

const getStatistics = () => (dispatch) => {
  dispatch({
    type: types.QUERY_STATISTICS_PENDING,
    payload: {},
  });

  return axios.get('/statistics')
    .then((response) => {
      dispatch({
        type: types.QUERY_STATISTICS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.QUERY_STATISTICS_REJECTED,
        payload: error.response,
      });
    });
};

export default getStatistics;
