import { isEmpty } from 'lodash';
import { push } from 'react-router-redux';
import * as USER_GROUPS from '../../../../constants/auth/userGroups';
import { queryBoards, getBoard } from './actions/boardTable';
import { getOrganizations } from '../../../../actions/organization';
import {
  queryApplicationStructureOptions,
  queryCurrentActiveApplicationStructures,
  hasFetchedAllApplicationStructures,
  hasFetchedAllStructureOption,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../../../actions/structures';
import { fetchAllPages } from '../../../../actions/utils';

export const BoardsRoute = store => ({
  path: 'boards',
  authorize: {
    groups: [
      USER_GROUPS.BOARD_MEMBER,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.ADMINISTRATOR,
      USER_GROUPS.SECRETARY_ASSISTANT,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/BoardsTable/BoardTable')
      .then(async (module) => {
        const boardsReducer = await import('../../../../reducers/boardsTable');
        store.injectReducer({ key: 'boards', reducer: boardsReducer.default });

        const state = store.getState();
        if (state.boards.dataTotalSize === 0) {
          store.dispatch(queryBoards());
        }
        cb(null, module.default);
      });
  },
});

export const BoardCreatorRoute = store => ({
  path: 'boards/:boardId',
  authorize: {
    groups: [
      USER_GROUPS.BOARD_MEMBER,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.SECRETARY_ASSISTANT,
      USER_GROUPS.ADMINISTRATOR,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/BoardsTable/BoardDetail')
      .then(async (module) => {
        const boardId = nextState.params.boardId;
        const state = store.getState();
        const boardManagementGroup = [USER_GROUPS.ADMINISTRATOR, USER_GROUPS.PERSONNEL];
        const userBelongsToMgmntGroup = state.auth.userGroups.some(group => boardManagementGroup.includes(group));

        const boardDetailReducer = await import('./reducers/boardDetail');
        store.injectReducer({ key: 'board', reducer: boardDetailReducer.default });

        if (boardId !== 'new') {
          store.dispatch(getBoard(boardId))
            .catch(() => store.dispatch(push('/dashboard/boards')));
        }

        if (userBelongsToMgmntGroup) {
          if (!state.structures.fetchedAll && !state.structures.startedFetching) {
            store.dispatch(hasStartedFetchingApplicationStructures());
            store.dispatch(fetchAllPages(
              queryCurrentActiveApplicationStructures,
              {},
              hasFetchedAllApplicationStructures,
            ));
          }
          if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
            store.dispatch(hasStartedFetchingStructureOptions());
            store.dispatch(fetchAllPages(
              queryApplicationStructureOptions,
              {},
              hasFetchedAllStructureOption,
            ));
          }
          if (isEmpty(state.organizations)) {
            store.dispatch(getOrganizations());
          }
        }
        cb(null, module.default);
      });
  },
});

export default BoardsRoute;
