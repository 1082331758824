import * as types from '../constants/feeds/actionTypes';

const initialValue = {};

const feedsReducer = (state = initialValue, action) => {
  let name;
  let model;
  let modelId;
  let cutoff;

  switch (action.type) {
  case types.INIT_FEED:
    name = action.payload.name;
    model = action.payload.model;
    modelId = action.payload.modelId;
    cutoff = action.payload.cutoff ? action.payload.cutoff : 10;

    if (!name || !model || !modelId) {
      return state;
    }

    return {
      ...state,
      [name]: {
        model,
        modelId,
        cutoff,
      },
    };
  case types.DESTROY_FEED:
    return Object.keys(state)
      .filter(key => key !== action.payload.name)
      .reduce((result, current) => {
        // eslint-disable-next-line no-param-reassign
        result[current] = state[current];
        return result;
      }, {});
  case types.CHANGE_FEED_CUTOFF:
    name = action.payload.name;
    cutoff = action.payload.cutoff;
    if (!state[name]) {
      return state;
    }

    return {
      ...state,
      [name]: {
        ...state[name],
        cutoff,
      },
    };
  default:
    return state;
  }
};

export default feedsReducer;
