export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGIN_FULFILLED_NOT_LOGGED_IN = 'LOGIN_FULFILLED_NOT_LOGGED_IN';

export const PASSWORD_RESET_REQUEST_PENDING = 'PASSWORD_RESET_REQUEST_PENDING';
export const PASSWORD_RESET_REQUEST_FULFILLED = 'PASSWORD_RESET_REQUEST_FULFILLED';
export const PASSWORD_RESET_REQUEST_REJECTED = 'PASSWORD_RESET_REQUEST_REJECTED';
export const PASSWORD_RESET_PENDING = 'PASSWORD_RESET_PENDING';
export const PASSWORD_RESET_FULFILLED = 'PASSWORD_RESET_FULFILLED';
export const PASSWORD_RESET_REJECTED = 'PASSWORD_RESET_REJECTED';

export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const REGISTER_NEW_USER_PENDING = 'REGISTER_NEW_USER_PENDING';
export const REGISTER_NEW_USER_FULFILLED = 'REGISTER_NEW_USER_FULFILLED';
export const REGISTER_NEW_USER_REJECTED = 'REGISTER_NEW_USER_REJECTED';

export const REQUEST_EMAIL_VERIFICATION_PENDING = 'REQUEST_EMAIL_VERIFICATION_PENDING';
