import { isEmpty } from 'lodash';

import axios from '../../../api/axios';
import * as types from '../constants/registry/actionTypes';
import { QUERY_USERS_FULFILLED } from '../../../constants/user/actionTypes';


export const toggleRegistryFilters = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_FILTERS,
    payload: {},
  });
};

export const searchUserCertifications = searchTerm => dispatch => (
  dispatch(queryUserCertifications(undefined, searchTerm))
);

export const filterUserCertifications = filters => dispatch => (
  dispatch(queryUserCertifications(filters))
);

export const sortUserCertifications = () => (dispatch, getState) => {
  const sortFiltersValues = getState().registry.selectedSortOptions.value;
  const filterValues = getState().registry.filters;
  const sortFilters = { ...filterValues, ordering: sortFiltersValues };
  dispatch(queryUserCertifications(sortFilters));
};

export const queryUserCertifications = (filtersParams, searchTerm) => (dispatch, getState) => {
  const registry = getState().registry;
  const existingSearch = registry.search;
  const existingFilters = registry.filters;

  const registryQueryFetching = {};
  registryQueryFetching.initialDataFetching = true;
  if (!isEmpty(filtersParams) || !(typeof searchTerm === 'undefined')) {
    registryQueryFetching.initialDataFetching = false;
  }

  const resetFilters = filtersParams === null;
  const resetSearch = searchTerm === null;

  let filters = {};
  if (!resetFilters) {
    filters = isEmpty(filtersParams) ? existingFilters : filtersParams;
  }

  let search = null;
  if (!resetSearch) {
    search = typeof searchTerm === 'undefined' ? existingSearch : { search: searchTerm };
  }

  filters.has_certifications = 1;
  filters.include_certifications = 1;

  if (!('page_size' in filters)) {
    filters.page_size = 5;
  }

  if (!('page' in filters)) {
    filters.page = 1;
  }
  const queryParams = { filters, search };

  const payload = {
    queryParams,
    ...registryQueryFetching,
  };

  dispatch({
    type: types.QUERY_USER_CERTIFICATIONS_PENDING,
    payload,
  });

  return axios.get('/users', { params: { ...queryParams.filters, ...queryParams.search } })
    .then((response) => {
      dispatch({
        type: QUERY_USERS_FULFILLED,
        payload: response,
      });

      // Due to the change in the backend filtering, the users per certification
      // is extracted. E.g. If a user have two matched certifications, the result
      // will contain two results with same userId, Therefore, filter the duplicate.
      const userIds = [...new Set(response.data.map(user => (user.id)))];

      payload.response = {
        ...response,
        data: userIds,
      };

      dispatch({
        type: types.QUERY_USER_CERTIFICATIONS_FULFILLED,
        payload,
      });
    })
    .catch((err) => {
      payload.response = err;
      dispatch({
        type: types.QUERY_USER_CERTIFICATIONS_REJECTED,
        payload,
      });
    });
};

export const clearRegistryFilters = () => (dispatch) => {
  dispatch(queryUserCertifications(null, null));
  dispatch({
    type: types.CLEAR_REGISTRY_FILTERS,
  });
};

export const changeRegistrySortOptions = selectedOption => (dispatch) => {
  dispatch({
    type: types.CHANGE_REGISTRY_SORT_OPTIONS,
    payload: selectedOption,
  });
  dispatch(sortUserCertifications());
};

export const createShareableLink = userId => (dispatch) => {
  dispatch({
    type: types.CREATE_SHAREABLE_LINK_FOR_USER,
    payload: userId,
  });
};
