const initialValue = {
  warningHeaderText: null,
  closed: false,
};

const warningHeaderReducer = (state = initialValue, action) => {
  switch (action.type) {
  case 'SET_HEADER_TEXT':
    return {
      ...state,
      warningHeaderText: action.payload,
    };
  case 'HIDE_HEADER_WARNING':
    return {
      ...state,
      closed: true,
    };
  case 'SHOW_HEADER_WARNING':
    return {
      ...state,
      closed: false,
    };
  default:
    return state;
  }
};

export default warningHeaderReducer;
