import { omit } from 'lodash';
import * as types from '../constants/userAttributeTypes/actionTypes';

const initialValue = {};

const userAttributeTypeReducer = (state = initialValue, action) => {
  switch (action.type) {
  case types.QUERY_USER_ATTRIBUTE_TYPES_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };
  case types.GET_USER_ATTRIBUTE_TYPE_FULFILLED:
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };
  case types.REMOVE_USER_ATTRIBUTE_TYPE_FULFILLED: {
    const id = action.payload.id;
    return {
      ...omit(state, id),
    };
  }
  case types.CREATE_USER_ATTRIBUTE_TYPE_FULFILLED:
  case types.UPDATE_USER_ATTRIBUTE_TYPE_FULFILLED: {
    const obj = action.payload.data;
    return {
      ...state,
      [obj.id]: obj,
    };
  }

  default:
    return state;
  }
};

export default userAttributeTypeReducer;
