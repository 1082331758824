import * as types from '../constants/auth/actionTypes';

const initialState = {
  completed: false,
  loggingIn: false,
  loggedIn: false,
  currentUser: '',
  userGroups: [],
  emailVerified: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
  case types.LOGIN_FULFILLED:
    return {
      completed: true,
      loggedIn: true,
      loggingIn: false,
      currentUser: action.payload.currentUser,
      userGroups: action.payload.userGroups,
      emailVerified: action.payload.emailVerified,
    };
  case types.LOGIN_FULFILLED_NOT_LOGGED_IN:
    return {
      ...initialState,
      completed: true,
    };
  case types.LOGIN_REJECTED:
    return {
      ...state,
      completed: true,
      loggingIn: false,
      loggedIn: false,
    };
  case types.LOGIN_PENDING:
    return {
      ...state,
      loggingIn: true,
      completed: false,
    };
  case types.LOGOUT_FULFILLED:
    return {
      ...initialState,
      completed: true,
    };
  case types.PASSWORD_RESET_REQUEST_PENDING:
    return {
      ...state,
      completed: false,
    };
  default:
    return state;
  }
};

export default authReducer;
