import { getAllBillingInformation } from '../../actions/user';
import { setActivePage } from '../../actions/menu';
import * as menuPages from '../../constants/menu/pages';
import requireAuth from '../../utils/routing';
import * as USER_GROUPS from '../../constants/auth/userGroups';
import {
  queryApplicationStructureOptions,
  queryCurrentActiveApplicationStructures,
  hasFetchedAllStructureOption,
  hasFetchedAllApplicationStructures,
  hasStartedFetchingApplicationStructures,
  hasStartedFetchingStructureOptions,
} from '../../actions/structures';
import { getDegrees } from '../../actions/degrees';
import { fetchAllPages } from '../../actions/utils';
import { fetchUserApplications } from '../Users/routes/Applications/actions/application';


export const ApplicationWizardRoute = store => ({
  path: 'applicationwizard',
  authorize: {
    groups: [
      USER_GROUPS.APPLICANT,
      USER_GROUPS.SECRETARY,
      USER_GROUPS.ADMINISTRATOR,
      USER_GROUPS.SECRETARY_ASSISTANT,
    ],
  },
  onEnter: requireAuth,
  getComponent(nextState, cb) {
    import('./containers/ApplicationWizardRoute').then(async (module) => {
      const state = store.getState();
      const qualificationsReducer = await import('./modules/qualifications');
      store.injectReducer({ key: 'qualifications', reducer: qualificationsReducer.default });

      const wizardReducer = await import('./reducers/wizard');
      store.injectReducer({ key: 'applicationWizard', reducer: wizardReducer.default });

      store.dispatch(fetchUserApplications(state.auth.currentUser));
      store.dispatch(getAllBillingInformation('me'));

      if (!state.structureOptions.fetchedAll && !state.structureOptions.startedFetching) {
        store.dispatch(hasStartedFetchingStructureOptions());
        store.dispatch(fetchAllPages(
          queryApplicationStructureOptions,
          {},
          hasFetchedAllStructureOption,
        ));
      }
      if (!state.structures.fetchedAll && !state.structures.startedFetching) {
        store.dispatch(hasStartedFetchingApplicationStructures());
        store.dispatch(fetchAllPages(
          queryCurrentActiveApplicationStructures,
          { stripped: false },
          hasFetchedAllApplicationStructures,
        ));
      }
      store.dispatch(getDegrees());

      store.dispatch(setActivePage(menuPages.APPLICATION_WIZARD_PAGE));

      cb(null, module.default);
    });
  },
});


export default ApplicationWizardRoute;
