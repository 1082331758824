import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ChildModal from '../../containers/ChildModal';
import { getRichTextPageData, PRIVACY_POLICY_PAGE_ID } from '../../utils/cms';

const PrivacyPolicy = (props) => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    const asyncGetPageData = async () => {
      const jsonPageData = await getRichTextPageData(props.pageId);
      if (jsonPageData != null) {
        setPageData(jsonPageData);
      }
    };
    asyncGetPageData();
  }, [props.pageId]);

  return (
    <ChildModal
      replace={props.replaceModal}
      title={<FormattedMessage
        id="privacyPolicy.modalTitle"
        defaultMessage="Privacy policy"
      />}
      id={`privacyPolicy_${props.id}`}
      modalSize="large"
      type="text-link"
      buttonText={<FormattedMessage
        id="privacyPolicy.modalOpenButtonText"
        defaultMessage="Privacy policy"
      />}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: pageData.body }} />
    </ChildModal>
  );
};

PrivacyPolicy.propTypes = {
  id: PropTypes.string.isRequired,
  replaceModal: PropTypes.bool,
  pageId: PropTypes.string,
};

PrivacyPolicy.defaultProps = {
  replaceModal: true,
  pageId: PRIVACY_POLICY_PAGE_ID,
};


export default PrivacyPolicy;
