import * as types from '../constants/degrees/actionTypes';

const initialValue = {};

const operationalAreaReducer = (state = initialValue, action) => {
  switch (action.type) {
  case types.QUERY_OPERATIONAL_AREAS_FULFILLED:
    return {
      ...state,
      ...action.payload.data.reduce((accumulator, obj) => ({
        ...accumulator,
        [obj.id]: obj,
      }), {}),
    };

  default:
    return state;
  }
};

export default operationalAreaReducer;
