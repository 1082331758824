import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { modelMessageMapping } from '../../messages/models';
import templateMessages from './templates';

const IntlToast = (props) => {
  const formatMessage = (message, values) => props.intl.formatMessage(message, values);

  const getTemplate = (template, model) => {
    let translatedModel = model;
    if (!isEmpty(model)) {
      translatedModel = modelMessageMapping[model];
    }

    translatedModel = translatedModel ? formatMessage(translatedModel) : translatedModel;

    let title = templateMessages[`${template}_title`];
    let message = templateMessages[`${template}_message`];
    const values = {
      model: translatedModel,
    };

    title = title ? formatMessage(title, values) : title;
    message = message ? formatMessage(message, values) : message;

    return {
      title,
      message,
    };
  };

  let title;
  let message;
  if (!isEmpty(props.template)) {
    const template = getTemplate(props.template, props.model);
    title = template.title;
    message = template.message;
  } else {
    if (props.title) {
      title = formatMessage(props.title);
    }
    if (props.message) {
      message = formatMessage(props.message);
    }
  }

  return (
    <div>
      {title &&
      <div className="title">{title}</div>}
      {message &&
      <div className="message">{message}</div>}
    </div>
  );
};

IntlToast.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
  template: PropTypes.string,
  model: PropTypes.string,
};

IntlToast.defaultProps = {
  template: '',
  model: 'item',
  title: null,
  message: null,
};

export default injectIntl(IntlToast);
