import PropTypes from 'prop-types';
import React, { Component } from 'react';

import styles from './style.less';
import DangerousDiv from '../../../../utils/DangerousDiv';


function getWindowWidth() {
  return Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  );
}

class ImageRowSection extends Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.state = {
      section: props.section,
      width: getWindowWidth(),
    };

    window.addEventListener('resize', this.onResize);
  }

  onResize() {
    window.requestAnimationFrame(() => {
      this.setState({
        width: getWindowWidth(),
      });
    });
  }

  render() {
    const contentComponents = [];
    contentComponents.push(
      <div className={styles.imageContainer}>
        <div className={styles.sideImage} style={{ backgroundImage: `url("${this.state.section.image}")` }} />
      </div>,
    );
    contentComponents.push(
      <div className={`${styles.text} ${!this.state.section.is_right_side_image ? styles.rightText : ''}`}>
        <DangerousDiv innerHTML={this.state.section.text} key={this.state.section.id} />
      </div>,
    );
    const ScreenXsMax = 849;
    const smallScreen = this.state.width < ScreenXsMax;
    const content = (
      this.state.section.is_right_side_image && !smallScreen ?
        contentComponents.reverse() : contentComponents
    );

    return (
      <div className={styles.imageRow}>
        {content}
      </div>
    );
  }
}


ImageRowSection.propTypes = {
  section: PropTypes.shape({}),
};

ImageRowSection.defaultProps = {
  section: {},
};

export default ImageRowSection;
