export const GET_FEED = 'GET_FEED';
export const GET_FEED_PENDING = 'GET_FEED_PENDING';
export const GET_FEED_FULFILLED = 'GET_FEED_FULFILLED';
export const GET_FEED_REJECTED = 'GET_FEED_REJECTED';

export const GET_FEED_DATA = 'GET_FEED_DATA';
export const GET_FEED_DATA_PENDING = 'GET_FEED_DATA_PENDING';
export const GET_FEED_DATA_FULFILLED = 'GET_FEED_DATA_FULFILLED';
export const GET_FEED_DATA_REJECTED = 'GET_FEED_DATA_REJECTED';

export const INIT_FEED = 'INIT_FEED';
export const DESTROY_FEED = 'DESTROY_FEED';
export const CHANGE_FEED_CUTOFF = 'CHANGE_FEED_CUTOFF';
