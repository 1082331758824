import AdminSettingsRoute from './routes/AdminSettings';
import { ApplicationsDetailRoute, ApplicationsRoute } from './routes/Applications';
import { ApplicationCreatorRoute, ApplicationCreatorDetailRoute } from './routes/ApplicationCreator';
import UsersRoute from './routes/Users';
import { BoardsRoute, BoardCreatorRoute } from './routes/Boards';
import { BoardMeetingsRoute, BoardMeetingDetailRoute } from './routes/BoardMeetings';
import StatisticsRoute from './routes/Statistics';
import { setActivePage } from '../../actions/menu';
import getStatistics from '../../actions/statistics';
import * as menuPages from '../../constants/menu/pages';
import * as USER_GROUPS from '../../constants/auth/userGroups';

export const DashboardRoute = store => ({
  path: 'dashboard',
  authorize: {
    groups: [
      USER_GROUPS.SECRETARY,
      USER_GROUPS.ADMINISTRATOR,
      USER_GROUPS.PERSONNEL,
      USER_GROUPS.BOARD_MEMBER,
      USER_GROUPS.SECRETARY_ASSISTANT,
    ],
  },
  getComponent(nextState, cb) {
    import('./containers/DashboardRoute').then(async (module) => {
      const statisticsReducer = await import('../../reducers/statistics');
      store.injectReducer({ key: 'statistics', reducer: statisticsReducer.default });
      store.dispatch(getStatistics());
      store.dispatch(setActivePage(menuPages.DASHBOARD_PAGE));
      cb(null, module.default);
    });
  },
  childRoutes: [
    ApplicationsRoute(store),
    ApplicationsDetailRoute(store),
    ApplicationCreatorRoute(store),
    ApplicationCreatorDetailRoute(store),
    AdminSettingsRoute(store),
    UsersRoute(store),
    BoardsRoute(store),
    BoardMeetingsRoute(store),
    BoardMeetingDetailRoute(store),
    BoardCreatorRoute(store),
    StatisticsRoute(store),
  ],
});

export default DashboardRoute;
