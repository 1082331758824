export const QUERY_APPLICATIONS_PENDING = 'QUERY_APPLICATIONS_PENDING';
export const QUERY_APPLICATIONS_FULFILLED = 'QUERY_APPLICATIONS_FULFILLED';
export const QUERY_APPLICATIONS_REJECTED = 'QUERY_APPLICATIONS_REJECTED';

export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_PENDING = 'GET_APPLICATION_PENDING';
export const GET_APPLICATION_FULFILLED = 'GET_APPLICATION_FULFILLED';
export const GET_APPLICATION_REJECTED = 'GET_APPLICATION_REJECTED';

export const CREATE_APPLICATION_COMMENT = 'CREATE_APPLICATION_COMMENT';
export const CREATE_APPLICATION_COMMENT_PENDING = 'CREATE_APPLICATION_COMMENT_PENDING';
export const CREATE_APPLICATION_COMMENT_FULFILLED = 'CREATE_APPLICATION_COMMENT_FULFILLED';
export const CREATE_APPLICATION_COMMENT_REJECTED = 'CREATE_APPLICATION_COMMENT_REJECTED';

export const UPDATE_APPLICATION_COMMENT = 'UPDATE_APPLICATION_COMMENT';
export const UPDATE_APPLICATION_COMMENT_PENDING = 'UPDATE_APPLICATION_COMMENT_PENDING';
export const UPDATE_APPLICATION_COMMENT_FULFILLED = 'UPDATE_APPLICATION_COMMENT_FULFILLED';
export const UPDATE_APPLICATION_COMMENT_REJECTED = 'UPDATE_APPLICATION_COMMENT_REJECTED';

export const REMOVE_APPLICATION_COMMENT = 'REMOVE_APPLICATION_COMMENT';
export const REMOVE_APPLICATION_COMMENT_PENDING = 'REMOVE_APPLICATION_COMMENT_PENDING';
export const REMOVE_APPLICATION_COMMENT_FULFILLED = 'REMOVE_APPLICATION_COMMENT_FULFILLED';
export const REMOVE_APPLICATION_COMMENT_REJECTED = 'REMOVE_APPLICATION_COMMENT_REJECTED';

export const CREATE_APPLICATION_NOTE = 'CREATE_APPLICATION_NOTE';
export const CREATE_APPLICATION_NOTE_PENDING = 'CREATE_APPLICATION_NOTE_PENDING';
export const CREATE_APPLICATION_NOTE_FULFILLED = 'CREATE_APPLICATION_NOTE_FULFILLED';
export const CREATE_APPLICATION_NOTE_REJECTED = 'CREATE_APPLICATION_NOTE_REJECTED';

export const UPDATE_APPLICATION_NOTE = 'UPDATE_APPLICATION_NOTE';
export const UPDATE_APPLICATION_NOTE_PENDING = 'UPDATE_APPLICATION_NOTE_PENDING';
export const UPDATE_APPLICATION_NOTE_FULFILLED = 'UPDATE_APPLICATION_NOTE_FULFILLED';
export const UPDATE_APPLICATION_NOTE_REJECTED = 'UPDATE_APPLICATION_NOTE_REJECTED';

export const REMOVE_APPLICATION_NOTE = 'REMOVE_APPLICATION_NOTE';
export const REMOVE_APPLICATION_NOTE_PENDING = 'REMOVE_APPLICATION_NOTE_PENDING';
export const REMOVE_APPLICATION_NOTE_FULFILLED = 'REMOVE_APPLICATION_NOTE_FULFILLED';
export const REMOVE_APPLICATION_NOTE_REJECTED = 'REMOVE_APPLICATION_NOTE_REJECTED';

export const CREATE_APPLICATION_STATE = 'CREATE_APPLICATION_STATE';
export const CREATE_APPLICATION_STATE_PENDING = 'CREATE_APPLICATION_STATE_PENDING';
export const CREATE_APPLICATION_STATE_FULFILLED = 'CREATE_APPLICATION_STATE_FULFILLED';
export const CREATE_APPLICATION_STATE_REJECTED = 'CREATE_APPLICATION_STATE_REJECTED';

export const OPEN_APPLICATION_DRAFT = 'OPEN_APPLICATION_DRAFT';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_PENDING = 'UPDATE_APPLICATION_PENDING';
export const UPDATE_APPLICATION_FULFILLED = 'UPDATE_APPLICATION_FULFILLED';
export const UPDATE_APPLICATION_REJECTED = 'UPDATE_APPLICATION_REJECTED';

export const REMOVE_APPLICATION = 'REMOVE_DEGREE';
export const REMOVE_APPLICATION_PENDING = 'REMOVE_APPLICATION_PENDING';
export const REMOVE_APPLICATION_FULFILLED = 'REMOVE_APPLICATION_FULFILLED';
export const REMOVE_APPLICATION_REJECTED = 'REMOVE_APPLICATION_REJECTED';

export const GET_APPLICATION_VOTE = 'GET_APPLICATION_VOTE';
export const GET_APPLICATION_VOTE_PENDING = 'GET_APPLICATION_VOTE_PENDING';
export const GET_APPLICATION_VOTE_FULFILLED = 'GET_APPLICATION_VOTE_FULFILLED';
export const GET_APPLICATION_VOTE_REJECTED = 'GET_APPLICATION_VOTE_REJECTED';

export const CREATE_APPLICATION_VOTE = 'CREATE_APPLICATION_VOTE';
export const CREATE_APPLICATION_VOTE_PENDING = 'CREATE_APPLICATION_VOTE_PENDING';
export const CREATE_APPLICATION_VOTE_FULFILLED = 'CREATE_APPLICATION_VOTE_FULFILLED';
export const CREATE_APPLICATION_VOTE_REJECTED = 'CREATE_APPLICATION_VOTE_REJECTED';

export const UPDATE_APPLICATION_VOTE = 'UPDATE_APPLICATION_VOTE';
export const UPDATE_APPLICATION_VOTE_PENDING = 'UPDATE_APPLICATION_VOTE_PENDING';
export const UPDATE_APPLICATION_VOTE_FULFILLED = 'UPDATE_APPLICATION_VOTE_FULFILLED';
export const UPDATE_APPLICATION_VOTE_REJECTED = 'UPDATE_APPLICATION_VOTE_REJECTED';

export const MAKE_COPY_OF_APPLICATION = 'MAKE_COPY_OF_APPLICATION';
