let baseUrl = '/api';

if (process.env.REACT_APP_BASE_URL) {
  baseUrl = process.env.REACT_APP_BASE_URL;
}

if (process.env.NODE_ENV === 'development') {
  if (!process.env.REACT_APP_BASE_URL) {
    baseUrl = 'http://fise:8000/api';
  }
}

const config = {
  baseUrl,
};

export default config;
