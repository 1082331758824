const ResetPasswordRoute = () => ({
  path: 'reset_password',
  getComponent(nextState, cb) {
    import('./ResetPasswordView')
      .then(async module => cb(null, module.default));
  },
});


export default ResetPasswordRoute;
