import { isEmpty } from 'lodash';

import axios from '../api/axios';
import * as types from '../constants/degrees/actionTypes';


export const getDegrees = () => (dispatch, getStore) => {
  const degrees = getStore().degrees;
  if (isEmpty(degrees)) {
    return dispatch({
      type: types.QUERY_DEGREES,
      payload: axios.get('/degrees'),
    });
  }
  return null;
};

export const getDegree = degreeId => dispatch => (
  dispatch({
    type: types.GET_DEGREE,
    payload: axios.get(`/degrees/${degreeId}`),
  })
);

export const removeDegree = degreeId => (dispatch) => {
  const payload = {
    id: degreeId,
  };

  dispatch({
    type: types.REMOVE_DEGREE_PENDING,
    payload,
  });

  return axios.delete(`/degrees/${degreeId}`)
    .then(
      (response) => {
        payload.response = response;
        dispatch({
          type: types.REMOVE_DEGREE_FULFILLED,
          payload,
        });
      },
      (error) => {
        dispatch({
          type: types.REMOVE_DEGREE_REJECTED,
          payload: error,
        });
      },
    );
};

export const updateDegree = (degreeId, data) => dispatch => (
  dispatch({
    type: types.UPDATE_DEGREE,
    payload: axios.patch(`/degrees/${degreeId}`, data),
  })
);

export const createDegree = data => dispatch => (
  dispatch({
    type: types.UPDATE_DEGREE,
    payload: axios.post('/degrees', data),
  })
);

export const sortDegrees = data => dispatch => (
  dispatch({
    type: types.SORT_DEGREES,
    payload: axios.put('/degrees/sort', data),
  })
);
