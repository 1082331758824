import { filter, uniqueId, get } from 'lodash';
import { arrayPush, change, formValueSelector } from 'redux-form';
import { all, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import axios from '../api/axios';
import store from '../store';
import { fileToBase64 } from '../utils/forms';
import { serverErrorToastr } from '../utils/toastr';

import * as types from '../constants/attachments/actionTypes';
import { showModal } from '../actions/modal';


// ------------------------------------
// Utils
// ------------------------------------
const modelAttachmentMap = {
  work_items: 'workhistory',
  education_items: 'educations',
  course_items: 'courses',
  fise_exam_items: 'exams',
  training_items: 'trainings',
  application: 'applications',
  cpd_course_items: 'cpdcourses',
  reference_items: 'references',
  comment: 'comments',
  statement_items: 'statements',
  statement_author_items: 'statementauthors',
  board_meetings: 'board_meetings',
  billing_personal: 'billing/personal',
};

const customFieldAttachmenttUrlMap = {
  work_attachments: 'work_attachments',
  education_attachments: 'education_attachments',
  signed_meeting_minutes: 'signed_meeting_minutes_attachments',
};

const constructURL = (action, fieldName) => {
  const { userId, model, parentId, applicationId } = action.payload;

  const modelUrlRoute = modelAttachmentMap[model];

  const customPostfix = get(customFieldAttachmenttUrlMap, fieldName, null);
  const attachmentPostfix = customPostfix || 'attachments';

  let url = `/${modelUrlRoute}/${parentId}/${attachmentPostfix}`;

  if (userId) {
    url = `/users/${userId}${url}`;
  }

  if (applicationId) {
    url = `/applications/${applicationId}${url}`;
  }

  return url;
};

const getActionTypes = (action) => {
  const { model } = action.payload;

  const actionTypes = {
    addFulfilled: null,
    addRejected: null,
    removeFulfilled: null,
    removeRejected: null,
  };

  const userAction = [
    'work_items',
    'education_items',
    'course_items',
    'fise_exam_items',
    'training_items',
    'cpd_course_items',
  ];

  if (userAction.indexOf(model) > -1) {
    actionTypes.addFulfilled = types.USER_ATTACHMENT_ADD_FULFILLED;
    actionTypes.addRejected = types.USER_ATTACHMENT_ADD_REJECTED;
    actionTypes.removeFulfilled = types.USER_ATTACHMENT_REMOVE_FULFILLED;
    actionTypes.removeRejected = types.USER_ATTACHMENT_REMOVE_REJECTED;
  }

  if (model === 'application') {
    actionTypes.addFulfilled = types.APPLICATION_ATTACHMENT_ADD_FULFILLED;
    actionTypes.addRejected = types.APPLICATION_ATTACHMENT_ADD_REJECTED;
    actionTypes.removeFulfilled = types.APPLICATION_ATTACHMENT_REMOVE_FULFILLED;
    actionTypes.removeRejected = types.APPLICATION_ATTACHMENT_REMOVE_REJECTED;
  }

  if (model === 'comment') {
    actionTypes.addFulfilled = types.APPLICATION_COMMENT_ATTACHMENT_ADD_FULFILLED;
    actionTypes.addRejected = types.APPLICATION_COMMENT_ATTACHMENT_ADD_REJECTED;
    actionTypes.removeFulfilled = types.APPLICATION_COMMENT_ATTACHMENT_REMOVE_FULFILLED;
    actionTypes.removeRejected = types.APPLICATION_COMMENT_ATTACHMENT_REMOVE_REJECTED;
  }

  return actionTypes;
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export function* addAttachments(action) {
  const { model, parentId, files, formName, fieldName, applicationId } = action.payload;
  const url = constructURL(action, fieldName);
  const actions = getActionTypes(action);

  // Get all attachments that are currently in the form
  const selector = formValueSelector(formName);

  try {
    yield all(files.map(function* uploadFile(file) {
      // Copying JS blobs are not straight forward so we
      // cheat here. (No, it is not as easy as .splice())
      /* eslint-disable no-param-reassign */
      file.uploaded = false;
      file.id = uniqueId();
      if (parentId) {
        file.uploading = true;
      } else {
        file.awaitingUpload = true;
      }
      /* eslint-enable no-param-reassign */
      yield put(arrayPush(formName, fieldName, file));

      // Only do automatic uploads when we already have a parent object
      // as we have no endpoint to store to otherwise.
      if (parentId) {
        const convertedFile = yield fileToBase64(file, ['name']);

        // Upload the file
        const response = yield axios.post(url, convertedFile);

        // Add the model to the action payload so that reducers have an easier time
        response.model = model;
        response.parentId = parentId;
        response.applicationId = applicationId;
        yield put({ type: actions.addFulfilled, payload: response });

        const newFile = response.data;
        newFile.uploaded = true;
        newFile.uploading = false;

        // Edit the form, replacing the old data with the response
        const state = store.getState();
        const attachments = selector(state, fieldName);
        const newAttachments = [
          ...attachments.map(
            attachment => (
              attachment.id !== file.id ? attachment : newFile
            ),
          ),
        ];
        yield put(change(formName, fieldName, newAttachments));
      }
    }));
  } catch (err) {
    yield put({ type: actions.addRejected, payload: err });
    serverErrorToastr(err.response);
  }
}


export function* removeAttachment(action) {
  const { userId, model, parentId, file, formName, fieldName, applicationId } = action.payload;
  let url = constructURL(action, fieldName);
  const actions = getActionTypes(action);
  const removalPayload = {
    userId,
    model,
    parentId,
    applicationId,
    fileId: file.id,
  };

  url += `/${file.id}`;

  // Get all attachments that are currently in the form
  const selector = formValueSelector(formName);
  const state = store.getState();
  let attachments = selector(state, fieldName);

  // Remove the attachment that will be removed from the list
  attachments = filter(attachments, attachment => attachment.id !== file.id);

  let response;
  try {
    if (!file.awaitingUpload) {
      // Remove the attachment from the server
      response = yield axios.delete(url);
      removalPayload.response = response;

      // Remove the attachment from the user state
      yield put({ type: actions.removeFulfilled, payload: removalPayload });
    }

    // Remove the attachment from the form
    yield put(change(formName, fieldName, attachments));
  } catch (err) {
    yield put({ type: actions.removeRejected, payload: err });
    serverErrorToastr(err.response);
  }
}

export function* downloadAttachment(action) {
  const { file } = action.payload;

  // This is not the nicest way to handle downloads
  // but it gets past popup blockers. Note that we
  // can not user window.open() after the axios
  // call since then this will not longer be a trusted
  // event and the browser will not allow it.

  const url = `/files/${file}`;
  try {
    const fileResponse = yield axios.get(url);
    const newWindow = window.open('', '_blank');
    const fileData = fileResponse.data;
    if (fileData) {
      newWindow.location.href = fileData.file;
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      yield store.dispatch(showModal('login', 1));
    } else {
      serverErrorToastr(err.response);
    }
  }
}

// ------------------------------------
// Watchers
// ------------------------------------
export function* watchAttachmentSagas() {
  yield all([
    takeEvery(types.ATTACHMENT_ADD, addAttachments),
    takeEvery(types.ATTACHMENT_REMOVE, removeAttachment),
    takeLatest(types.ATTACHMENT_DOWNLOAD, downloadAttachment),
  ]);
}

export default function* attachmentSagas() {
  yield all([
    fork(watchAttachmentSagas),
  ]);
}
