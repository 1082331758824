import { isEmpty } from 'lodash';
import * as types from '../constants/users';

import axios from '../../../../../api/axios';

export const queryUsers = filterParams => (dispatch, getState) => {
  const filters = isEmpty(filterParams) ? {} : filterParams;
  filters.stripped = 1;

  if (!('page' in filters)) {
    filters.page = 1;
  }

  const queryParams = { filters };

  const payload = {
    queryParams,
  };

  dispatch({
    type: types.QUERY_DASHBOARD_USERS_PENDING,
    payload,
  });

  axios.get('/users', { params: { ...queryParams.filters } })
    .then((response) => {
      const users = {};

      for (const user of response.data) {
        users[user.id] = user;
      }

      payload.response = {
        ...response,
        users,
      };

      dispatch({
        type: types.QUERY_DASHBOARD_USERS_FULFILLED,
        payload,
      });
    })
    .catch((error) => {
      payload.response = error;
      dispatch({
        type: types.QUERY_DASHBOARD_USERS_REJECTED,
        payload,
      });
    });
};

export const changeSorting = (sortName, sortOrder) => (dispatch) => {
  dispatch({
    type: types.CHANGE_DASHBOARD_USERS_SORTING,
    payload: {
      sortName,
      sortOrder,
    },
  });
};

export const getDeleteUserWarning = id => (dispatch) => {
  dispatch({
    type: types.DELETE_USER_WARNING_PENDING,
    loading: true,
  });

  axios.get(`/users/${id}/delete_user_warning`)
    .then((response) => {
      dispatch({
        type: types.DELETE_USER_WARNING_FULFILLED,
        payload: response,
      });
    },
    );
};

export const confirmDeleteUser = id => dispatch => dispatch({
  type: types.DELETE_USER_CONFIRM,
  payload: axios.delete(`/users/${id}`),
});

export const moveUserCertificate = (fromUserId, toUserId) => dispatch => dispatch({
  type: types.MOVE_USER_CERTIFICATES,
  payload: axios.put(`/users/${fromUserId}/move_certificates`, { move_to: toUserId }),
});
